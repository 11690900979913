import { useEffect, useState } from "react";
import {
  Flex,
  Tab,
  TabList,
  Tabs,
  TabPanels,
  TabPanel,
  Spacer,
  Select,
} from "@chakra-ui/react";
import authHandler from "../../utils/handlers/authHandler";
import { useNavigate } from "react-router-dom";
import { reportStatus } from "../../enums/status";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import { userRoles } from "../../enums/roles";
import { routes } from "../../enums/routes";
import ReportsResearcherVision from "../../components/reportsResearcherVision";
import ReportsCompanyVision from "../../components/reportsCompanyVision";
import ChartsCompanyVision from "../../components/chartsCompanyVision";
import ChartsResearcherVision from "../../components/chartsResearcherVision";
import programRequests from "../../utils/requests/programRequests";

export default function Reports() {
  const navigate = useNavigate();
  const userRole = sessionControllerHandler.getUserRole();
  const userId = sessionControllerHandler.getUserID();

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [lastDays, setLastDays] = useState("all");
  const [periodFilter, setPeriodFilter] = useState("byMonth");
  const [tabIndex, setTabIndex] = useState(0);
  const [status, setStatus] = useState<reportStatus>("all" as reportStatus);
  const [program, setProgram] = useState("all");
  const [programOptions, setProgramOptions] = useState<
    { label: string; id: string }[]
  >([]);

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
    getProgramOptons();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  const getProgramOptons = async () => {
    const response = await programRequests.getOptions(
      userRole == userRoles.COMPANY || userRole == userRoles.GUEST
        ? userId
        : null
    );

    if (response.programsOption) {
      setProgramOptions(response.programsOption);
    }
  };

  return (
    <Tabs
      position="relative"
      variant="unstyled"
      onChange={(index) => setTabIndex(index)}
    >
      <TabList>
        <Flex gap="1.5rem" alignItems="center">
          {userRole === userRoles.COMPANY && (
            <Tab
              m="0"
              p="0"
              color="gray.400"
              fontSize="2xl"
              as="b"
              _selected={{ color: "gray.700" }}
              cursor="pointer"
            >
              Relatórios
            </Tab>
          )}

          {userRole === userRoles.GUEST && (
            <Tab
              m="0"
              p="0"
              color="gray.400"
              fontSize="2xl"
              as="b"
              _selected={{ color: "gray.700" }}
              cursor="pointer"
            >
              Relatórios
            </Tab>
          )}

          {userRole === userRoles.COMPANY && (
            <Tab
              color="gray.400"
              fontSize="2xl"
              as="b"
              _selected={{ color: "gray.700" }}
              cursor="pointer"
            >
              Gráficos
            </Tab>
          )}

          {userRole === userRoles.GUEST && (
            <Tab
              color="gray.400"
              fontSize="2xl"
              as="b"
              _selected={{ color: "gray.700" }}
              cursor="pointer"
            >
              Gráficos
            </Tab>
          )}

          {userRole === userRoles.RESEARCHER && (
            <Tab
              m="0"
              p="0"
              color="gray.400"
              fontSize="2xl"
              as="b"
              _selected={{ color: "gray.700" }}
              cursor="pointer"
            >
              Relatórios
            </Tab>
          )}

          {userRole === userRoles.RESEARCHER && (
            <Tab
              color="gray.400"
              fontSize="2xl"
              as="b"
              _selected={{ color: "gray.700" }}
              cursor="pointer"
            >
              Gráficos
            </Tab>
          )}
        </Flex>

        <Spacer />

        <Flex gap="1.5rem" alignItems="center">
          {userRole === userRoles.COMPANY && (
            <Select
              w="15rem"
              size="md"
              onChange={(event) => setLastDays(event.target.value)}
              defaultValue="all"
              focusBorderColor="teal.500"
            >
              <option value="30">Últimos 30 dias</option>

              <option value="60">Últimos 60 dias</option>

              <option value="90">Últimos 90 dias</option>

              <option value="all">Todos os dias</option>
            </Select>
          )}

          {userRole === userRoles.GUEST && (
            <Select
              w="15rem"
              size="md"
              onChange={(event) => setLastDays(event.target.value)}
              defaultValue="all"
              focusBorderColor="teal.500"
            >
              <option value="30">Últimos 30 dias</option>

              <option value="60">Últimos 60 dias</option>

              <option value="90">Últimos 90 dias</option>

              <option value="all">Todos os dias</option>
            </Select>
          )}

          {userRole === userRoles.RESEARCHER && (
            <>
              {tabIndex === 0 && (
                <Select
                  w="15rem"
                  size="md"
                  onChange={(event) => setLastDays(event.target.value)}
                  defaultValue="all"
                  focusBorderColor="teal.500"
                >
                  <option value="30">Últimos 30 dias</option>

                  <option value="60">Últimos 60 dias</option>

                  <option value="90">Últimos 90 dias</option>

                  <option value="all">Todos os dias</option>
                </Select>
              )}

              {tabIndex === 1 && (
                <Select
                  w="15rem"
                  size="md"
                  onChange={(event) => setPeriodFilter(event.target.value)}
                  defaultValue="byMonth"
                  focusBorderColor="teal.500"
                >
                  <option value="byMonth">Por mês</option>

                  <option value="lastMonth">Último mês</option>

                  <option value="byYear">Por ano</option>
                </Select>
              )}
            </>
          )}

          <Select
            w="15rem"
            size="md"
            onChange={(event) => setStatus(event.target.value as reportStatus)}
            defaultValue="all"
            focusBorderColor="teal.500"
          >
            <option value="all">Todos os status</option>
            <option value={reportStatus.ACCEPT}>Aceito</option>
            <option value={reportStatus.NOT_ACCEPT}>Não aceito</option>
            <option value={reportStatus.NEW}>Novo</option>
            <option value={reportStatus.MORE_INFORMATION}>
              Mais informações
            </option>
            <option value={reportStatus.IN_ANALYSIS}>Em análise</option>
          </Select>

          <Select
            w="15rem"
            size="md"
            onChange={(event) => setProgram(event.target.value)}
            defaultValue="all"
            focusBorderColor="teal.500"
          >
            <option value="all">Todos os programas</option>

            {programOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.label}
              </option>
            ))}
          </Select>
        </Flex>
      </TabList>

      <TabPanels>
        {userRole === userRoles.COMPANY && (
          <TabPanel m="0" p="0">
            <ReportsCompanyVision
              lastDays={lastDays}
              status={status}
              program={program}
            />
          </TabPanel>
        )}

        {userRole === userRoles.GUEST && (
          <TabPanel m="0" p="0">
            <ReportsCompanyVision
              lastDays={lastDays}
              status={status}
              program={program}
            />
          </TabPanel>
        )}

        {userRole === userRoles.COMPANY && (
          <TabPanel m="0" p="0">
            <ChartsCompanyVision
              lastDays={lastDays}
              status={status}
              program={program}
            />
          </TabPanel>
        )}

        {userRole === userRoles.GUEST && (
          <TabPanel m="0" p="0">
            <ChartsCompanyVision
              lastDays={lastDays}
              status={status}
              program={program}
            />
          </TabPanel>
        )}

        {userRole === userRoles.RESEARCHER && (
          <TabPanel m="0" p="0">
            <ReportsResearcherVision
              lastDays={lastDays}
              status={status}
              program={program}
            />
          </TabPanel>
        )}

        {userRole === userRoles.RESEARCHER && (
          <TabPanel m="0" p="0">
            <ChartsResearcherVision
              periodFilter={periodFilter}
              status={status}
              program={program}
            />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}
