import React from "react";
import { ChatInterface } from "../interfaces/chat";

export interface ChatContextInterface {
  chatSelected: ChatInterface | null;
  updateChatSelected: (chatSelected: ChatInterface) => void;

  chats: ChatInterface[];
  updateChats: (chats: ChatInterface[]) => void;
}

interface ChatProviderInterface {
  children: React.ReactNode;
}

export const ChatContext = React.createContext<ChatContextInterface | null>(
  null
);

export const ChatProvider: React.FC<ChatProviderInterface> = ({ children }) => {
  const [chatSelected, setChatSelected] = React.useState<ChatInterface | null>(
    null
  );

  const [chats, setChats] = React.useState<ChatInterface[]>([]);

  const updateChatSelected = (chatSelected: ChatInterface) => {
    setChatSelected(chatSelected);
  };

  const updateChats = (chatSelected: ChatInterface[]) => {
    console.log(chats);
    setChats(chatSelected);
  };

  return (
    <ChatContext.Provider
      value={{ chatSelected, updateChatSelected, chats, updateChats }}
    >
      {children}
    </ChatContext.Provider>
  );
};
