import React from "react";
import { Box, Text, Flex, Badge, Spacer } from "@chakra-ui/react";

interface Props {
  titleText: string;
  titleColor: string;
  informations: {
    label: string;
    value: string | number;
    colorScheme: string;
  }[];
}

const InformationCard: React.FC<Props> = ({
  informations,
  titleColor,
  titleText,
}) => {
  return (
    <Box p="1.5rem" bg="whiteAlpha.900" height="auto" borderRadius="8px">
      <Text mb="1rem" color={titleColor} fontSize="xl">
        {titleText}
      </Text>

      <Flex gap="1rem" flexDirection="column">
        {informations.map((information, index) => (
          <Flex key={index}>
            <Text color="gray.700" fontSize="sm">
              {information.label}
            </Text>

            <Spacer />

            <Badge
              fontSize="sm"
              variant="solid"
              colorScheme={information.colorScheme}
              borderRadius="5px"
            >
              {information.value}
            </Badge>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export default InformationCard;
