import React from "react";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Avatar,
  Flex,
  InputGroup,
  Input,
  InputLeftAddon,
  Spacer,
  Image,
} from "@chakra-ui/react";
import { PROFILE_PIC_ENDPOINTS } from "../../enums/apiEndpoints";
import { routes } from "../../enums/routes";
import filesRequests from "../../utils/requests/files.requests";
import userRequests from "../../utils/requests/userRequests";

export default function TopBar() {
  const navigate = useNavigate();
  const useUploadedPic = sessionControllerHandler.getUseUploadedPic();
  const profilePicId = sessionControllerHandler.getProfilePicId();
  const [profilePic, setProfilePic] = React.useState("");

  const profilePicSrc =
    process.env.REACT_APP_BASE_URL +
    PROFILE_PIC_ENDPOINTS.PROFILE_PIC_GET +
    "/" +
    profilePicId;

  const userName = sessionControllerHandler.getUserName();
  const userSurname = sessionControllerHandler.getUserSurname();
  const fullname = `${userName} ${userSurname}`;

  const logout = async () => {
    const userId = sessionControllerHandler.getUserID();
    await userRequests.logout({ id: userId ?? "" });

    sessionControllerHandler.clearRecords();
    navigate(routes.LOGIN);
  };

  const getProfilePic = async () => {
    const response = await filesRequests.get(profilePicSrc);

    if (
      response !== "Ocorreu um erro no servidor, tente novamente mais tarde"
    ) {
      setProfilePic(response);
    }
  };

  React.useEffect(() => {
    getProfilePic();
  }, []);

  return (
    <Flex
      boxShadow="base"
      minWidth="max-content"
      p="1.5rem"
      background="whiteAlpha.900"
      h="9vh"
      position="sticky"
      zIndex={"2"}
    >
      <Image
        alignSelf="center"
        w="10rem"
        src="/assets/logo-wehack.svg"
        alt="Wehack Network"
      />

      <Spacer />

      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              isActive={isOpen}
              colorScheme="whiteAlpha"
              color="gray.700"
              leftIcon={
                useUploadedPic ? (
                  <Avatar size="xs" src={profilePic} />
                ) : (
                  <Avatar size="xs" name={fullname} />
                )
              }
              rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            >
              {fullname}
            </MenuButton>

            <MenuList>
              <MenuItem onClick={() => navigate(routes.MY_ACCOUNT)}>
                Minha conta
              </MenuItem>
              <MenuItem onClick={() => logout()}>Sair</MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  );
}
