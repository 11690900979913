import { useEffect, useState } from "react";
import {
  Flex,
  Select,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import authHandler from "../../utils/handlers/authHandler";
import { useNavigate } from "react-router-dom";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import { userRoles } from "../../enums/roles";
import ProgramOverview from "../../components/ProgramOverview";
import { routes } from "../../enums/routes";
import Overview from "../../components/overview";
import AdminOverview from "../../components/adminOverview";

export default function Homepage() {
  const navigate = useNavigate();
  const userRole = sessionControllerHandler.getUserRole();
  const userName = sessionControllerHandler.getUserName();
  const actualMonth = new Date().getMonth() + 1;
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [lastDays, setLastDays] = useState("all");
  const [months, setMonths] = useState<{ id: number; label: string }[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(
    actualMonth.toString()
  );

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
    getMonthList();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  const getMonthList = () => {
    const months = [
      { id: 1, label: "Janeiro" },
      { id: 2, label: "Fevereiro" },
      { id: 3, label: "Março" },
      { id: 4, label: "Abril" },
      { id: 5, label: "Maio" },
      { id: 6, label: "Junho" },
      { id: 7, label: "Julho" },
      { id: 8, label: "Agosto" },
      { id: 9, label: "Setembro" },
      { id: 10, label: "Outubro" },
      { id: 11, label: "Novembro" },
      { id: 12, label: "Dezembro" },
    ];

    const filtredMonths = months.filter((month) => month.id <= actualMonth);

    setMonths(filtredMonths);
  };

  return (
    <>
      <Tabs position="relative" variant="unstyled">
        <TabList>
          <Flex gap="1.5rem" alignItems="center">
            {userRole === userRoles.COMPANY && (
              <Tab
                m="0"
                p="0.5rem 0"
                color="gray.400"
                fontSize="2xl"
                as="b"
                _selected={{ color: "gray.700" }}
                cursor="pointer"
              >
                Overview {userName}
              </Tab>
            )}

            {userRole === userRoles.GUEST && (
              <Tab
                m="0"
                p="0.5rem 0"
                color="gray.400"
                fontSize="2xl"
                as="b"
                _selected={{ color: "gray.700" }}
                cursor="pointer"
              >
                Overview {userName}
              </Tab>
            )}

            {userRole === userRoles.RESEARCHER ||
              (userRole === userRoles.ADMIN && (
                <Tab
                  m="0"
                  p="0.5rem 0"
                  color="gray.400"
                  fontSize="2xl"
                  as="b"
                  _selected={{ color: "gray.700" }}
                  cursor="pointer"
                >
                  Overview
                </Tab>
              ))}
          </Flex>

          <Spacer />

          <Flex gap="1.5rem" alignItems="center">
            {userRole === userRoles.COMPANY && (
              <Select
                w="15rem"
                size="md"
                onChange={(event) => setLastDays(event.target.value)}
                defaultValue="all"
                focusBorderColor="teal.500"
              >
                <option value="30">Últimos 30 dias</option>

                <option value="60">Últimos 60 dias</option>

                <option value="90">Últimos 90 dias</option>

                <option value="all">Todos</option>
              </Select>
            )}

            {userRole === userRoles.GUEST && (
              <Select
                w="15rem"
                size="md"
                onChange={(event) => setLastDays(event.target.value)}
                defaultValue="all"
                focusBorderColor="teal.500"
              >
                <option value="30">Últimos 30 dias</option>

                <option value="60">Últimos 60 dias</option>

                <option value="90">Últimos 90 dias</option>

                <option value="all">Todos</option>
              </Select>
            )}

            {userRole === userRoles.RESEARCHER ||
              (userRole === userRoles.ADMIN && (
                <Select
                  w="15rem"
                  size="md"
                  onChange={(event) => setSelectedMonth(event.target.value)}
                  value={selectedMonth}
                  focusBorderColor="teal.500"
                >
                  {months.map((month, index) => (
                    <option key={index} value={month.id.toString()}>
                      {month.label}
                    </option>
                  ))}
                </Select>
              ))}
          </Flex>
        </TabList>

        <TabPanels>
          {userRole === userRoles.COMPANY && (
            <TabPanel m="0" p="0">
              <ProgramOverview lastDays={lastDays} />
            </TabPanel>
          )}

          {userRole === userRoles.GUEST && (
            <TabPanel m="0" p="0">
              <ProgramOverview lastDays={lastDays} />
            </TabPanel>
          )}

          {userRole === userRoles.RESEARCHER && (
            <TabPanel m="0" p="0">
              <Overview month={selectedMonth} />
            </TabPanel>
          )}

          {userRole === userRoles.ADMIN && (
            <TabPanel m="0" p="0">
              <AdminOverview month={selectedMonth} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  );
}
