import "@mui/lab/themeAugmentation";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillArrowUpRightSquareFill } from "react-icons/bs";
import { Box, useToast } from "@chakra-ui/react";
import StatusBadge from "../statusBadge";
import { reportStatus } from "../../enums/status";
import CriticallyBadge from "../criticallyBadge";
import { reportCritically } from "../../enums/critically";
import ReportInterface from "../../interfaces/report";
import reportRequests from "../../utils/requests/reportRequests";
import {
  createTheme,
  Table,
  TableContainer,
  TableHead,
  ThemeProvider,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Pagination,
} from "@mui/material";
import { routes } from "../../enums/routes";
import { useGridApiOptionHandler } from "@mui/x-data-grid";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import { userRoles } from "../../enums/roles";

interface Props {
  lastDays: string;
  status: reportStatus;
  program: string;
}

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: "#319795",
    },
  },

  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          padding: "1.3rem",
          marginTop: "1.5rem",
          marginBottom: "1rem",
          borderRadius: "16px",
          border: "1px solid #E2E8F0",
          minWidth: 650,
        },
      },
    },
    MuiTableCell: { styleOverrides: { root: { padding: "1rem" } } },
  },
});

const ReportsCompanyVision: React.FC<Props> = ({
  lastDays,
  status,
  program,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [page, setPage] = useState(1);
  const [reports, setReports] = useState<ReportInterface[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getReports(page);
  }, [status, lastDays, program]);

  const handleChangePage = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    getReports(value);
  };

  const getReports = async (currentPage: number) => {
    const userId = sessionControllerHandler.getUserID();
    const userRole = sessionControllerHandler.getUserRole();

    let userEmail: undefined | string = undefined;

    if (userRole == userRoles.GUEST) {
      userEmail = sessionControllerHandler.getEmail();
    }

    const response = await reportRequests.getAll(
      status,
      lastDays,
      currentPage,
      program,
      undefined,
      userId ?? undefined,
      userEmail
    );

    if (!response.reports) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setReports([]);
      setTotalPages(1);
      setPage(1);

      return;
    }

    setReports(response.reports);
    setTotalRows(response.total);
    setTotalPages(response.totalPages);
    return;
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", fontColor: "#4A5568" }}>
                TÍTULO
              </TableCell>

              <TableCell sx={{ fontWeight: "bold", fontColor: "#4A5568" }}>
                PROGRAMA
              </TableCell>

              <TableCell sx={{ fontWeight: "bold", fontColor: "#4A5568" }}>
                TIPO
              </TableCell>

              <TableCell
                align="left"
                sx={{ fontWeight: "bold", fontColor: "#4A5568" }}
              >
                USUÁRIO
              </TableCell>

              <TableCell
                align="left"
                sx={{ fontWeight: "bold", fontColor: "#4A5568" }}
              >
                DATA
              </TableCell>

              <TableCell
                align="center"
                sx={{ fontWeight: "bold", fontColor: "#4A5568" }}
              >
                STATUS
              </TableCell>

              <TableCell
                align="center"
                sx={{ fontWeight: "bold", fontColor: "#4A5568" }}
              >
                CRITICIDADE
              </TableCell>

              <TableCell
                sx={{ fontWeight: "bold", fontColor: "#4A5568" }}
              ></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {reports.length !== 0 ? (
              reports.map((report) => (
                <TableRow
                  key={report.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "15rem",
                    }}
                    component="th"
                    scope="row"
                  >
                    {report.title}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "15rem",
                    }}
                    component="th"
                    scope="row"
                  >
                    {report.program.title}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "15rem",
                    }}
                    component="th"
                    scope="row"
                  >
                    {report.vulnerability}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="left"
                    component="th"
                    scope="row"
                  >
                    {report.developer?.name + " " + report.developer?.surname}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="left"
                    component="th"
                    scope="row"
                  >
                    {new Date(report.createdAt).toLocaleDateString("pt-br")}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    <StatusBadge status={report.status as reportStatus} />
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    <CriticallyBadge
                      critically={report.critically as reportCritically}
                    />
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    component="th"
                    scope="row"
                  >
                    <Box
                      as={BsFillArrowUpRightSquareFill}
                      size="1.2rem"
                      color="teal.500"
                      cursor="pointer"
                      onClick={() =>
                        navigate(routes.REPORT_DETAILS + report.id)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    fontColor: "#2D3748",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "15rem",
                  }}
                  component="th"
                  scope="row"
                  colSpan={7}
                  align="center"
                >
                  Nenhum relatório encontrado
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <Stack direction="row-reverse">
          <Pagination
            page={page}
            onChange={handleChangePage}
            count={totalPages}
            color="primary"
            sx={{ fontColor: "#319795" }}
          />
        </Stack>
      </TableContainer>
    </ThemeProvider>
  );
};

export default ReportsCompanyVision;
