import React, { useEffect, useState } from "react";
import { Flex, useToast } from "@chakra-ui/react";
import { reportStatus } from "../../enums/status";
import ReportChart from "../reportChart";
import reportAnalyticsRequests from "../../utils/requests/reportAnalyticsRequests";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import ReportResearcherAnalyticsInterface from "../../interfaces/reportResearcherAnalyticsInterface";

interface Props {
  periodFilter: string;
  status: reportStatus;
  program: string;
}

const ChartsResearcherVision: React.FC<Props> = ({
  periodFilter,
  status,
  program,
}) => {
  const toast = useToast();
  const userId = sessionControllerHandler.getUserID();

  const [serieApprovedReports, setSerieApprovedReports] = useState([
    {
      name: "Impacto dos relatórios",
      data: [
        { x: "Dezembro", y: 5 },
        { x: "Janeiro", y: 3 },
        { x: "Fevereiro", y: 2 },
        { x: "Março", y: 1 },
      ],
    },
  ]);

  const [optionsApprovedReports, setOptionsApprovedReports] = useState({
    chart: {
      toolbar: { show: false },
      height: 350,
      type: "bar",
    },

    fill: {
      colors: ["#319795"],
    },

    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },

    dataLabels: {
      enabled: false,
    },

    legend: {
      show: false,
    },

    xaxis: {
      labels: {
        rotate: -45,
      },
      tickPlacement: "on",
    },

    yaxis: {
      forceNiceScale: true,
      tickAmount: 1,
      decimalsInFloat: 0,
      labels: {
        formatter: function (val: any) {
          return val.toFixed(0);
        },
      },
    },
  });

  useEffect(() => {
    getInformations(status, periodFilter, program);
  }, [status, periodFilter, program]);

  const getInformations = async (
    status: reportStatus,
    periodFilter: string,
    program: string
  ) => {
    if (!userId) {
      toast({
        title: "Não foi possível identificar o usuário.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    const reponse = await reportAnalyticsRequests.getResearcher(
      periodFilter,
      status,
      program,
      undefined,
      userId
    );

    if (!reponse.reportsByPeriod) {
      toast({
        title: "Não foi possível obter análise de relatórios",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    organizeApprovedReports(reponse);
  };

  const organizeApprovedReports = (
    reportAnalytics: ReportResearcherAnalyticsInterface
  ) => {
    const reportsByPeriodValue = [];

    for (let key in reportAnalytics.reportsByPeriod) {
      reportsByPeriodValue.push({
        x: reportAnalytics.reportsByPeriod[key].period.toString(),
        y: reportAnalytics.reportsByPeriod[key].totalReports,
      });
    }

    setSerieApprovedReports([
      {
        name: normalizeChartTitle(status),
        data: reportsByPeriodValue,
      },
    ]);
  };

  const normalizeChartTitle = (status: reportStatus) => {
    if (status === reportStatus.ACCEPT) return "Relatórios aprovados";

    if (status === reportStatus.NOT_ACCEPT) return "Relatórios não aprovados";

    if (status === reportStatus.MORE_INFORMATION)
      return "Relatórios mais informações";

    if (status === reportStatus.NEW) return "Relatórios novos";

    if (status === reportStatus.IN_ANALYSIS) return "Relatórios em análise";

    return "Relatórios";
  };

  return (
    <Flex mt="1.5rem" gap="1.5rem" flexDirection="column">
      <ReportChart
        chartTitle={normalizeChartTitle(status)}
        chartOptions={optionsApprovedReports as any}
        chartSeries={serieApprovedReports}
        chartType="bar"
      />
    </Flex>
  );
};

export default ChartsResearcherVision;
