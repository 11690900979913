export enum reportVulnerability {
  INJECTION = "Injection",
  BROKEN_AUTHENTICATION = "Broken Authentication",
  SENSITIVE_DATA_EXPOSURE = "Sensitive Data Exposure",
  XML_EXTERNAL_ENTITIES = "XML External Entities",
  BROKEN_ACCESS_CONTROL = "Broken Access Control",
  SECURITY_MISCONFIGURATION = "Security Misconfiguration",
  CROSS_SITE_SCRIPTING = "Cross-Site Scripting",
  INSECURE_DESERIALIZATION = "Insecure Deserialization",
  USING_COMPONENTS_WITH_KNOWN_VULNERABILITIES = "Using Components with Known Vulnerabilities",
  INSUFFICIENT_LOGGIN_AND_MONITORING = "Insufficient Logging and Monitoring",
  NOT_LISTED = "Não listado",
}
