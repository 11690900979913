export enum reportFieldsDescription {
  vulnerability = "Vulnerabilidade",
  critically = "Criticidade",
  title = "Título do Relatório",
  softwares = "Softwares Utilizados",
  operaton = "Funcionamento",
  howToReproduce = "Como reproduzir",
  solutions = "Informações adicionais",
  attachments = "Anexos",
  solution = "Solução",
}

export enum programFieldsDescription {
  aboutProgram = "Sobre o programa",
  aboutInstitution = "Sobre a instituição",
  apis = "API'S",
  sites = "Sites",
  apps = "Aplicativos",
  outOfScope = "Fora de escopo",
}
