import React from "react";
import { BsFillArrowUpRightSquareFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { routes } from "../../enums/routes";
import { StarIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Flex,
  Spacer,
  Tooltip,
  Avatar,
  Text,
  VStack,
  HStack,
  Grid,
} from "@chakra-ui/react";
import CircleIcon from "../circleIcon";

interface Props {
  iconUrl: string;
  title: string;
  id: string;
  companyName: string;
  criticalValue: number;
  hightValue: number;
  mediumValue: number;
  lowValue: number;
  reportsTotal: number;
}

const ProgramCard: React.FC<Props> = ({
  iconUrl,
  title,
  id,
  companyName,
  criticalValue,
  hightValue,
  mediumValue,
  lowValue,
  reportsTotal,
}) => {
  const navigate = useNavigate();

  const calculateMedia = () => {
    const media = (criticalValue + hightValue + mediumValue + lowValue) / 4;
    return media.toFixed(2);
  };

  return (
    <VStack
      align="stretch"
      p="1rem"
      spacing="0.5rem"
      borderRadius="0.5rem"
      border="1px solid #E2E8F0"
    >
      <Flex justifyContent="flex-end" overflow="hidden" mb="0.5rem">
        <Box
          as={BsFillArrowUpRightSquareFill}
          size="1.2rem"
          color="teal.500"
          cursor="pointer"
          onClick={() => navigate(routes.PROGRAM_DETAILS + id)}
        />
      </Flex>

      <Flex alignItems="center" gap="0.5rem">
        <Avatar icon={<StarIcon fontSize="1.5rem" />} src={iconUrl} />

        <Text size="sm">
          <b>{title}</b>
        </Text>
      </Flex>

      <Text size="sm" mb="1rem">
        {companyName}
      </Text>

      <VStack align="stretch" spacing="0.5rem">
        <HStack spacing="0.5rem">
          <Text size="sm">Relatórios:</Text>
          <Badge variant="outline">{reportsTotal}</Badge>
        </HStack>

        <HStack spacing="0.5rem">
          <Text size="sm">Valor médio:</Text>
          <Badge variant="outline">R$ {calculateMedia()}</Badge>
        </HStack>
      </VStack>

      <Grid templateColumns="repeat(2, 1fr)" mt="1rem" gap="0.5rem">
        <HStack spacing="0.5rem">
          <CircleIcon color="red" />
          <Text size="sm">R$ {criticalValue.toFixed(2)}</Text>
        </HStack>

        <HStack spacing="0.5rem">
          <CircleIcon color="orange" />
          <Text size="sm">R$ {hightValue.toFixed(2)}</Text>
        </HStack>

        <HStack spacing="0.5rem">
          <CircleIcon color="green" />
          <Text size="sm">R$ {mediumValue.toFixed(2)}</Text>
        </HStack>

        <HStack spacing="0.5rem">
          <CircleIcon color="blue" />
          <Text size="sm">R$ {lowValue.toFixed(2)}</Text>
        </HStack>
      </Grid>
    </VStack>
  );
};

export default ProgramCard;
