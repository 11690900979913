import React, { useEffect, useState } from "react";
import { Flex, useToast } from "@chakra-ui/react";
import { reportStatus } from "../../enums/status";
import ReportChart from "../reportChart";
import reportAnalyticsRequests from "../../utils/requests/reportAnalyticsRequests";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import { reportCritically } from "../../enums/critically";
import ReportCompanyAnalyticsInterface from "../../interfaces/reportCompanyAnalyticsInterface";

interface Props {
  lastDays: string;
  status: reportStatus;
  program: string;
}

const ChartsCompanyVision: React.FC<Props> = ({
  lastDays,
  status,
  program,
}) => {
  const toast = useToast();
  const userId = sessionControllerHandler.getUserID();

  const [serieImpactOfReports, setSerieImpactOfReports] = useState([
    {
      name: "Impacto dos relatórios",
      data: [
        { x: "Crítico", y: 0 },
        { x: "Alto", y: 0 },
        { x: "Médio", y: 0 },
        { x: "Baixo", y: 0 },
      ],
    },
  ]);

  const [optionsImpactOfReports, setOptionsImpactOfReports] = useState({
    chart: {
      toolbar: { show: false },
      height: 350,
      type: "bar",
    },

    fill: {
      colors: ["#E53E3E", "#ED8936", "#3182CE", "#2D3748"],
    },

    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },

    dataLabels: {
      enabled: false,
    },

    legend: {
      show: false,
    },

    xaxis: {
      labels: {
        rotate: -45,
      },
      tickPlacement: "on",
    },

    yaxis: {
      forceNiceScale: true,
      tickAmount: 1,
      decimalsInFloat: 0,
      labels: {
        formatter: function (val: any) {
          return val.toFixed(0);
        },
      },
    },
  });

  const [serieTopVulnerabilities, setSerieTopVulnerabilities] = useState([0]);

  const [optionsTopVulnerabilities, setOptionsTopVulnerabilities] = useState({
    chart: {
      type: "donut",
    },
    legend: {
      formatter: function (val: any, opts: any) {
        return val + " | Qtd.: " + opts.w.globals.series[opts.seriesIndex];
      },
    },
    labels: ["Exemplo"],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    getInformations(status, lastDays, program);
  }, [status, lastDays, program]);

  const normalizingReportCritically = (critically: reportCritically) => {
    if (critically === reportCritically.CRITICAL) return "Crítico";
    if (critically === reportCritically.HIGH) return "Alto";
    if (critically === reportCritically.MEDIUM) return "Médio";
    if (critically === reportCritically.LOW) return "Baixo";
    return critically;
  };

  const getInformations = async (
    status: reportStatus,
    lastDays: string,
    program: string
  ) => {
    if (!userId) {
      toast({
        title: "Não foi possível identificar o usuário.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    const reponse = await reportAnalyticsRequests.getCompany(
      lastDays,
      status,
      program,
      userId,
      undefined
    );

    if (!reponse.topVulnerabilities) {
      toast({
        title: "Não foi possível obter análise de relatórios",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    organizeImpactOfReportsValue(reponse);
    organizeTopVulnerabilities(reponse);
  };

  const organizeImpactOfReportsValue = (
    reportAnalytics: ReportCompanyAnalyticsInterface
  ) => {
    const impactOfReportsValue = [];

    const colors = [];

    for (let key in reportAnalytics.impactOfReports) {
      impactOfReportsValue.push({
        x: normalizingReportCritically(
          reportAnalytics.impactOfReports[key].critically
        ),
        y: reportAnalytics.impactOfReports[key].totalReports,
      });

      if (reportAnalytics.impactOfReports[key].critically === "critical")
        colors.push("#E53E3E");

      if (reportAnalytics.impactOfReports[key].critically === "high")
        colors.push("#ED8936");

      if (reportAnalytics.impactOfReports[key].critically === "medium")
        colors.push("#3182CE");

      if (reportAnalytics.impactOfReports[key].critically === "low")
        colors.push("#2D3748");
    }

    setSerieImpactOfReports([
      {
        name: "Impacto dos relatórios",
        data: impactOfReportsValue,
      },
    ]);

    setOptionsImpactOfReports({
      chart: {
        toolbar: { show: false },
        height: 350,
        type: "bar",
      },

      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },

      dataLabels: {
        enabled: false,
      },

      legend: {
        show: false,
      },

      xaxis: {
        labels: {
          rotate: -45,
        },
        tickPlacement: "on",
      },

      fill: {
        colors: colors,
      },

      yaxis: {
        forceNiceScale: true,
        tickAmount: 1,
        decimalsInFloat: 0,
        labels: {
          formatter: function (val: any) {
            return val.toFixed(0);
          },
        },
      },
    });
  };

  const organizeTopVulnerabilities = (
    reportAnalytics: ReportCompanyAnalyticsInterface
  ) => {
    const topVulnetabilitiesValues = [];
    const topVulnetabilitiesLabels = [];

    for (let key in reportAnalytics.topVulnerabilities) {
      topVulnetabilitiesValues.push(
        reportAnalytics.topVulnerabilities[key].totalReports
      );

      topVulnetabilitiesLabels.push(
        reportAnalytics.topVulnerabilities[key].vulnerability
      );
    }

    setSerieTopVulnerabilities(topVulnetabilitiesValues);

    setOptionsTopVulnerabilities({
      chart: {
        type: "donut",
      },
      legend: {
        formatter: function (val: any, opts: any) {
          return val + " | Qtd.: " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      labels: topVulnetabilitiesLabels,
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
              },
            },
          },
        },
      },
    });
  };

  return (
    <Flex mt="1.5rem" gap="1.5rem" flexDirection="column">
      <ReportChart
        chartTitle="Impacto dos relatórios"
        chartOptions={optionsImpactOfReports as any}
        chartSeries={serieImpactOfReports}
        chartType="bar"
      />

      <ReportChart
        chartTitle="TOP Vulnerabilidades"
        chartOptions={optionsTopVulnerabilities as any}
        chartSeries={serieTopVulnerabilities}
        chartType="donut"
      />
    </Flex>
  );
};

export default ChartsCompanyVision;
