import axios from "axios";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import logoutAndReload from "../../utils/logoutAndReload";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(async (config: any) => {
  const token = sessionControllerHandler.getToken();
  const userId = sessionControllerHandler.getUserID();

  if (token) {
    config.headers.Authorization = `Bearer ${token.replace(/"/g, "")}`;
  }

  if (userId) {
    config.headers.userid = userId;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response?.status === 401 &&
      error.response?.data !== "Senha incorreta" &&
      error.response?.data !== "Usuário não encontrado"
    ) {
      logoutAndReload();
    }

    if (
      error.response?.status === 403 &&
      error.response?.data !== "ReCAPTCHA inválido."
    ) {
      logoutAndReload();
    }

    return Promise.reject(error);
  }
);

export default api;
