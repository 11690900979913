import { Center, Icon, Text, VStack } from "@chakra-ui/react";
import { IoConstructOutline } from "react-icons/io5";

export default function Developing() {
  return (
    <Center h="100%" w="100%">
      <VStack>
        <Icon as={IoConstructOutline} boxSize={"5rem"} color={"gray.300"} />

        <Text color={"gray.300"} as="b" fontSize={"3xl"}>
          Em desenvolvimento
        </Text>
      </VStack>
    </Center>
  );
}
