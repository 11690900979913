import authHandler from "../../utils/handlers/authHandler";
import AllCompanies from "../../components/allCompanies";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../enums/routes";

import {
  Flex,
  Tab,
  TabList,
  Tabs,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

export default function Companies() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <Tabs position="relative" variant="unstyled">
        <TabList>
          <Flex gap="1.5rem" alignItems="center">
            <Tab
              m="0"
              p="0"
              color="gray.400"
              fontSize="2xl"
              as="b"
              _selected={{ color: "gray.700" }}
              cursor="pointer"
            >
              Empresas
            </Tab>
          </Flex>
        </TabList>

        <TabPanels>
          <TabPanel m="0" p="0">
            <AllCompanies />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
