import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Center,
} from "@chakra-ui/react";

interface Props {
  programs: {
    company: string;
    totalReports: number;
  }[];
}

const ProgramsListCard: React.FC<Props> = ({ programs }) => {
  return (
    <Box
      borderRadius="1rem"
      p="1rem"
      bg="whiteAlpha.900"
      w="100%"
      h="100%"
      maxH="15rem"
      overflowY="auto"
    >
      <Text fontSize="xl" mb="0.5rem">
        Programas
      </Text>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>EMPRESA</Th>
              <Th>RELATÓRIOS</Th>
            </Tr>
          </Thead>

          <Tbody>
            {programs.length === 0 && (
              <Tr>
                <Td colSpan={2}>
                  <Center>
                    <Text size="sm">Nenhuma informação para apresentsr</Text>
                  </Center>
                </Td>
              </Tr>
            )}

            {programs.map((program, index) => (
              <Tr key={index}>
                <Td>
                  <Text size="sm">{program.company}</Text>
                </Td>

                <Td>
                  <Text size="sm">{program.totalReports}</Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProgramsListCard;
