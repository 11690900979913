import React from "react";
import ChatOption from "../chatOption";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";

import { ChatContext, ChatContextInterface } from "../../context/chatContext";
import { Box } from "@chakra-ui/react";
import chatRequests from "../../utils/requests/chatRequests";

export default function ChatList() {
  const userId = sessionControllerHandler.getUserID();

  const { chats, updateChats } = React.useContext(
    ChatContext
  ) as ChatContextInterface;

  React.useEffect(() => {
    getChats();
  }, []);

  const getChats = async () => {
    if (!userId) return;

    const rooms = await chatRequests.listRooms(userId);
    updateChats(rooms.roomUserDetail);
  };

  return (
    <Box
      bg="whiteAlpha.900"
      w="30%"
      h="78vh"
      borderRadius="1rem"
      overflowX="auto"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
          margin: "0.5rem 0 0.5rem 0",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#38A169",
          borderRadius: "24px",
        },
      }}
    >
      {chats?.map((chat, index) => (
        <ChatOption chat={chat} key={index} />
      ))}
    </Box>
  );
}
