import authHandler from "../../utils/handlers/authHandler";
import ChatList from "../../components/chatList";
import Chat from "../../components/chat";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../enums/routes";
import { HStack } from "@chakra-ui/react";

export default function Message() {
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  return (
    <HStack spacing="1.5rem">
      <ChatList />
      <Chat />
    </HStack>
  );
}
