import React, { useEffect, useState } from "react";
import { Box, Text, Center } from "@chakra-ui/react";

interface Props {
  reward: number;
}

const RewardCard: React.FC<Props> = ({ reward }) => {
  return (
    <Box borderRadius="1rem" p="1rem" bg="teal.500" w="100%" h="100%">
      <Text color="whiteAlpha.900" fontSize="xl" mb="0.5rem">
        Recompensas recebidas
      </Text>

      <Center>
        <Text color="whiteAlpha.900" fontSize="lg" as="b">
          R$ {reward.toFixed(2)}
        </Text>
      </Center>
    </Box>
  );
};

export default RewardCard;
