import api from "../../services/api";
import { EXPORT_REPORT_ENDPOINTS } from "../../enums/apiEndpoints";
import { reportCritically } from "../../enums/critically";
import { reportStatus } from "../../enums/status";

class ExportReportRequest {
  public async csv(reportDetais: {
    id: string;
    createdAt: string;
    title: string;
    operation: string;
    softwares: string;
    howToReproduce: string;
    possibleSolutions: string;
    developer: string;
    company: string;
    critically: reportCritically;
    status: reportStatus;
    solution?: string;
  }) {
    try {
      const response = await api.post(
        EXPORT_REPORT_ENDPOINTS.CSV,
        reportDetais
      );

      const csv: {
        reportName: string;
        reportUrl: string;
      } = response.data;

      return csv;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async pdf(reportDetais: {
    id: string;
    createdAt: string;
    title: string;
    operation: string;
    softwares: string;
    howToReproduce: string;
    possibleSolutions: string;
    developer: string;
    company: string;
    critically: reportCritically;
    status: reportStatus;
    solution?: string;
  }) {
    try {
      const response = await api.post(
        EXPORT_REPORT_ENDPOINTS.PDF,
        reportDetais
      );

      const csv: {
        reportName: string;
        reportUrl: string;
      } = response.data;

      return csv;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }
}

export default new ExportReportRequest();
