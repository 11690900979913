import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import userRequests from "../../utils/requests/userRequests";
import aesjs from "aes-js";
import ReCAPTCHA from "react-google-recaptcha";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../enums/routes";

import {
  BsFillEyeFill,
  BsFillEyeSlashFill,
  BsFillLockFill,
} from "react-icons/bs";

import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

export default function ForgotPassword() {
  const email = sessionControllerHandler.getEmailToChangePassword();
  const navigate = useNavigate();
  const toast = useToast();

  const [step, setStep] = useState<"one" | "two">("one");

  const [timer, setTimer] = useState(60);
  const [code, setCode] = useState("");
  const [codeHasError, setCodeHasError] = useState(false);
  const [sendCodeAttempts, setSendCodeAttempts] = useState(0);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPasswordValue, setShowPasswordValue] = useState(false);
  const [showConfirmPasswordValue, setShowConfirmPasswordValue] =
    useState(false);

  const [samePassword, setSamePassword] = useState(true);
  const [captchaValue, setCaptchaValue] = useState<null | string>(null);

  useEffect(() => {
    setTimeout(startTimer, 1000);
  }, [timer]);

  const startTimer = () => {
    if (timer - 1 >= 0) {
      setTimer(timer - 1);
    }
  };

  useEffect(() => {
    if (!email) navigate(routes.LOGIN);
  }, []);

  const handleShowPasswordValue = () =>
    setShowPasswordValue(!showPasswordValue);

  const handleShowConfirmPasswordValue = () =>
    setShowConfirmPasswordValue(!showConfirmPasswordValue);

  const checkPasswords = () => {
    return password === confirmPassword;
  };

  const sendValidationCode = async () => {
    if (!email) {
      toast({
        title: "É necessário informar um e-mail.",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });

      navigate(routes.LOGIN);
      return;
    }

    const response = await userRequests.sendResetPasswordCode(email);

    if (response !== "Código enviado com sucesso") {
      toast({
        title:
          "Não foi possível enviar o codigo de validação. Tente novamente.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const finishStepOne = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setCodeHasError(false);

    if (!email) {
      toast({
        title: "É necessário informar um e-mail.",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });

      navigate(routes.LOGIN);
      return;
    }

    const response = await userRequests.validateResetPassword(email, code);

    if (
      response === "Ocorreu um erro no servidor, tente novamente mais tarde"
    ) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    if (response.message === "Código de validação inválido.") {
      toast({
        title: response.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setCodeHasError(true);

      return;
    }

    setStep("two");
  };

  const finishStepTwo = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validationPasswords = checkPasswords();
    setSamePassword(validationPasswords);

    if (!validationPasswords) return;

    resetPassword();
  };

  const resetPassword = async () => {
    if (!email) {
      toast({
        title: "É necessário informar um e-mail.",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });

      navigate(routes.LOGIN);
      return;
    }
    const aesPassword = process.env.REACT_APP_AES_PASSWORD_FOR_EMAIL;

    if (!aesPassword) {
      toast({
        title: "Erro na aplicação WEB. Contate um administrador.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }

    const encriptEmailHex = encriptEmail(email);

    const response = await userRequests.resetPassword(
      encriptEmailHex,
      password,
      code,
      captchaValue
    );

    if (
      response === "Ocorreu um erro no servidor, tente novamente mais tarde"
    ) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    if (response.status && response.status !== 200) {
      toast({
        title: response.data,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    toast({
      title: "Senha alterada com sucesso",
      status: "success",
      duration: 4000,
      isClosable: true,
    });

    sessionControllerHandler.setEmailToChangePassword("");

    navigate(routes.LOGIN);
  };

  const encriptEmail = (email: string) => {
    const emailBytes = aesjs.utils.utf8.toBytes(email);

    const password = process.env.REACT_APP_AES_PASSWORD_FOR_EMAIL;

    var key = [];

    for (var index = 0; index < password.length; index++) {
      key.push(password.charCodeAt(index));
    }

    const aesCounter = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));

    const encryptedEmailBytes = aesCounter.encrypt(emailBytes);

    const encryptedEmailHex = aesjs.utils.hex.fromBytes(encryptedEmailBytes);

    return encryptedEmailHex;
  };

  const onCaptchaChange = async (value: string | null) => {
    setCaptchaValue(value);
  };

  return (
    <>
      {step === "one" && (
        <Center bg="gray.50" h="100vh">
          <Flex
            bg="whiteAlpha.900"
            p="3rem"
            gap="1.5rem"
            direction="column"
            alignItems="center"
            maxW="25rem"
          >
            <Image w="70%" src="/assets/logo-wehack.svg" alt="Wehack Network" />

            <form
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
              onSubmit={(event) => finishStepOne(event)}
            >
              <Stack spacing="1.5rem">
                <Text alignSelf="center" textAlign="center">
                  Um código de confirmação foi enviado para o seu e-mail. Por
                  favor, insira o código no campo abaixo.
                </Text>

                <HStack justifyContent="center">
                  <PinInput
                    type="number"
                    focusBorderColor="teal.500"
                    onChange={setCode}
                    isInvalid={codeHasError}
                  >
                    <PinInputField required />
                    <PinInputField required />
                    <PinInputField required />
                    <PinInputField required />
                    <PinInputField required />
                    <PinInputField required />
                  </PinInput>
                </HStack>

                <Text alignSelf="center">Não recebeu o código?</Text>

                <Button
                  size="lg"
                  w="100%"
                  variant="outline"
                  colorScheme="teal"
                  isDisabled={timer !== 0}
                  onClick={() => {
                    if (sendCodeAttempts === 3) {
                      toast({
                        title: "Você já enviou códigos demais",
                        description: "Por favor, tente novamente mais tarde.",
                        status: "warning",
                        duration: 4000,
                        isClosable: true,
                      });

                      return;
                    }

                    setSendCodeAttempts(sendCodeAttempts + 1);
                    setTimer(60);
                    sendValidationCode();
                  }}
                >
                  Enviar novamente em {timer} seg
                </Button>
              </Stack>

              <Button
                type="submit"
                size="lg"
                variant="solid"
                w="100%"
                mt="1.5rem"
                colorScheme="teal"
              >
                Confirmar
              </Button>
            </form>
          </Flex>
        </Center>
      )}

      {step === "two" && (
        <Center bg="gray.50" h="100vh">
          <Flex
            bg="whiteAlpha.900"
            p="3rem"
            gap="1.5rem"
            direction="column"
            alignItems="center"
            maxW="25rem"
          >
            <Image w="70%" src="/assets/logo-wehack.svg" alt="Wehack Network" />

            <form
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
              onSubmit={(event) => finishStepTwo(event)}
            >
              <HStack m="1.5rem 0" spacing="2.5rem" alignItems="flex-start">
                <Stack spacing="1.5rem">
                  <Text alignSelf="center" textAlign="center">
                    Selecione o novo valor para sua senha.
                  </Text>

                  <Stack spacing="1.5rem">
                    <FormControl isRequired>
                      <InputGroup
                        borderColor="gray.400"
                        size="lg"
                        variant="outline"
                        w="19rem"
                      >
                        <InputLeftElement
                          children={
                            <Box
                              as={BsFillLockFill}
                              size="1.5rem"
                              color="gray.400"
                            />
                          }
                        />

                        <Input
                          type={showPasswordValue ? "text" : "password"}
                          placeholder="Senha"
                          focusBorderColor="teal.500"
                          onChange={(event) =>
                            setPassword(event.currentTarget.value)
                          }
                        />

                        <InputRightElement>
                          <Button
                            borderLeft="1px"
                            borderColor="gray.400"
                            w="95%"
                            h="95%"
                            roundedLeft="0"
                            onClick={handleShowPasswordValue}
                          >
                            {showPasswordValue ? (
                              <Box
                                as={BsFillEyeSlashFill}
                                size="1.5rem"
                                color="gray.700"
                              />
                            ) : (
                              <Box
                                as={BsFillEyeFill}
                                size="1.5rem"
                                color="gray.700"
                              />
                            )}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>

                    <FormControl isRequired isInvalid={!samePassword}>
                      <InputGroup
                        borderColor="gray.400"
                        size="lg"
                        variant="outline"
                        w="19rem"
                      >
                        <InputLeftElement
                          children={
                            <Box
                              as={BsFillLockFill}
                              size="1.5rem"
                              color="gray.400"
                            />
                          }
                        />

                        <Input
                          type={showConfirmPasswordValue ? "text" : "password"}
                          placeholder="Confirmar senha"
                          focusBorderColor="teal.500"
                          onChange={(event) =>
                            setConfirmPassword(event.currentTarget.value)
                          }
                        />

                        <InputRightElement>
                          <Button
                            borderLeft="1px"
                            borderColor="gray.400"
                            w="95%"
                            h="95%"
                            roundedLeft="0"
                            onClick={handleShowConfirmPasswordValue}
                          >
                            {showConfirmPasswordValue ? (
                              <Box
                                as={BsFillEyeSlashFill}
                                size="1.5rem"
                                color="gray.700"
                              />
                            ) : (
                              <Box
                                as={BsFillEyeFill}
                                size="1.5rem"
                                color="gray.700"
                              />
                            )}
                          </Button>
                        </InputRightElement>
                      </InputGroup>

                      {!samePassword && (
                        <Flex flexDirection="column" alignItems="center">
                          <FormErrorMessage textDecoration="underline">
                            As senhas não coincidem.
                          </FormErrorMessage>

                          <FormErrorMessage textDecoration="underline">
                            Verifique os campos.
                          </FormErrorMessage>
                        </Flex>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
              </HStack>

              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={onCaptchaChange}
              />

              <Button
                type="submit"
                size="lg"
                variant="solid"
                w="100%"
                mt="1.5rem"
                colorScheme="teal"
                isDisabled={captchaValue ? false : true}
              >
                Confirmar
              </Button>
            </form>
          </Flex>
        </Center>
      )}
    </>
  );
}
