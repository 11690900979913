import JSZip from "jszip";
import { saveAs } from "file-saver";
import sessionControllerHandler from "./handlers/sessionControllerHandler";

interface FilesInformation {
  url: string;
  name: string;
}

export default async function zipFiles(
  zipName: string,
  filesInformation: FilesInformation[]
) {
  const zip = new JSZip();

  let files = [];

  const token = sessionControllerHandler.getToken() ?? "";

  for (let index in filesInformation) {
    const information = filesInformation[index];
    const imageBlob: Blob = await fetch(information.url, {
      headers: { Authorization: `Bearer ${token.replace(/"/g, "")}` },
    }).then((response) => response.blob());
    const imgData: File = new File([imageBlob], information.name);
    files.push(imgData);
  }

  files.forEach((file) => {
    zip.file(file.name, file, { base64: true });
  });

  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, zipName + ".zip");
  });
}
