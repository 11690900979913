export enum USER_ENDPOINTS {
  USER_LOGIN = "/users/login",
  USER_LOGOUT = "/users/logout",
  USER_CREATE = "/users",
  USER_UPDATE = "/users",
  USER_GET_DETAILS = "/users",
  USER_UPDATE_PASSWORD = "/users/update-password",
  USER_SEND_AUTH_CODE = "/users/send-auth-code",
  USER_CONFIRM_AUTH_CODE = "/users/confirm-auth-code",
  USER_PUT_BANK_DATA = "/users/bank-data",
  USER_GET_BANK_DATA = "/users/bank-data",
  USER_SEND_RESET_PASSWORD_CODE = "/users/send-reset-password-code",
  USER_RESET_PASSWORD = "/users/reset-password",
  VALIDATE_CODE_PASSWORD = "/users/validate-code-password",
  USER_GET_COMPANIES = "/users-companies",
  INVITE_GUEST = "/reportsSharingEmails/create",
}

export enum PROFILE_PIC_ENDPOINTS {
  PROFILE_PIC_GET = "/users/profile-pic",
  PROFILE_PIC_UPLOAD = "/users/profile-pic",
  PROFILE_PIC_DELETE = "/users/profile-pic",
}

export enum REPORT_ENDPOINTS {
  REPORT_GET = "/reports",
  REPORT_UPDATE = "/reports",
  REPORT_CREATE = "/reports",
  REPORT_UPLOAD_ATTACHMENTS = "/reports/attachments",
  COMPANY_ANALYTICS = "/company-analytics",
  RESEACHER_ANALYTICS = "/researcher-analytics",
}

export enum ATTCHMENTS_ENDPOINTS {
  ATTACHMENT_GET = "/reports/attachments",
}

export enum OVERVIEW_PROGRAM_ENDPOINTS {
  GET = "/overviewProgram",
}

export enum OVERVIEW_ENDPOINTS {
  GET = "/overview",
}

export enum OVERVIEW_ADMIN_ENDPOINTS {
  GET = "/overviewAdmin",
}

export enum EXPORT_REPORT_ENDPOINTS {
  CSV = "/reports/export-one/csv",
  PDF = "/reports/export-one/pdf",
}

export enum PROGRAM_ENDPOINTS {
  PROGRAM_GET = "/programs",
  PROGRAM_CREATE = "/programs",
  PROGRAM_EDIT = "/programs",
  PROGRAM_GET_OPTIONS = "/programs/options",
  PROGRAM_UPLOAD_ICON = "/programs/upload/icon",
  PROGRAM_UPDATE_LIMIT_REPORT = "/update-limit-report",
}

export enum CHAT_ENDPOINTS {
  CHAT_GET_ROOMS = "/rooms/loadRooms",
  CHAT_CREATE_ROOM = "/rooms/createRoom",
  CHAT_CREATE_MESSAGE = "/rooms/sendMessage",
  CHAT_GET_MESSAGE = "/rooms",
  CHAT_READ_MESSAGES = "/rooms/updateMessagesStatus",
  CHAT_GET_ROOM = "/rooms/",
}
