import files from "../../services/files";
import { Buffer } from "buffer";

class FilesRequests {
  public async get(url: string) {
    try {
      const response = await files.get(url, { responseType: "arraybuffer" });

      return `data:${response.headers["content-type"]};base64,${new Buffer(
        response.data,
        "binary"
      ).toString("base64")}`;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }
}

export default new FilesRequests();
