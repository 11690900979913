import api from "../../services/api";
import OverviewAdminInterface from "../../interfaces/overview";
import { OVERVIEW_ADMIN_ENDPOINTS } from "../../enums/apiEndpoints";

class OverviewAdminRequest {
  public async get(month: string) {
    try {
      let url: string = OVERVIEW_ADMIN_ENDPOINTS.GET;

      url += `?month=${month}`;

      const response = await api.get<OverviewAdminInterface>(url);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }
}

export default new OverviewAdminRequest();
