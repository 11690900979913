import authHandler from "../../utils/handlers/authHandler";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import { ArrowUpIcon, InfoOutlineIcon, StarIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../enums/routes";
import { BsArrowLeft } from "react-icons/bs";
import {
  Flex,
  Text,
  Box,
  Spacer,
  Button,
  FormControl,
  InputGroup,
  Input,
  FormLabel,
  Textarea,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Badge,
  Td,
  Avatar,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import programRequests from "../../utils/requests/programRequests";
import { programFieldsDescription } from "../../enums/fieldsDescription";

export default function CreateProgram() {
  const navigate = useNavigate();
  const toast = useToast();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [createProgramLoading, setCreateProgramLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [aboutProgram, setAboutProgram] = useState("");
  const [aboutInstitution, setAboutInstitution] = useState("");
  const [apis, setApis] = useState("");
  const [sites, setSites] = useState("");
  const [apps, setApps] = useState("");
  const [outOfScope, setOutOfScope] = useState("");
  const [criticalValue, setCriticalValue] = useState<number>(0.0);
  const [hightValue, setHightValue] = useState<number>(0.0);
  const [mediumValue, setMediumValue] = useState<number>(0.0);
  const [lowValue, setLowValue] = useState<number>(0.0);
  const [icon, setIcon] = useState<File>();

  const defaultMaskOptions = {
    prefix: "R$ ",
    suffix: ".00",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: "",
    decimalLimit: 0,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  const formatMoneyValue = (value: string) => {
    const defaultValue = 0;
    if (!value) return defaultValue;

    const formatedValue = value
      .replaceAll("R", "")
      .replaceAll("$", "")
      .replaceAll(" ", "")
      .replaceAll(",", "");

    const parsedValue: number = parseFloat(formatedValue);

    return parsedValue;
  };

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  const createProgram = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const userId = sessionControllerHandler.getUserID();
    if (!userId) return;

    setCreateProgramLoading(true);

    const body = {
      title,
      aboutProgram,
      aboutInstitution,
      apis,
      sites,
      apps,
      outOfScope,
      criticalValue,
      hightValue,
      mediumValue,
      lowValue,
      icon,
      isActive: true,
      company: { id: userId },
    };

    const createdProgramStatus = await programRequests.create(body);

    if (
      createdProgramStatus ===
      "Ocorreu um erro no servidor, tente novamente mais tarde"
    ) {
      toast({
        title: createdProgramStatus,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setCreateProgramLoading(false);
      return;
    }

    toast({
      title: "Programa cadastrado!",
      description: `O programa ${createdProgramStatus.program.title} foi criado com sucesso.`,
      status: "success",
      duration: 4000,
      isClosable: true,
    });

    if (icon) {
      const createdIconStatus = await programRequests.updateIcon(
        createdProgramStatus.program.id,
        icon
      );

      if (
        createdIconStatus ===
        "Ocorreu um erro no servidor, tente novamente mais tarde"
      ) {
        toast({
          title: "Não foi possível realizar o upload do ícone do programa.",
          description: createdIconStatus,
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
      }

      if (!createdIconStatus.id) {
        toast({
          title: "Não foi possível realizar o upload do ícone do programa.",
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
      }
    }

    setCreateProgramLoading(false);
    navigate(routes.PROGRAM);
  };

  const uploadIcon = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", ".png, .jpg, .jpeg");

    input.addEventListener("change", () => {
      if (input.files && input.files?.length !== 0) {
        setIcon(input.files[0]);
      }
      input.remove();
    });

    input.click();
  };

  return (
    <form onSubmit={(event) => createProgram(event)}>
      <Flex gap="1rem" flexDirection="row" alignItems="center" flexWrap="wrap">
        <Box
          as={BsArrowLeft}
          size="1.5rem"
          color="gray.700"
          cursor="pointer"
          onClick={() => navigate(routes.PROGRAM)}
        />

        <Text
          color="gray.700"
          fontSize="2xl"
          as="b"
          maxWidth="20%"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          cursor="default"
        >
          Título
        </Text>

        <FormControl w="19rem" isRequired>
          <InputGroup borderColor="gray.400" size="lg" variant="outline">
            <Input
              type="text"
              placeholder="Título do programa..."
              focusBorderColor="teal.500"
              color="gray.400"
              onChange={(event) => setTitle(event.target.value)}
            />
          </InputGroup>
        </FormControl>

        <Spacer />

        <Button
          colorScheme="teal"
          variant="outline"
          onClick={() => navigate(routes.PROGRAM)}
        >
          Cancelar
        </Button>

        <Button colorScheme="teal" variant="solid" type="submit">
          Criar Programa
        </Button>
      </Flex>

      <Flex
        p="1.5rem"
        marginTop="1.5rem"
        gap="1rem"
        bg="whiteAlpha.900"
        borderRadius="1rem"
        flexDirection="column"
      >
        <Text color="gray.700" size="sm">
          <b>ícone:</b>
        </Text>

        <Flex flexDirection="row" gap="1rem" alignItems="center">
          <Avatar
            src={icon && URL.createObjectURL(icon)}
            icon={<StarIcon fontSize="1.5rem" />}
          />

          <Button
            leftIcon={<ArrowUpIcon />}
            colorScheme="teal"
            variant="solid"
            onClick={() => uploadIcon()}
          >
            Carregar ícone
          </Button>
        </Flex>

        <FormControl isRequired>
          <FormLabel
            color="gray.700"
            size="sm"
            display="flex"
            alignItems="center"
            gap="0.5rem"
          >
            Sobre o programa:
            <Tooltip
              hasArrow
              label={programFieldsDescription.aboutProgram}
              placement="top"
            >
              <InfoOutlineIcon cursor="pointer" />
            </Tooltip>
          </FormLabel>
          <InputGroup
            color="gray.400"
            borderColor="gray.400"
            size="lg"
            variant="outline"
          >
            <Textarea
              placeholder="Sobre o programa..."
              focusBorderColor="teal.500"
              color="gray.400"
              fontSize="sm"
              h="7.5rem"
              onChange={(event) => setAboutProgram(event.target.value)}
            />
          </InputGroup>
        </FormControl>

        <FormControl isRequired>
          <FormLabel
            color="gray.700"
            size="sm"
            display="flex"
            alignItems="center"
            gap="0.5rem"
          >
            Sobre a instituição:
            <Tooltip
              hasArrow
              label={programFieldsDescription.aboutInstitution}
              placement="top"
            >
              <InfoOutlineIcon cursor="pointer" />
            </Tooltip>
          </FormLabel>
          <InputGroup
            color="gray.400"
            borderColor="gray.400"
            size="lg"
            variant="outline"
          >
            <Textarea
              placeholder="Sobre a instituição..."
              focusBorderColor="teal.500"
              color="gray.400"
              fontSize="sm"
              onChange={(event) => setAboutInstitution(event.target.value)}
            />
          </InputGroup>
        </FormControl>

        <TableContainer w="50%">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th borderColor="gray.400">
                  <Badge colorScheme="red" variant="solid">
                    Crítico
                  </Badge>
                </Th>

                <Th borderColor="gray.400">
                  <Badge colorScheme="orange" variant="solid">
                    Alto
                  </Badge>
                </Th>

                <Th borderColor="gray.400">
                  <Badge colorScheme="green" variant="solid">
                    Médio
                  </Badge>
                </Th>

                <Th borderColor="gray.400">
                  <Badge colorScheme="blue" variant="solid">
                    Baixo
                  </Badge>
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              <Tr>
                <Td borderColor="gray.400">
                  <FormControl isRequired>
                    <InputGroup
                      color="gray.400"
                      borderColor="gray.400"
                      size="lg"
                      variant="unstyled"
                    >
                      <Input
                        placeholder="R$ XX.XX"
                        color="gray.400"
                        fontSize="sm"
                        type="text"
                        as={MaskedInput}
                        mask={currencyMask}
                        onChange={(event) => {
                          setCriticalValue(
                            formatMoneyValue(event.target.value)
                          );
                        }}
                      />
                    </InputGroup>
                  </FormControl>
                </Td>
                <Td borderColor="gray.400">
                  <FormControl isRequired>
                    <InputGroup
                      color="gray.400"
                      borderColor="gray.400"
                      size="lg"
                      variant="unstyled"
                    >
                      <Input
                        placeholder="R$ XX.XX"
                        color="gray.400"
                        fontSize="sm"
                        type="text"
                        as={MaskedInput}
                        mask={currencyMask}
                        onChange={(event) => {
                          setHightValue(formatMoneyValue(event.target.value));
                        }}
                      />
                    </InputGroup>
                  </FormControl>
                </Td>
                <Td borderColor="gray.400">
                  <FormControl isRequired>
                    <InputGroup
                      color="gray.400"
                      borderColor="gray.400"
                      size="lg"
                      variant="unstyled"
                    >
                      <Input
                        placeholder="R$ XX.XX"
                        color="gray.400"
                        fontSize="sm"
                        type="text"
                        as={MaskedInput}
                        mask={currencyMask}
                        onChange={(event) => {
                          setMediumValue(formatMoneyValue(event.target.value));
                        }}
                      />
                    </InputGroup>
                  </FormControl>
                </Td>
                <Td borderColor="gray.400">
                  <FormControl isRequired>
                    <InputGroup
                      color="gray.400"
                      borderColor="gray.400"
                      size="lg"
                      variant="unstyled"
                    >
                      <Input
                        placeholder="R$ XX.XX"
                        color="gray.400"
                        fontSize="sm"
                        type="text"
                        as={MaskedInput}
                        mask={currencyMask}
                        onChange={(event) => {
                          setLowValue(formatMoneyValue(event.target.value));
                        }}
                      />
                    </InputGroup>
                  </FormControl>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <FormControl isRequired>
          <FormLabel
            color="gray.700"
            size="sm"
            display="flex"
            alignItems="center"
            gap="0.5rem"
          >
            API'S:
            <Tooltip
              hasArrow
              label={programFieldsDescription.apis}
              placement="top"
            >
              <InfoOutlineIcon cursor="pointer" />
            </Tooltip>
          </FormLabel>
          <InputGroup
            color="gray.400"
            borderColor="gray.400"
            size="lg"
            variant="outline"
          >
            <Textarea
              placeholder="API'S..."
              focusBorderColor="teal.500"
              color="gray.400"
              fontSize="sm"
              onChange={(event) => setApis(event.target.value)}
            />
          </InputGroup>
        </FormControl>

        <FormControl isRequired>
          <FormLabel
            color="gray.700"
            size="sm"
            display="flex"
            alignItems="center"
            gap="0.5rem"
          >
            Sites:
            <Tooltip
              hasArrow
              label={programFieldsDescription.sites}
              placement="top"
            >
              <InfoOutlineIcon cursor="pointer" />
            </Tooltip>
          </FormLabel>
          <InputGroup
            color="gray.400"
            borderColor="gray.400"
            size="lg"
            variant="outline"
          >
            <Textarea
              placeholder="Sites..."
              focusBorderColor="teal.500"
              color="gray.400"
              fontSize="sm"
              onChange={(event) => setSites(event.target.value)}
            />
          </InputGroup>
        </FormControl>

        <FormControl isRequired>
          <FormLabel
            color="gray.700"
            size="sm"
            display="flex"
            alignItems="center"
            gap="0.5rem"
          >
            Aplicativos:
            <Tooltip
              hasArrow
              label={programFieldsDescription.apps}
              placement="top"
            >
              <InfoOutlineIcon cursor="pointer" />
            </Tooltip>
          </FormLabel>

          <InputGroup
            color="gray.400"
            borderColor="gray.400"
            size="lg"
            variant="outline"
          >
            <Textarea
              placeholder="Aplicativos..."
              focusBorderColor="teal.500"
              color="gray.400"
              fontSize="sm"
              onChange={(event) => setApps(event.target.value)}
            />
          </InputGroup>
        </FormControl>

        <FormControl isRequired>
          <FormLabel
            color="gray.700"
            size="sm"
            display="flex"
            alignItems="center"
            gap="0.5rem"
          >
            Fora de escopo:
            <Tooltip
              hasArrow
              label={programFieldsDescription.outOfScope}
              placement="top"
            >
              <InfoOutlineIcon cursor="pointer" />
            </Tooltip>
          </FormLabel>
          <InputGroup
            color="gray.400"
            borderColor="gray.400"
            size="lg"
            variant="outline"
          >
            <Textarea
              placeholder="Fora de escopo..."
              focusBorderColor="teal.500"
              color="gray.400"
              h="21rem"
              fontSize="sm"
              onChange={(event) => setOutOfScope(event.target.value)}
            />
          </InputGroup>
        </FormControl>
      </Flex>
    </form>
  );
}
