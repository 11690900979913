import React, { useRef } from "react";
import Message from "../message";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import chatRequests from "../../utils/requests/chatRequests";
import moment from "moment";

import { ChatContext, ChatContextInterface } from "../../context/chatContext";
import { MessageInterface } from "../../interfaces/message";

import {
  BsChat,
  BsEmojiLaughing,
  BsFillArrowUpRightSquareFill,
  BsPaperclip,
} from "react-icons/bs";

import {
  Avatar,
  AvatarBadge,
  Badge,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";

import { userRoles } from "../../enums/roles";
import { useNavigate } from "react-router-dom";
import { routes } from "../../enums/routes";
import { ChatInterface } from "../../interfaces/chat";
import { userStatus } from "../../enums/userStatus";

export default function Chat() {
  const userId = sessionControllerHandler.getUserID();
  const toast = useToast();
  const chatBoxRef = useRef<any>(null);
  const navigate = useNavigate();

  const [showEmojis, setShowEmojis] = React.useState(false);
  const [newMessage, setNewMessage] = React.useState("");
  const [messages, setMessages] = React.useState<MessageInterface[]>([]);

  const { chatSelected, chats, updateChats } = React.useContext(
    ChatContext
  ) as ChatContextInterface;

  const anotherPersonInChat = chatSelected
    ? chatSelected.users.filter((value) => value.id != userId)
    : [
        {
          id: "",
          name: "",
          surname: "",
          email: "",
          status: userStatus.OFFLINE,
          lastTimeOnline: null,
        },
      ];

  React.useEffect(() => {
    if (!chatSelected || !userId) return;
    listMessages();
  }, [chatSelected]);

  React.useEffect(() => {
    if (!chatBoxRef || !chatBoxRef.current) return;

    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  }, [messages]);

  const addEmoji = (e: any) => {
    let sym = e.unified.split("-");
    let codesArray: any[] = [];
    sym.forEach((el: any) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setNewMessage(newMessage + emoji);
  };

  const handleSendMessageInputKeyDown = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) createMessage();
  };

  const createMessage = async () => {
    if (!chatSelected) return;
    if (!userId) return;
    if (!newMessage) return;

    const payload = {
      roomId: chatSelected.id,
      senderId: userId,
      message: newMessage,
    };

    const response = await chatRequests.createMessage(payload);

    if (!response.message) {
      toast({
        title: "Falha ao enviar menssagem.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    const newMessageList = (current: MessageInterface[]) => [
      ...current,
      {
        text: newMessage,
        createdAt: new Date(),
        sender: {
          id: userId,
          name: "",
          surname: "",
          useUploadedPic: false,
          email: "",
          passwordHash: "",
          cnpjOrCpf: "",
          cellphone: "",
          role: userRoles.COMPANY,
        },
      },
    ];

    setMessages(newMessageList);

    setNewMessage("");
  };

  const listMessages = async () => {
    if (!chatSelected) return;

    const response = await chatRequests.getMessages(chatSelected.id);

    setMessages(response.messages);
  };

  const readMessages = async () => {
    if (!chatSelected) return;
    if (!userId) return;

    const payload = {
      roomId: chatSelected.id,
      senderId: userId,
    };

    const response = await chatRequests.readMessages(payload);

    if (response.status != 204) return;

    updateNotificationsNumber(chatSelected.id);
  };

  const updateNotificationsNumber = (chatId: string) => {
    const updatedChats: ChatInterface[] = [];

    for (let chat of chats) {
      if (chat.id === chatId) {
        chat.unreadMessageCount = 0;
      }

      updatedChats.push(chat);
    }

    updateChats(updatedChats);
  };

  return (
    <Box
      position="relative"
      bg="whiteAlpha.900"
      w="70%"
      h="78vh"
      borderRadius="1rem"
    >
      {chatSelected ? (
        <>
          <Flex
            p="1rem 1.5rem"
            borderBottom="1px solid"
            borderColor="gray.200"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
            overflow="hidden"
          >
            <VStack spacing="0.5rem" alignItems="flex-start">
              <HStack spacing="0.5rem">
                <Avatar
                  name={`${anotherPersonInChat[0].name} ${anotherPersonInChat[0].surname}`}
                  bg="teal.500"
                  color="whiteAlpha.900"
                  size="sm"
                  src={``}
                >
                  <AvatarBadge
                    boxSize="1.25em"
                    bg={
                      anotherPersonInChat[0].status === userStatus.ONLINE
                        ? "green.500"
                        : "red.500"
                    }
                  />
                </Avatar>

                <Text fontSize="sm" as="b" noOfLines={1} color="gray.700">
                  {`${anotherPersonInChat[0].name} ${anotherPersonInChat[0].surname}`}
                </Text>
              </HStack>

              <Text noOfLines={1} color="gray.600">
                Última vez online:{" "}
                {anotherPersonInChat[0].lastTimeOnline
                  ? moment(
                      new Date(anotherPersonInChat[0].lastTimeOnline)
                    ).format("LLL")
                  : "Informação indisponível"}
              </Text>
            </VStack>

            <HStack spacing="1rem">
              {/* <Button size="sm" colorScheme="red">
                Abrir disputa
              </Button> */}

              <HStack spacing="0.5rem">
                <Text fontSize="sm" as="b" color="gray.700" noOfLines={1}>
                  Relatório ID:
                </Text>

                <Badge colorScheme="teal" isTruncated>
                  {chatSelected.report.id}
                </Badge>

                <Box
                  as={BsFillArrowUpRightSquareFill}
                  size="1.2rem"
                  color="teal.500"
                  cursor="pointer"
                  onClick={() =>
                    navigate(routes.REPORT_DETAILS + chatSelected.report.id)
                  }
                />
              </HStack>
            </HStack>
          </Flex>

          <Box
            w="100%"
            overflowY="auto"
            position="absolute"
            ref={chatBoxRef}
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
                margin: "0.5rem 0 0.5rem 0",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#38A169",
                borderRadius: "24px",
              },
              "@media screen and (min-height: 810px)": {
                height: "55vh",
              },
              "@media screen and (max-height: 810px)": {
                height: "46vh",
              },
            }}
          >
            <Flex
              p="1rem"
              flexDirection="column"
              gap="1rem"
              alignItems="baseline"
              justifyContent="flex-end"
            >
              {messages.map((message, index) => (
                <Message
                  key={index}
                  isFromLoggedUser={message.sender?.id === userId}
                  message={{
                    content: message.text,
                    createdAt: message.createdAt,
                  }}
                />
              ))}
            </Flex>
          </Box>

          <Flex
            p="1rem"
            position="absolute"
            bottom="0"
            w="100%"
            overflow="hidden"
          >
            <InputGroup>
              <Textarea
                placeholder="Digite aqui"
                size="sm"
                resize="none"
                borderRadius="0.5rem"
                focusBorderColor="teal.500"
                value={newMessage}
                onChange={(event: any) => setNewMessage(event.target.value)}
                onKeyDown={handleSendMessageInputKeyDown}
                onFocus={readMessages}
              />
              <InputRightElement w="auto">
                <Button
                  p="0.2rem"
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                >
                  <Icon as={BsPaperclip} color="gray.700" boxSize="1rem" />
                </Button>

                <Button
                  p="0.2rem"
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                  onClick={() => setShowEmojis(!showEmojis)}
                >
                  <Icon as={BsEmojiLaughing} color="gray.700" boxSize="1rem" />
                </Button>
              </InputRightElement>
            </InputGroup>
          </Flex>

          {showEmojis && (
            <Flex position="absolute" right="1.5%" bottom="20%">
              <Picker data={data} onEmojiSelect={addEmoji} />
            </Flex>
          )}
        </>
      ) : (
        <Center h="100%" flexDirection="column" gap="1rem">
          <Icon as={BsChat} boxSize="20" color="gray.200" />
          <Text color="gray.200" as="b" fontSize="20">
            Nenhum chat selecionado
          </Text>
        </Center>
      )}
    </Box>
  );
}
