class SessionControllerHandler {
  clearRecords() {
    localStorage.clear();
  }

  getToken() {
    const sessionToken = localStorage.getItem("authentication_token");

    if (!sessionToken || sessionToken === "undefined") return null;

    const token: string = JSON.parse(sessionToken);

    return token;
  }

  setToken(token: string) {
    localStorage.setItem(
      "authentication_token",
      token ? JSON.stringify(token) : null
    );
  }

  setUserID(id: string) {
    localStorage.setItem("user_id", JSON.stringify(id));
  }

  getUserID() {
    const sessionUser = localStorage.getItem("user_id");

    if (!sessionUser) return null;

    const id: string = JSON.parse(sessionUser);

    return id;
  }

  setUserRole(role: string) {
    localStorage.setItem("user_role", JSON.stringify(role));
  }

  getUserRole() {
    const sessionUser = localStorage.getItem("user_role");

    if (!sessionUser) return null;

    const role: string = JSON.parse(sessionUser);

    return role;
  }

  setUserName(name: string) {
    localStorage.setItem("user_name", JSON.stringify(name));
  }

  getUserName() {
    const sessionUser = localStorage.getItem("user_name");

    if (!sessionUser) return null;

    const name: string = JSON.parse(sessionUser);

    return name;
  }

  setUserSurname(surname: string) {
    localStorage.setItem("user_surname", JSON.stringify(surname));
  }

  getUserSurname() {
    const sessionUser = localStorage.getItem("user_surname");

    if (!sessionUser) return null;

    const surname: string = JSON.parse(sessionUser);

    return surname;
  }

  setUseUploadedPic(useUploadedPic: boolean) {
    localStorage.setItem("use_uploaded_pic", JSON.stringify(useUploadedPic));
  }

  getUseUploadedPic() {
    const sessionUser = localStorage.getItem("use_uploaded_pic");

    if (!sessionUser) return null;

    const useUploadedPic: boolean = JSON.parse(sessionUser);

    return useUploadedPic;
  }

  setProfilePicId(profilePicId: string) {
    localStorage.setItem("profile_pic_id", JSON.stringify(profilePicId));
  }

  getProfilePicId() {
    const sessionUser = localStorage.getItem("profile_pic_id");

    if (!sessionUser) return null;

    const profilePicId: string = JSON.parse(sessionUser);

    return profilePicId;
  }

  setEmailToChangePassword(email: string) {
    localStorage.setItem("emailToChangePassword", JSON.stringify(email));
  }

  getEmailToChangePassword() {
    const sessionInfo = localStorage.getItem("emailToChangePassword");

    if (!sessionInfo) return null;

    const email: string = JSON.parse(sessionInfo);

    return email;
  }

  setEmail(email: string) {
    localStorage.setItem("email", JSON.stringify(email));
  }

  getEmail() {
    const sessionInfo = localStorage.getItem("email");

    if (!sessionInfo) return undefined;

    const email: string = JSON.parse(sessionInfo);

    return email;
  }

  getUserLevel() {
    const sessionInfo = localStorage.getItem("user_level");

    if (!sessionInfo) return null;

    const info: number = JSON.parse(sessionInfo);

    return info;
  }

  setUserLevel(level: number) {
    localStorage.setItem("user_level", JSON.stringify(level));
  }
}

export default new SessionControllerHandler();
