import "./style.css";

import authHandler from "../../utils/handlers/authHandler";
import reportRequests from "../../utils/requests/reportRequests";
import ReportInterface from "../../interfaces/report";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../enums/routes";
import { reportCritically } from "../../enums/critically";
import { reportStatus } from "../../enums/status";
import { reasonForStatus } from "../../enums/reasonForStatus";
import { BsArrowLeft, BsPlusCircle } from "react-icons/bs";
import {
  Flex,
  Text,
  Box,
  Spacer,
  Button,
  FormControl,
  InputGroup,
  Input,
  FormLabel,
  Textarea,
  useToast,
  Select,
  HStack,
  Center,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import AttachmentInterface from "../../interfaces/attachment";
import { ATTCHMENTS_ENDPOINTS } from "../../enums/apiEndpoints";
import { reportVulnerability } from "../../enums/vulnerability";
import ProgramInterface from "../../interfaces/program";
import { reportFieldsDescription } from "../../enums/fieldsDescription";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { retestStatus } from "../../enums/retestStatus";

export default function EditReport() {
  let { id } = useParams();

  const navigate = useNavigate();
  const toast = useToast();
  const zoomImageModal = useDisclosure();
  const zoomExistentImageModal = useDisclosure();

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [imageToZoom, setImageToZoom] = useState<File>();
  const [existentImageToZoom, setExistentImageToZoom] =
    useState<AttachmentInterface>();

  const [getReportIsLoading, setGetReportIsLoading] = useState(true);
  const [editReportLoading, setEditReportLoading] = useState(false);

  const [vulnerability, setVulnerability] = useState<reportVulnerability>(
    reportVulnerability.BROKEN_ACCESS_CONTROL
  );
  const [critically, setCritically] = useState<reportCritically>(
    reportCritically.LOW
  );
  const [title, setTitle] = useState("");
  const [operation, setOperation] = useState("");
  const [softwares, setSoftwares] = useState("");
  const [howToReproduce, setHowToReproduce] = useState("");
  const [solutions, setSolutions] = useState("");
  const [status, setStatus] = useState<reportStatus>(reportStatus.NEW);
  const [newReasonsForStatus, setNewReasonsForStatus] =
    useState<reasonForStatus | null>(null);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [existentAttachments, setExistentAttachments] = useState<
    AttachmentInterface[]
  >([]);
  const [program, setProgram] = useState<ProgramInterface>();
  const [reportValue, setReportValue] = useState<number>();
  const [solution, setSolution] = useState<string | null>(null);
  const [retestStatusValue, setRetestStatusValue] = useState<retestStatus>(
    retestStatus.UNSOLICITED
  );

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
    getReportDetail();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  const getReportDetail = async () => {
    setGetReportIsLoading(true);

    if (!id) {
      toast({
        title: "Relatório não identificado.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setGetReportIsLoading(false);

      return;
    }

    const response = await reportRequests.get(id);

    if (!response.id) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setGetReportIsLoading(false);

      return;
    }

    const reportDetails: ReportInterface = response;

    setCritically(reportDetails.critically as reportCritically);
    setTitle(reportDetails.title);
    setOperation(reportDetails.operation);
    setSoftwares(reportDetails.softwares);
    setHowToReproduce(reportDetails.howToReproduce);
    setSolutions(reportDetails.possibleSolutions);
    setStatus(reportDetails.status);
    setExistentAttachments(reportDetails.attachments);
    setVulnerability(reportDetails.vulnerability);
    setProgram(reportDetails.program);
    setNewReasonsForStatus(reportDetails.reasonForStatus);
    setReportValue(reportDetails.value);
    setSolution(reportDetails.solution ?? null);
    setRetestStatusValue(reportDetails.retestStatus);
    setGetReportIsLoading(false);
  };

  const editReport = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setEditReportLoading(true);

    if (!id) {
      toast({
        title: "Não é possível editar relatório.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setEditReportLoading(false);

      return;
    }

    const body = {
      id,
      title,
      operation,
      softwares,
      howToReproduce,
      possibleSolutions: solutions,
      critically,
      status,
      vulnerability,
      reasonForStatus: newReasonsForStatus,
      value: reportValue ?? null,
      solution: solution ?? null,
      retestStatus: retestStatusValue,
    };

    const response = await reportRequests.update(body);

    if (!response.id) {
      toast({
        title: "Não é possível editar relatório.",
        description: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setEditReportLoading(false);
      return;
    }

    toast({
      title: "Editado e enviado!",
      description: "Seu relatório foi salvo e enviado com sucesso!",
      status: "success",
      duration: 4000,
      isClosable: true,
    });

    for (let propertie in attachments) {
      const attachment = attachments[propertie];

      const uploadStatus = await reportRequests.uploadAttachments(
        response.id,
        attachment
      );

      if (uploadStatus !== "Anexos adicionados") {
        toast({
          title: "Não foi possível adicionar o anexo " + attachment.name,
          description: uploadStatus,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    }

    setEditReportLoading(false);
    navigate(routes.REPORT_DETAILS + id);
  };

  const includeAttachment = (newAttachment: File) => {
    setAttachments([...attachments, ...[newAttachment]]);
  };

  const selectAttachment = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute(
      "accept",
      ".png, .jpg, .jpeg, video/mp4, video/x-m4v, video/*"
    );

    input.addEventListener("change", () => {
      if (input.files && input.files?.length !== 0) {
        includeAttachment(input.files[0]);
      }
      input.remove();
    });

    input.click();
  };

  return (
    <>
      {getReportIsLoading ? (
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          h="70vh"
        >
          <Spinner size="xl" color="teal.500" />
        </Flex>
      ) : (
        <form onSubmit={(event) => editReport(event)}>
          <Flex
            gap="1rem"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
          >
            <Box
              as={BsArrowLeft}
              size="1.5rem"
              color="gray.700"
              cursor="pointer"
              onClick={() => navigate(routes.REPORT_DETAILS + id)}
            />

            <Text color="gray.700" size="sm">
              <b>{title}</b>
            </Text>

            <Spacer />

            <Button
              colorScheme="teal"
              variant="outline"
              onClick={() => navigate(routes.REPORT_DETAILS + id)}
            >
              Cancelar
            </Button>

            <Button
              colorScheme="teal"
              variant="solid"
              type="submit"
              isLoading={editReportLoading}
            >
              Editar relatório
            </Button>
          </Flex>

          <Flex
            p="1.5rem"
            marginTop="1.5rem"
            gap="1rem"
            bg="whiteAlpha.900"
            borderRadius="1rem"
            flexDirection="column"
          >
            {program && (
              <FormControl>
                <FormLabel color="gray.700" size="sm">
                  Origem do relatório / Programa
                </FormLabel>
                <InputGroup
                  color="gray.400"
                  borderColor="gray.400"
                  size="lg"
                  variant="outline"
                >
                  <Input
                    placeholder="Digite aqui"
                    focusBorderColor="teal.500"
                    color="gray.400"
                    fontSize="sm"
                    value={program.title}
                    disabled
                  />
                </InputGroup>
              </FormControl>
            )}

            <FormControl isRequired>
              <FormLabel
                color="gray.700"
                size="sm"
                display="flex"
                alignItems="center"
                gap="0.5rem"
              >
                Vulnerabilidade
                <Tooltip
                  hasArrow
                  label={reportFieldsDescription.vulnerability}
                  placement="top"
                >
                  <InfoOutlineIcon cursor="pointer" />
                </Tooltip>
              </FormLabel>

              <InputGroup
                color="gray.400"
                borderColor="gray.400"
                size="lg"
                variant="outline"
              >
                <Select
                  placeholder="Selecione"
                  focusBorderColor="teal.500"
                  color="gray.400"
                  fontSize="sm"
                  borderColor="gray.400"
                  value={vulnerability}
                  onChange={(event) =>
                    setVulnerability(event.target.value as reportVulnerability)
                  }
                >
                  {Object.entries(reportVulnerability).map(
                    ([key, vulnerability], index) => (
                      <option key={index} value={vulnerability}>
                        {vulnerability}
                      </option>
                    )
                  )}
                </Select>
              </InputGroup>
            </FormControl>

            <FormControl isRequired>
              <FormLabel
                color="gray.700"
                size="sm"
                display="flex"
                alignItems="center"
                gap="0.5rem"
              >
                Criticidade
                <Tooltip
                  hasArrow
                  label={reportFieldsDescription.critically}
                  placement="top"
                >
                  <InfoOutlineIcon cursor="pointer" />
                </Tooltip>
              </FormLabel>

              <InputGroup
                color="gray.400"
                borderColor="gray.400"
                size="lg"
                variant="outline"
              >
                <Select
                  placeholder="Selecione"
                  focusBorderColor="teal.500"
                  color="gray.400"
                  fontSize="sm"
                  borderColor="gray.400"
                  onChange={(event) =>
                    setCritically(event.target.value as reportCritically)
                  }
                  value={critically}
                >
                  <option value={reportCritically.CRITICAL}>Crítico</option>
                  <option value={reportCritically.HIGH}>Alto</option>
                  <option value={reportCritically.LOW}>Baixo</option>
                  <option value={reportCritically.MEDIUM}>Médio</option>
                </Select>
              </InputGroup>
            </FormControl>

            <FormControl isRequired>
              <FormLabel
                color="gray.700"
                size="sm"
                display="flex"
                alignItems="center"
                gap="0.5rem"
              >
                Título do Relatório
                <Tooltip
                  hasArrow
                  label={reportFieldsDescription.title}
                  placement="top"
                >
                  <InfoOutlineIcon cursor="pointer" />
                </Tooltip>
              </FormLabel>

              <InputGroup
                color="gray.400"
                borderColor="gray.400"
                size="lg"
                variant="outline"
              >
                <Input
                  placeholder="Digite aqui"
                  focusBorderColor="teal.500"
                  color="gray.400"
                  fontSize="sm"
                  defaultValue={title}
                  onChange={(event) => setTitle(event.target.value)}
                />
              </InputGroup>
            </FormControl>

            <FormControl isRequired>
              <FormLabel
                color="gray.700"
                size="sm"
                display="flex"
                alignItems="center"
                gap="0.5rem"
              >
                Softwares Utilizados
                <Tooltip
                  hasArrow
                  label={reportFieldsDescription.softwares}
                  placement="top"
                >
                  <InfoOutlineIcon cursor="pointer" />
                </Tooltip>
              </FormLabel>

              <InputGroup
                color="gray.400"
                borderColor="gray.400"
                size="lg"
                variant="outline"
              >
                <Input
                  placeholder="Digite aqui"
                  focusBorderColor="teal.500"
                  color="gray.400"
                  fontSize="sm"
                  onChange={(event) => setSoftwares(event.target.value)}
                  defaultValue={softwares}
                />
              </InputGroup>
            </FormControl>

            <HStack spacing="1rem">
              <FormControl isRequired>
                <FormLabel
                  color="gray.700"
                  size="sm"
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                >
                  Funcionamento
                  <Tooltip
                    hasArrow
                    label={reportFieldsDescription.operaton}
                    placement="top"
                  >
                    <InfoOutlineIcon cursor="pointer" />
                  </Tooltip>
                </FormLabel>
                <InputGroup
                  color="gray.400"
                  borderColor="gray.400"
                  size="lg"
                  variant="outline"
                >
                  <Textarea
                    placeholder="Digite aqui"
                    focusBorderColor="teal.500"
                    color="gray.400"
                    fontSize="sm"
                    defaultValue={operation}
                    onChange={(event) => setOperation(event.target.value)}
                  />
                </InputGroup>
              </FormControl>

              <FormControl isRequired>
                <FormLabel
                  color="gray.700"
                  size="sm"
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                >
                  Como reproduzir
                  <Tooltip
                    hasArrow
                    label={reportFieldsDescription.howToReproduce}
                    placement="top"
                  >
                    <InfoOutlineIcon cursor="pointer" />
                  </Tooltip>
                </FormLabel>

                <InputGroup
                  color="gray.400"
                  borderColor="gray.400"
                  size="lg"
                  variant="outline"
                >
                  <Textarea
                    placeholder="Digite aqui"
                    focusBorderColor="teal.500"
                    color="gray.400"
                    fontSize="sm"
                    onChange={(event) => setHowToReproduce(event.target.value)}
                    defaultValue={howToReproduce}
                  />
                </InputGroup>
              </FormControl>
            </HStack>

            <FormControl isRequired>
              <FormLabel
                color="gray.700"
                size="sm"
                display="flex"
                alignItems="center"
                gap="0.5rem"
              >
                Informações adicionais
                <Tooltip
                  hasArrow
                  label={reportFieldsDescription.solutions}
                  placement="top"
                >
                  <InfoOutlineIcon cursor="pointer" />
                </Tooltip>
              </FormLabel>

              <InputGroup
                color="gray.400"
                borderColor="gray.400"
                size="lg"
                variant="outline"
              >
                <Textarea
                  placeholder="Digite aqui"
                  focusBorderColor="teal.500"
                  color="gray.400"
                  fontSize="sm"
                  defaultValue={solutions}
                  onChange={(event) => setSolutions(event.target.value)}
                />
              </InputGroup>
            </FormControl>

            <FormControl>
              <FormLabel
                color="gray.700"
                size="sm"
                display="flex"
                alignItems="center"
                gap="0.5rem"
              >
                Solução
                <Tooltip
                  hasArrow
                  label={reportFieldsDescription.solution}
                  placement="top"
                >
                  <InfoOutlineIcon cursor="pointer" />
                </Tooltip>
              </FormLabel>
              <InputGroup
                color="gray.400"
                borderColor="gray.400"
                size="lg"
                variant="outline"
              >
                <Textarea
                  placeholder="Digite aqui"
                  focusBorderColor="teal.500"
                  color="gray.400"
                  fontSize="sm"
                  onChange={(event) => setSolution(event.target.value)}
                  defaultValue={solution ?? ""}
                />
              </InputGroup>
            </FormControl>

            <Flex gap="0.5rem" flexDirection="column">
              <Text
                color="gray.700"
                fontWeight="500"
                size="sm"
                display="flex"
                alignItems="center"
                gap="0.5rem"
              >
                Anexos
                <Tooltip
                  hasArrow
                  label={reportFieldsDescription.attachments}
                  placement="top"
                >
                  <InfoOutlineIcon cursor="pointer" />
                </Tooltip>
              </Text>

              <Flex
                border="1px"
                borderColor="gray.400"
                borderRadius="0.3rem"
                p="0.6rem"
                gap="0.6rem"
              >
                {existentAttachments.map((existentAttachment, index) =>
                  existentAttachment.mimetype.includes("image") ? (
                    <Image
                      key={index}
                      src={
                        process.env.REACT_APP_BASE_URL +
                        ATTCHMENTS_ENDPOINTS.ATTACHMENT_GET +
                        `/${existentAttachment.id}`
                      }
                      alt={"Imagem " + existentAttachment.name}
                      boxSize="4.5rem"
                      objectFit="cover"
                      borderRadius="0.3rem"
                      borderColor="gray.400"
                      title={existentAttachment.name}
                      fallbackSrc="https://via.placeholder.com/150"
                      cursor="pointer"
                      onClick={() => {
                        setExistentImageToZoom(existentAttachment);
                        zoomExistentImageModal.onOpen();
                      }}
                    />
                  ) : (
                    <video
                      src={
                        process.env.REACT_APP_BASE_URL +
                        ATTCHMENTS_ENDPOINTS.ATTACHMENT_GET +
                        `/${existentAttachment.id}`
                      }
                      title={existentAttachment.name}
                      controls
                      controlsList="nodownload"
                      className="video"
                    />
                  )
                )}

                {attachments.map((attachment, index) =>
                  attachment.type.includes("image") ? (
                    <Image
                      key={index}
                      src={URL.createObjectURL(attachment)}
                      alt={"Imagem " + attachment.name}
                      boxSize="4.5rem"
                      objectFit="cover"
                      borderRadius="0.3rem"
                      borderColor="gray.400"
                      title={attachment.name}
                      fallbackSrc="https://via.placeholder.com/150"
                      cursor="pointer"
                      onClick={() => {
                        setImageToZoom(attachment);
                        zoomImageModal.onOpen();
                      }}
                    />
                  ) : (
                    <video
                      src={URL.createObjectURL(attachment)}
                      title={attachment.name}
                      controls
                      controlsList="nodownload"
                      className="video"
                    />
                  )
                )}

                <Center
                  w="4.5rem"
                  h="4.5rem"
                  border="1px"
                  borderColor="gray.400"
                  borderRadius="0.3rem"
                >
                  <Box
                    as={BsPlusCircle}
                    size="1.5rem"
                    color="gray.400"
                    cursor="pointer"
                    onClick={() => selectAttachment()}
                    _hover={{ color: "gray.600" }}
                  />
                </Center>
              </Flex>
            </Flex>
          </Flex>
        </form>
      )}

      <Modal
        size="full"
        isOpen={zoomImageModal.isOpen}
        onClose={zoomImageModal.onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody m="0" p="2.5rem">
            <Flex justifyContent="center" h="80vh">
              {imageToZoom && (
                <Image
                  src={URL.createObjectURL(imageToZoom)}
                  alt={imageToZoom.name}
                  borderRadius="8px"
                  fallbackSrc="https://via.placeholder.com/150"
                  w="auto"
                  h="100%"
                  objectFit="contain"
                />
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        size="full"
        isOpen={zoomExistentImageModal.isOpen}
        onClose={zoomExistentImageModal.onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody m="0" p="2.5rem">
            <Flex justifyContent="center" h="80vh">
              {existentImageToZoom && (
                <Image
                  src={
                    process.env.REACT_APP_BASE_URL +
                    ATTCHMENTS_ENDPOINTS.ATTACHMENT_GET +
                    `/${existentImageToZoom.id}`
                  }
                  alt={existentImageToZoom.name}
                  borderRadius="8px"
                  fallbackSrc="https://via.placeholder.com/150"
                  w="auto"
                  h="100%"
                  objectFit="contain"
                />
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
