import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import React, { useState } from "react";
import { userRoles } from "../../enums/roles";
import { Flex, Spacer, HStack, Box, Text } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../enums/routes";
import { FaBuilding } from "react-icons/fa";
import {
  BsFillHouseDoorFill,
  BsFillFileEarmarkFill,
  BsFillEnvelopeFill,
  BsFillTabletLandscapeFill,
  BsCode,
  BsFillBellFill,
  BsGearFill,
} from "react-icons/bs";

const NavigationMenu: React.FC = () => {
  const userRole = sessionControllerHandler.getUserRole();
  const navigate = useNavigate();
  let location = useLocation();
  const [currentPage, setCurrentPage] = useState<routes>(routes.HOMEPAGE);

  React.useEffect(() => {
    updateCurrentPage(location.pathname);
  }, [location]);

  const updateCurrentPage = (route: string) => {
    const findPage = Object.entries(routes).find(([key, value]) => {
      if (route !== "/" && value !== "/") {
        return route.includes(value);
      }

      return route === value;
    });

    if (findPage) {
      const [pageKey, pageValue] = findPage;
      setCurrentPage(pageValue);
    }
  };

  return (
    <Flex
      position="sticky"
      top="0"
      background="whiteAlpha.900"
      direction="column"
      p="2.5rem"
      w="15vw"
      h="91vh"
      boxShadow="base"
      zIndex="1"
    >
      <Flex direction="column" gap="1rem">
        {userRoles.COMPANY === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.HOMEPAGE)}
          >
            <Box
              as={BsFillHouseDoorFill}
              size="1.25rem"
              color={currentPage === routes.HOMEPAGE ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.HOMEPAGE ? "teal.500" : "gray.700"}
            >
              Dashboard
            </Text>
          </HStack>
        )}

        {userRoles.GUEST === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.HOMEPAGE)}
          >
            <Box
              as={BsFillHouseDoorFill}
              size="1.25rem"
              color={currentPage === routes.HOMEPAGE ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.HOMEPAGE ? "teal.500" : "gray.700"}
            >
              Dashboard
            </Text>
          </HStack>
        )}

        {userRoles.RESEARCHER === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.HOMEPAGE)}
          >
            <Box
              as={BsFillHouseDoorFill}
              size="1.25rem"
              color={currentPage === routes.HOMEPAGE ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.HOMEPAGE ? "teal.500" : "gray.700"}
            >
              Dashboard
            </Text>
          </HStack>
        )}

        {userRoles.ADMIN === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.HOMEPAGE)}
          >
            <Box
              as={BsFillHouseDoorFill}
              size="1.25rem"
              color={currentPage === routes.HOMEPAGE ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.HOMEPAGE ? "teal.500" : "gray.700"}
            >
              Dashboard
            </Text>
          </HStack>
        )}

        {userRoles.ADMIN === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.COMPANIES)}
          >
            <Box
              as={FaBuilding}
              size="1.25rem"
              color={currentPage === routes.COMPANIES ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.COMPANIES ? "teal.500" : "gray.700"}
            >
              Empresas
            </Text>
          </HStack>
        )}

        {userRoles.COMPANY === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.REPORTS)}
          >
            <Box
              as={BsFillFileEarmarkFill}
              size="1.25rem"
              color={currentPage === routes.REPORTS ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.REPORTS ? "teal.500" : "gray.700"}
            >
              Relatórios
            </Text>
          </HStack>
        )}

        {userRoles.GUEST === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.REPORTS)}
          >
            <Box
              as={BsFillFileEarmarkFill}
              size="1.25rem"
              color={currentPage === routes.REPORTS ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.REPORTS ? "teal.500" : "gray.700"}
            >
              Relatórios
            </Text>
          </HStack>
        )}

        {userRoles.RESEARCHER === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.REPORTS)}
          >
            <Box
              as={BsFillFileEarmarkFill}
              size="1.25rem"
              color={currentPage === routes.REPORTS ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.REPORTS ? "teal.500" : "gray.700"}
            >
              Relatórios
            </Text>
          </HStack>
        )}

        {userRoles.COMPANY === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.MESSAGES)}
          >
            <Box
              as={BsFillEnvelopeFill}
              size="1.25rem"
              color={currentPage === routes.MESSAGES ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.MESSAGES ? "teal.500" : "gray.700"}
            >
              Mensagens
            </Text>
          </HStack>
        )}

        {userRoles.GUEST === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.MESSAGES)}
          >
            <Box
              as={BsFillEnvelopeFill}
              size="1.25rem"
              color={currentPage === routes.MESSAGES ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.MESSAGES ? "teal.500" : "gray.700"}
            >
              Mensagens
            </Text>
          </HStack>
        )}

        {userRoles.RESEARCHER === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.MESSAGES)}
          >
            <Box
              as={BsFillEnvelopeFill}
              size="1.25rem"
              color={currentPage === routes.MESSAGES ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.MESSAGES ? "teal.500" : "gray.700"}
            >
              Mensagens
            </Text>
          </HStack>
        )}

        {userRoles.COMPANY === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.FINANCIAL)}
          >
            <Box
              as={BsFillTabletLandscapeFill}
              size="1.25rem"
              color={currentPage === routes.FINANCIAL ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.FINANCIAL ? "teal.500" : "gray.700"}
            >
              Financeiro
            </Text>
          </HStack>
        )}

        {userRoles.GUEST === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.FINANCIAL)}
          >
            <Box
              as={BsFillTabletLandscapeFill}
              size="1.25rem"
              color={currentPage === routes.FINANCIAL ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.FINANCIAL ? "teal.500" : "gray.700"}
            >
              Financeiro
            </Text>
          </HStack>
        )}

        {userRoles.RESEARCHER === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.FINANCIAL)}
          >
            <Box
              as={BsFillTabletLandscapeFill}
              size="1.25rem"
              color={currentPage === routes.FINANCIAL ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.FINANCIAL ? "teal.500" : "gray.700"}
            >
              Financeiro
            </Text>
          </HStack>
        )}

        {userRoles.COMPANY === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.PROGRAM)}
          >
            <Box
              as={BsCode}
              size="1.25rem"
              color={currentPage === routes.PROGRAM ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.PROGRAM ? "teal.500" : "gray.700"}
            >
              Meu programa
            </Text>
          </HStack>
        )}

        {userRoles.GUEST === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.PROGRAM)}
          >
            <Box
              as={BsCode}
              size="1.25rem"
              color={currentPage === routes.PROGRAM ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.PROGRAM ? "teal.500" : "gray.700"}
            >
              Meu programa
            </Text>
          </HStack>
        )}

        {userRoles.RESEARCHER === userRole && (
          <HStack
            spacing="1rem"
            cursor="pointer"
            onClick={() => navigate(routes.PROGRAM)}
          >
            <Box
              as={BsCode}
              size="1.25rem"
              color={currentPage === routes.PROGRAM ? "teal.500" : "gray.700"}
            />
            <Text
              color={currentPage === routes.PROGRAM ? "teal.500" : "gray.700"}
            >
              Programas
            </Text>
          </HStack>
        )}
      </Flex>

      <Spacer />

      <Flex direction="column" gap="1rem">
        <HStack spacing="1rem" cursor="pointer">
          <Box as={BsFillBellFill} size="1.25rem" color="gray.700" />
          <Text color="gray.700">Notificação</Text>
        </HStack>

        <HStack spacing="1rem" cursor="pointer">
          <Box as={BsGearFill} size="1.25rem" color="gray.700" />
          <Text color="gray.700">Configuração</Text>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default NavigationMenu;
