import React from "react";
import { Badge, ChakraProvider } from "@chakra-ui/react";
import { reportStatus } from "../../enums/status";

interface Props {
  status: reportStatus;
}

const StatusBadge: React.FC<Props> = ({ status }) => {
  const normalizeInfos = () => {
    if (status === reportStatus.ACCEPT) {
      return { color: "green", label: "ACEITO" };
    }

    if (status === reportStatus.MORE_INFORMATION) {
      return { color: "cyan", label: "MAIS INFORMAÇÕES" };
    }

    if (status === reportStatus.NOT_ACCEPT) {
      return { color: "red", label: "NÃO ACEITO" };
    }

    if (status === reportStatus.NEW) {
      return { color: "yellow", label: "NOVO" };
    }

    if (status === reportStatus.IN_ANALYSIS) {
      return { color: "purple", label: "EM ANÁLISE" };
    }

    return { color: "teal", label: status };
  };

  const infos: { color: string; label: string } = normalizeInfos();

  return (
    <ChakraProvider>
      <Badge colorScheme={infos.color} variant="solid">
        {infos.label}
      </Badge>
    </ChakraProvider>
  );
};

export default StatusBadge;
