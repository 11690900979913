import { Center } from "@chakra-ui/react";
import React from "react";
import RegisterModal from "../../components/registerModal";

export default function RegisterUser() {
  return (
    <>
      <Center bg="gray.50" h="100vh">
        <RegisterModal />
      </Center>
    </>
  );
}
