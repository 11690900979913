import api from "../../services/api";
import { PROGRAM_ENDPOINTS } from "../../enums/apiEndpoints";
import ProgramInterface from "../../interfaces/program";

class ProgramRequests {
  public async getAll(
    lastDays: string,
    page: number,
    companyId?: string,
    email?: string,
    level?: number,
    userConnectedId?: string
  ) {
    try {
      let url: string = PROGRAM_ENDPOINTS.PROGRAM_GET;

      if (lastDays === "all") {
        url += `?page=${page}`;
      } else {
        url += `?lastDays=${lastDays}&page=${page}`;
      }

      if (companyId) {
        url += `&companyId=${companyId}`;
      }

      if (email) {
        url += `&email=${email}`;
      }

      if (level) {
        url += `&level=${level}`;
      }

      if (userConnectedId) {
        url += `&userConnectedId=${userConnectedId}`;
      }

      const response = await api.get(url);
      const programList = response.data;
      return programList;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async get(id: string) {
    try {
      let url: string = PROGRAM_ENDPOINTS.PROGRAM_GET + `/${id}`;

      const response = await api.get(url);
      const programs: ProgramInterface = response.data;
      return programs;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async create(program: {
    title: string;
    aboutProgram: string;
    aboutInstitution: string;
    apis: string;
    sites: string;
    apps: string;
    outOfScope: string;
    company: { id: string };
    isActive: boolean;
    icon?: File;

    criticalValue: number;
    hightValue: number;
    mediumValue: number;
    lowValue: number;
  }) {
    try {
      let url: string = PROGRAM_ENDPOINTS.PROGRAM_CREATE;

      const response = await api.post(url, program);
      const programs: ProgramInterface = response.data;
      return programs;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async edit(program: {
    id: string;
    title: string;
    aboutProgram: string;
    aboutInstitution: string;
    apis: string;
    sites: string;
    apps: string;
    outOfScope: string;
    isActive: boolean;

    criticalValue: number;
    hightValue: number;
    mediumValue: number;
    lowValue: number;
  }) {
    try {
      let url: string = PROGRAM_ENDPOINTS.PROGRAM_EDIT;

      const response = await api.put(url, program);
      const programs: ProgramInterface = response.data;
      return programs;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async updateIcon(id: string, icon: File) {
    try {
      let url: string = PROGRAM_ENDPOINTS.PROGRAM_UPLOAD_ICON;

      const body = new FormData();
      body.append("programId", id);
      body.append("file", icon);

      const response = await api.post(url, body);
      const programs: ProgramInterface = response.data;
      return programs;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async getOptions(companyId?: string | null) {
    try {
      let url: string = PROGRAM_ENDPOINTS.PROGRAM_GET_OPTIONS;

      if (companyId) {
        url += `?companyId=${companyId}`;
      }

      const response = await api.get(url);
      const programList = response.data;
      return programList;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async updateLimitReport(id: string, limitReport: number) {
    try {
      let url: string = PROGRAM_ENDPOINTS.PROGRAM_UPDATE_LIMIT_REPORT;

      const body = {
        id,
        limitReport,
      };

      const response = await api.put(url, body);
      const programs: ProgramInterface = response.data;
      return programs;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }
}

export default new ProgramRequests();
