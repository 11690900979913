import sessionControllerHandler from "./handlers/sessionControllerHandler";
import userRequests from "./requests/userRequests";

const logoutAndReload = () => {
  userRequests.logout({ id: sessionControllerHandler.getUserID() ?? "" });
  sessionControllerHandler.clearRecords();
  window.location.reload();
};

export default logoutAndReload;
