import authHandler from "../../utils/handlers/authHandler";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import programRequests from "../../utils/requests/programRequests";
import ProgramInterface from "../../interfaces/program";
import UserInterface from "../../interfaces/user";

import { ArrowUpIcon, InfoOutlineIcon, StarIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../enums/routes";
import { BsArrowLeft } from "react-icons/bs";
import {
  Flex,
  Text,
  Box,
  Spacer,
  Button,
  FormControl,
  InputGroup,
  Input,
  FormLabel,
  Textarea,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Badge,
  Td,
  useToast,
  Spinner,
  Avatar,
  Tooltip,
} from "@chakra-ui/react";
import { programFieldsDescription } from "../../enums/fieldsDescription";

export default function EditProgram() {
  let { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const [title, setTitle] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [aboutProgram, setAboutProgram] = useState("");
  const [aboutInstitution, setAboutInstitution] = useState("");
  const [apis, setApis] = useState("");
  const [sites, setSites] = useState("");
  const [apps, setApps] = useState("");
  const [outOfScope, setOutOfScope] = useState("");
  const [criticalValue, setCriticalValue] = useState<number>(0.0);
  const [hightValue, setHightValue] = useState<number>(0.0);
  const [mediumValue, setMediumValue] = useState<number>(0.0);
  const [lowValue, setLowValue] = useState<number>(0.0);
  const [iconUrl, setIconUrl] = useState("");
  const [newIcon, setNewIcon] = useState<File>();

  const defaultMaskOptions = {
    prefix: "R$ ",
    suffix: ".00",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: "",
    decimalLimit: 0,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  const formatMoneyValue = (value: string) => {
    const defaultValue = 0;
    if (!value) return defaultValue;

    const formatedValue = value
      .replaceAll("R", "")
      .replaceAll("$", "")
      .replaceAll(" ", "")
      .replaceAll(",", "");

    const parsedValue: number = parseFloat(formatedValue);

    return parsedValue;
  };

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
    getProgramDetail();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  const getProgramDetail = async () => {
    setIsLoading(true);

    if (!id) {
      toast({
        title: "Programa não identificado.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setIsLoading(false);
      return;
    }

    const response = await programRequests.get(id);

    if (!response.id) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setIsLoading(false);
      return;
    }

    const programDetails: ProgramInterface = response;

    setTitle(programDetails.title);
    setAboutProgram(programDetails.aboutProgram);
    setAboutInstitution(programDetails.aboutInstitution);
    setApis(programDetails.apis);
    setSites(programDetails.sites);
    setApps(programDetails.apps);
    setOutOfScope(programDetails.outOfScope);
    setCriticalValue(programDetails.criticalValue);
    setHightValue(programDetails.hightValue);
    setMediumValue(programDetails.mediumValue);
    setLowValue(programDetails.lowValue);
    setIconUrl(programDetails.iconUrl);
    setIsActive(programDetails.isActive);

    setIsLoading(false);
  };

  const editProgram = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!id) {
      toast({
        title: "Programa não identificado.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    setIsUpdating(true);

    const body = {
      id,
      title,
      aboutProgram,
      aboutInstitution,
      apis,
      sites,
      apps,
      outOfScope,
      criticalValue,
      hightValue,
      mediumValue,
      lowValue,
      isActive,
    };

    const editedProgramStatus = await programRequests.edit(body);

    if (!editedProgramStatus.id) {
      toast({
        title: editedProgramStatus,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setIsUpdating(false);
      return;
    }

    toast({
      title: "Alterações salvas.",
      description: "As informações foram atualizadas!",
      status: "success",
      duration: 4000,
      isClosable: true,
    });

    if (newIcon) {
      const uploadedIconStatus = await programRequests.updateIcon(
        editedProgramStatus.id,
        newIcon
      );

      if (
        uploadedIconStatus ===
        "Ocorreu um erro no servidor, tente novamente mais tarde"
      ) {
        toast({
          title: "Não foi possível realizar o upload do ícone do programa.",
          description: uploadedIconStatus,
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
      }

      if (!uploadedIconStatus.id) {
        toast({
          title: "Não foi possível realizar o upload do ícone do programa.",
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
      }
    }

    setIsUpdating(false);
    navigate(routes.PROGRAM);
    return;
  };

  const uploadIcon = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", ".png, .jpg, .jpeg");

    input.addEventListener("change", () => {
      if (input.files && input.files?.length !== 0) {
        setNewIcon(input.files[0]);
      }
      input.remove();
    });

    input.click();
  };

  return (
    <form onSubmit={(event) => editProgram(event)}>
      <Flex gap="1rem" flexDirection="row" alignItems="center" flexWrap="wrap">
        <Box
          as={BsArrowLeft}
          size="1.5rem"
          color="gray.700"
          cursor="pointer"
          onClick={() => navigate(routes.PROGRAM_DETAILS + id)}
        />

        <Text
          color="gray.700"
          fontSize="2xl"
          as="b"
          maxWidth="20%"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          cursor="default"
        >
          Título:
        </Text>

        <FormControl w="19rem" isRequired>
          <InputGroup borderColor="gray.400" size="lg" variant="outline">
            <Input
              type="text"
              placeholder="Título do programa..."
              focusBorderColor="teal.500"
              color="gray.400"
              onChange={(event) => setTitle(event.target.value)}
              defaultValue={title}
            />
          </InputGroup>
        </FormControl>

        <Spacer />

        <Button
          colorScheme="teal"
          isLoading={isUpdating}
          variant="solid"
          type="submit"
        >
          Salvar alterações
        </Button>
      </Flex>

      {isLoading ? (
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          h="70vh"
        >
          <Spinner size="xl" color="teal.500" />
        </Flex>
      ) : (
        <Flex
          p="1.5rem"
          marginTop="1.5rem"
          gap="1rem"
          bg="whiteAlpha.900"
          borderRadius="1rem"
          flexDirection="column"
        >
          <Text color="gray.700" size="sm">
            <b>ícone:</b>
          </Text>

          <Flex flexDirection="row" gap="1rem" alignItems="center">
            <Avatar
              src={newIcon ? URL.createObjectURL(newIcon) : iconUrl}
              icon={<StarIcon fontSize="1.5rem" />}
            />

            <Button
              leftIcon={<ArrowUpIcon />}
              colorScheme="teal"
              variant="solid"
              onClick={() => uploadIcon()}
            >
              Carregar ícone
            </Button>
          </Flex>

          <FormControl isRequired>
            <FormLabel
              color="gray.700"
              size="sm"
              display="flex"
              alignItems="center"
              gap="0.5rem"
            >
              Sobre o programa:
              <Tooltip
                hasArrow
                label={programFieldsDescription.aboutProgram}
                placement="top"
              >
                <InfoOutlineIcon cursor="pointer" />
              </Tooltip>
            </FormLabel>

            <InputGroup
              color="gray.400"
              borderColor="gray.400"
              size="lg"
              variant="outline"
            >
              <Textarea
                placeholder="Sobre o programa..."
                focusBorderColor="teal.500"
                color="gray.400"
                fontSize="sm"
                h="7.5rem"
                onChange={(event) => setAboutProgram(event.target.value)}
                defaultValue={aboutProgram}
              />
            </InputGroup>
          </FormControl>

          <FormControl isRequired>
            <FormLabel
              color="gray.700"
              size="sm"
              display="flex"
              alignItems="center"
              gap="0.5rem"
            >
              Sobre a instituição:
              <Tooltip
                hasArrow
                label={programFieldsDescription.aboutInstitution}
                placement="top"
              >
                <InfoOutlineIcon cursor="pointer" />
              </Tooltip>
            </FormLabel>

            <InputGroup
              color="gray.400"
              borderColor="gray.400"
              size="lg"
              variant="outline"
            >
              <Textarea
                placeholder="Sobre a instituição..."
                focusBorderColor="teal.500"
                color="gray.400"
                fontSize="sm"
                onChange={(event) => setAboutInstitution(event.target.value)}
                defaultValue={aboutInstitution}
              />
            </InputGroup>
          </FormControl>

          <TableContainer w="50%">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th borderColor="gray.400">
                    <Badge colorScheme="red" variant="solid">
                      Crítico
                    </Badge>
                  </Th>

                  <Th borderColor="gray.400">
                    <Badge colorScheme="orange" variant="solid">
                      Alto
                    </Badge>
                  </Th>

                  <Th borderColor="gray.400">
                    <Badge colorScheme="green" variant="solid">
                      Médio
                    </Badge>
                  </Th>

                  <Th borderColor="gray.400">
                    <Badge colorScheme="blue" variant="solid">
                      Baixo
                    </Badge>
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                <Tr>
                  <Td borderColor="gray.400">
                    <FormControl isRequired>
                      <InputGroup
                        color="gray.400"
                        borderColor="gray.400"
                        size="lg"
                        variant="unstyled"
                      >
                        <Input
                          placeholder="R$ XX.XX"
                          color="gray.400"
                          fontSize="sm"
                          type="text"
                          as={MaskedInput}
                          mask={currencyMask}
                          onChange={(event) => {
                            setCriticalValue(
                              formatMoneyValue(event.target.value)
                            );
                          }}
                          value={criticalValue.toFixed(2)}
                        />
                      </InputGroup>
                    </FormControl>
                  </Td>
                  <Td borderColor="gray.400">
                    <FormControl isRequired>
                      <InputGroup
                        color="gray.400"
                        borderColor="gray.400"
                        size="lg"
                        variant="unstyled"
                      >
                        <Input
                          placeholder="R$ XX.XX"
                          color="gray.400"
                          fontSize="sm"
                          type="text"
                          as={MaskedInput}
                          mask={currencyMask}
                          onChange={(event) => {
                            setHightValue(formatMoneyValue(event.target.value));
                          }}
                          value={hightValue.toFixed(2)}
                        />
                      </InputGroup>
                    </FormControl>
                  </Td>
                  <Td borderColor="gray.400">
                    <FormControl isRequired>
                      <InputGroup
                        color="gray.400"
                        borderColor="gray.400"
                        size="lg"
                        variant="unstyled"
                      >
                        <Input
                          placeholder="R$ XX.XX"
                          color="gray.400"
                          fontSize="sm"
                          type="text"
                          as={MaskedInput}
                          mask={currencyMask}
                          onChange={(event) => {
                            setMediumValue(
                              formatMoneyValue(event.target.value)
                            );
                          }}
                          value={mediumValue.toFixed(2)}
                        />
                      </InputGroup>
                    </FormControl>
                  </Td>
                  <Td borderColor="gray.400">
                    <FormControl isRequired>
                      <InputGroup
                        color="gray.400"
                        borderColor="gray.400"
                        size="lg"
                        variant="unstyled"
                      >
                        <Input
                          placeholder="R$ XX.XX"
                          color="gray.400"
                          fontSize="sm"
                          type="text"
                          as={MaskedInput}
                          mask={currencyMask}
                          onChange={(event) => {
                            setLowValue(formatMoneyValue(event.target.value));
                          }}
                          value={lowValue.toFixed(2)}
                        />
                      </InputGroup>
                    </FormControl>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          <FormControl isRequired>
            <FormLabel
              color="gray.700"
              size="sm"
              display="flex"
              alignItems="center"
              gap="0.5rem"
            >
              API'S:
              <Tooltip
                hasArrow
                label={programFieldsDescription.apis}
                placement="top"
              >
                <InfoOutlineIcon cursor="pointer" />
              </Tooltip>
            </FormLabel>

            <InputGroup
              color="gray.400"
              borderColor="gray.400"
              size="lg"
              variant="outline"
            >
              <Textarea
                placeholder="API'S..."
                focusBorderColor="teal.500"
                color="gray.400"
                fontSize="sm"
                onChange={(event) => setApis(event.target.value)}
                defaultValue={apis}
              />
            </InputGroup>
          </FormControl>

          <FormControl isRequired>
            <FormLabel
              color="gray.700"
              size="sm"
              display="flex"
              alignItems="center"
              gap="0.5rem"
            >
              Sites:
              <Tooltip
                hasArrow
                label={programFieldsDescription.sites}
                placement="top"
              >
                <InfoOutlineIcon cursor="pointer" />
              </Tooltip>
            </FormLabel>

            <InputGroup
              color="gray.400"
              borderColor="gray.400"
              size="lg"
              variant="outline"
            >
              <Textarea
                placeholder="Sites..."
                focusBorderColor="teal.500"
                color="gray.400"
                fontSize="sm"
                onChange={(event) => setSites(event.target.value)}
                defaultValue={sites}
              />
            </InputGroup>
          </FormControl>

          <FormControl isRequired>
            <FormLabel
              color="gray.700"
              size="sm"
              display="flex"
              alignItems="center"
              gap="0.5rem"
            >
              Aplicativos:
              <Tooltip
                hasArrow
                label={programFieldsDescription.apps}
                placement="top"
              >
                <InfoOutlineIcon cursor="pointer" />
              </Tooltip>
            </FormLabel>

            <InputGroup
              color="gray.400"
              borderColor="gray.400"
              size="lg"
              variant="outline"
            >
              <Textarea
                placeholder="Aplicativos..."
                focusBorderColor="teal.500"
                color="gray.400"
                fontSize="sm"
                onChange={(event) => setApps(event.target.value)}
                defaultValue={apps}
              />
            </InputGroup>
          </FormControl>

          <FormControl isRequired>
            <FormLabel
              color="gray.700"
              size="sm"
              display="flex"
              alignItems="center"
              gap="0.5rem"
            >
              Fora de escopo:
              <Tooltip
                hasArrow
                label={programFieldsDescription.outOfScope}
                placement="top"
              >
                <InfoOutlineIcon cursor="pointer" />
              </Tooltip>
            </FormLabel>

            <InputGroup
              color="gray.400"
              borderColor="gray.400"
              size="lg"
              variant="outline"
            >
              <Textarea
                placeholder="Fora de escopo..."
                focusBorderColor="teal.500"
                color="gray.400"
                h="21rem"
                fontSize="sm"
                onChange={(event) => setOutOfScope(event.target.value)}
                defaultValue={outOfScope}
              />
            </InputGroup>
          </FormControl>
        </Flex>
      )}
    </form>
  );
}
