import api from "../../services/api";
import { CHAT_ENDPOINTS } from "../../enums/apiEndpoints";

class ChatRequests {
  public async createRoom(reportId: string, senderId?: string) {
    try {
      let url: string = CHAT_ENDPOINTS.CHAT_CREATE_ROOM;

      let payload: any = {
        report: { id: reportId },
      };

      if (senderId) {
        payload = {
          report: { id: reportId },
          sender: senderId,
        };
      }

      const response = await api.post(url, payload);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async getRoom(roomId: string) {
    try {
      let url: string = CHAT_ENDPOINTS.CHAT_GET_ROOM + roomId + "/getDetails";

      const response = await api.get(url);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async listRooms(userId: string) {
    try {
      let url: string = CHAT_ENDPOINTS.CHAT_GET_ROOMS;

      const response = await api.get(url + `/${userId}`);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async createMessage(payload: {
    roomId: string;
    senderId: string;
    message: string;
  }) {
    try {
      let url: string = CHAT_ENDPOINTS.CHAT_CREATE_MESSAGE;

      const response = await api.post(url, payload);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async getMessages(roomId: string) {
    try {
      let url: string = CHAT_ENDPOINTS.CHAT_GET_MESSAGE;
      url += `/${roomId}`;
      url += "/listMessages";

      const response = await api.get(url);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async readMessages(payload: { roomId: string; senderId: string }) {
    try {
      let url: string = CHAT_ENDPOINTS.CHAT_READ_MESSAGES;
      const response = await api.put(url, payload);
      return response;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }
}

export default new ChatRequests();
