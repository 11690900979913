import "@mui/lab/themeAugmentation";

import ProgramInterface from "../../interfaces/program";
import programRequests from "../../utils/requests/programRequests";
import CircleIcon from "../circleIcon";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";

import React, { useEffect, useState } from "react";
import { userRoles } from "../../enums/roles";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { BsFillArrowUpRightSquareFill } from "react-icons/bs";
import {
  Box,
  ButtonGroup,
  ChakraProvider,
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
  IconButtonProps,
  Input,
  NumberInput,
  Tooltip,
  useColorModeValue,
  useEditableControls,
  useToast,
} from "@chakra-ui/react";
import { routes } from "../../enums/routes";

import {
  createTheme,
  Table,
  TableContainer,
  TableHead,
  ThemeProvider,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Pagination,
} from "@mui/material";

interface Props {
  lastDays: string;
  especificCompanyId?: string;
}

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: "#319795",
    },
  },

  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          padding: "1.3rem",
          marginTop: "1.5rem",
          marginBottom: "1rem",
          borderRadius: "16px",
          border: "1px solid #E2E8F0",
          minWidth: 650,
        },
      },
    },
    MuiTableCell: { styleOverrides: { root: { padding: "1rem" } } },
  },
});

const MyProgram: React.FC<Props> = ({ lastDays, especificCompanyId }) => {
  const navigate = useNavigate();

  const toast = useToast();
  const userRole = sessionControllerHandler.getUserRole();

  const [page, setPage] = useState(1);
  const [programs, setPrograms] = useState<ProgramInterface[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getPrograms(page);
  }, [lastDays]);

  const handleChangePage = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    getPrograms(value);
  };

  const getPrograms = async (currentPage: number) => {
    let myId = sessionControllerHandler.getUserID();
    let userEmail: undefined | string = undefined;

    if (especificCompanyId) {
      myId = especificCompanyId;
    }

    if (userRole == userRoles.GUEST) {
      userEmail = sessionControllerHandler.getEmail();
    }

    const response = await programRequests.getAll(
      lastDays,
      currentPage,
      myId ?? undefined,
      userEmail
    );

    if (!response.programs) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setPrograms([]);
      setPage(1);
      setTotalPages(1);
      return;
    }

    setPrograms(response.programs);
    setTotalRows(response.total);
    setTotalPages(response.totalPages);
    return;
  };

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="end" size="sm" spacing={2} mt={2}>
        <IconButton
          icon={<CheckIcon />}
          {...(getSubmitButtonProps() as IconButtonProps)}
        />
        <IconButton
          icon={<CloseIcon boxSize={3} />}
          {...(getCancelButtonProps() as IconButtonProps)}
        />
      </ButtonGroup>
    ) : null;
  }

  const updateLimitReport = async (newLimit: string, id: string) => {
    const newLimitNumber = parseInt(newLimit);

    if (!newLimitNumber) return;

    if (!id) {
      toast({
        title: "Programa não identificado.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    const updateStatus = await programRequests.updateLimitReport(
      id,
      newLimitNumber
    );

    if (!updateStatus.id) {
      toast({
        title: updateStatus,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    toast({
      title: "Alterações salvas.",
      description: "As informações foram atualizadas!",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: "bold", fontColor: "#4A5568" }}
                align="center"
              >
                STATUS
              </TableCell>

              <TableCell sx={{ fontWeight: "bold", fontColor: "#4A5568" }}>
                PROGRAMA
              </TableCell>

              {userRole == userRoles.ADMIN && (
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontColor: "#4A5568" }}
                >
                  MÁXIMO DE RELATÓRIO
                </TableCell>
              )}

              <TableCell
                align="center"
                sx={{ fontWeight: "bold", fontColor: "#4A5568" }}
              >
                CRÍTICO
              </TableCell>

              <TableCell
                align="center"
                sx={{ fontWeight: "bold", fontColor: "#4A5568" }}
              >
                ALTO
              </TableCell>

              <TableCell
                align="center"
                sx={{ fontWeight: "bold", fontColor: "#4A5568" }}
              >
                MÉDIO
              </TableCell>

              <TableCell
                align="center"
                sx={{ fontWeight: "bold", fontColor: "#4A5568" }}
              >
                BAIXO
              </TableCell>

              <TableCell
                sx={{ fontWeight: "bold", fontColor: "#4A5568" }}
                align="center"
              ></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {programs.length !== 0 ? (
              programs.map((program) => (
                <TableRow
                  key={program.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    <CircleIcon color={program.isActive ? "green" : "red"} />
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "15rem",
                    }}
                    component="th"
                    scope="row"
                  >
                    {program.title}
                  </TableCell>

                  {userRole == userRoles.ADMIN && (
                    <TableCell
                      sx={{
                        fontColor: "#2D3748",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      align="center"
                      component="th"
                      scope="row"
                    >
                      <ChakraProvider>
                        <Editable
                          defaultValue={`${program.limitReport}`}
                          isPreviewFocusable={true}
                          selectAllOnFocus={false}
                          display={"flex"}
                          justifyContent={"center"}
                          gap="0.3rem"
                          maxW={"15rem"}
                          onSubmit={(value) =>
                            updateLimitReport(value, program.id)
                          }
                        >
                          <EditablePreview
                            py={2}
                            px={4}
                            _hover={{
                              background: "gray.100",
                            }}
                          />

                          <Input py={2} px={4} as={EditableInput} />
                          <EditableControls />
                        </Editable>
                      </ChakraProvider>
                    </TableCell>
                  )}

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    R$ {program.criticalValue.toFixed(2)}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    R$ {program.hightValue.toFixed(2)}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    R$ {program.mediumValue.toFixed(2)}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    R$ {program.lowValue.toFixed(2)}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    component="th"
                    scope="row"
                  >
                    <Box
                      as={BsFillArrowUpRightSquareFill}
                      size="1.2rem"
                      color="teal.500"
                      cursor="pointer"
                      onClick={() =>
                        navigate(routes.PROGRAM_DETAILS + program.id)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    fontColor: "#2D3748",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "15rem",
                  }}
                  component="th"
                  scope="row"
                  colSpan={userRole == userRoles.ADMIN ? 8 : 7}
                  align="center"
                >
                  Nenhum programa encontrado
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <Stack direction="row-reverse">
          <Pagination
            page={page}
            onChange={handleChangePage}
            count={totalPages}
            color="primary"
            sx={{ fontColor: "#319795" }}
          />
        </Stack>
      </TableContainer>
    </ThemeProvider>
  );
};

export default MyProgram;
