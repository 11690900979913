import api from "../../services/api";
import { REPORT_ENDPOINTS } from "../../enums/apiEndpoints";
import { reportStatus } from "../../enums/status";
import ReportResearcherAnalyticsInterface from "../../interfaces/reportResearcherAnalyticsInterface";
import ReportCompanyAnalyticsInterface from "../../interfaces/reportCompanyAnalyticsInterface";

class ReportAnalyticsRequests {
  public async getCompany(
    lastDays: string,
    status: reportStatus | "all",
    program: string,
    companyId?: string,
    researcherId?: string
  ) {
    try {
      let url: string = REPORT_ENDPOINTS.COMPANY_ANALYTICS + "?";

      if (lastDays !== "all") {
        url += `&lastDays=${lastDays}`;
      }

      if (status !== "all") {
        url += `&status=${status}`;
      }
      if (program !== "all") {
        url += `&program=${program}`;
      }

      if (companyId) {
        url += `&companyId=${companyId}`;
      }

      if (researcherId) {
        url += `&researcherId=${researcherId}`;
      }

      const response = await api.get(url);
      return response.data as ReportCompanyAnalyticsInterface;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async getResearcher(
    periodFilter: string,
    status: reportStatus | "all",
    program: string,
    companyId?: string,
    researcherId?: string
  ) {
    try {
      let url: string = REPORT_ENDPOINTS.RESEACHER_ANALYTICS + "?";

      url += `&periodFilter=${periodFilter}`;

      if (status !== "all") {
        url += `&status=${status}`;
      }

      if (program !== "all") {
        url += `&program=${program}`;
      }

      if (companyId) {
        url += `&companyId=${companyId}`;
      }

      if (researcherId) {
        url += `&researcherId=${researcherId}`;
      }

      const response = await api.get(url);
      return response.data as ReportResearcherAnalyticsInterface;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }
}

export default new ReportAnalyticsRequests();
