import authHandler from "../../utils/handlers/authHandler";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";

import MyProgram from "../../components/myProgram";
import AllProgram from "../../components/allPrograms";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../enums/roles";
import { routes } from "../../enums/routes";
import {
  Flex,
  Tab,
  TabList,
  Tabs,
  TabPanels,
  TabPanel,
  Spacer,
  Select,
  Button,
} from "@chakra-ui/react";

export default function Programs() {
  const navigate = useNavigate();
  const userRole = sessionControllerHandler.getUserRole();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [lastDays, setLastDays] = useState("30");

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <Tabs position="relative" variant="unstyled">
        <TabList>
          <Flex gap="1.5rem" alignItems="center">
            {userRole === userRoles.COMPANY && (
              <Tab
                m="0"
                p="0"
                color="gray.400"
                fontSize="2xl"
                as="b"
                _selected={{ color: "gray.700" }}
                cursor="pointer"
              >
                Meu programa
              </Tab>
            )}

            {userRole === userRoles.GUEST && (
              <Tab
                m="0"
                p="0"
                color="gray.400"
                fontSize="2xl"
                as="b"
                _selected={{ color: "gray.700" }}
                cursor="pointer"
              >
                Meu programa
              </Tab>
            )}

            {userRole === userRoles.RESEARCHER && (
              <Tab
                m="0"
                p="0"
                color="gray.400"
                fontSize="2xl"
                as="b"
                _selected={{ color: "gray.700" }}
                cursor="pointer"
              >
                Programas
              </Tab>
            )}
          </Flex>

          <Spacer />

          <Flex gap="1.5rem" alignItems="center">
            {userRole !== userRoles.RESEARCHER && (
              <Select
                w="15rem"
                size="md"
                onChange={(event) => setLastDays(event.target.value)}
                defaultValue="30"
                focusBorderColor="teal.500"
              >
                <option value="30">Últimos 30 dias</option>

                <option value="60">Últimos 60 dias</option>

                <option value="90">Últimos 90 dias</option>

                <option value="all">Todos</option>
              </Select>
            )}

            {userRole === userRoles.COMPANY && (
              <Button
                type="submit"
                size="md"
                variant="solid"
                colorScheme="teal"
                w="15rem"
                onClick={() => navigate(routes.CREATE_PROGRAM)}
              >
                Criar novo programa
              </Button>
            )}

            {userRole === userRoles.GUEST && (
              <Button
                type="submit"
                size="md"
                variant="solid"
                colorScheme="teal"
                w="15rem"
                onClick={() => navigate(routes.CREATE_PROGRAM)}
              >
                Criar novo programa
              </Button>
            )}
          </Flex>
        </TabList>

        <TabPanels>
          {userRole === userRoles.COMPANY && (
            <TabPanel m="0" p="0">
              <TabPanel m="0" p="0">
                <MyProgram lastDays={lastDays} />
              </TabPanel>
            </TabPanel>
          )}

          {userRole === userRoles.GUEST && (
            <TabPanel m="0" p="0">
              <TabPanel m="0" p="0">
                <MyProgram lastDays={lastDays} />
              </TabPanel>
            </TabPanel>
          )}

          {userRole === userRoles.RESEARCHER && (
            <TabPanel m="0" p="0">
              <AllProgram lastDays={"all"} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  );
}
