import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import authHandler from "../../utils/handlers/authHandler";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import userRequests from "../../utils/requests/userRequests";
import profilePicRequest from "../../utils/requests/profilePicRequest";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../enums/roles";
import { PROFILE_PIC_ENDPOINTS } from "../../enums/apiEndpoints";
import { routes } from "../../enums/routes";
import {
  BsFillEyeFill,
  BsFillEyeSlashFill,
  BsFillLockFill,
} from "react-icons/bs";
import { IoMdTrash } from "react-icons/io";
import {
  Flex,
  Text,
  Box,
  Grid,
  GridItem,
  Input,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  Avatar,
  Stack,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  FormErrorMessage,
  Select,
  ModalCloseButton,
  HStack,
} from "@chakra-ui/react";
import filesRequests from "../../utils/requests/files.requests";
import { coin as coinInterface } from "../../enums/coin";

export default function MyAccount() {
  const navigate = useNavigate();
  const toast = useToast();

  const profilePicId = sessionControllerHandler.getProfilePicId();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const changeBankDataModal = useDisclosure();

  const profilePicSrc = profilePicId
    ? process.env.REACT_APP_BASE_URL +
      PROFILE_PIC_ENDPOINTS.PROFILE_PIC_GET +
      "/" +
      profilePicId
    : "";

  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const [profilePic, setProfilePic] = useState("");
  const [pixType, setPixType] = useState("");
  const [pixValue, setPixValue] = useState("");
  const [pixTypeDefault, setPixTypeDefault] = useState("");
  const [pixValueDefaul, setPixValueDefault] = useState("");
  const [isPixValueInvalid, setIsPixValueInvalid] = useState(true);
  const [updateBankDataLoading, setUpdateBankDataLoading] = useState(false);
  const [coin, setCoin] = useState<coinInterface | null>(null);

  const [fullname, setFullname] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [role, setRole] = useState("");
  const [cpfOrCnpj, setCpfOrCnpj] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [email, setEmail] = useState("");
  const [useUploadedPic, setUseUploadedPic] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [radioValue, setRadioValue] = React.useState(
    useUploadedPic ? "uploaded" : "default"
  );

  const [isCellphoneInvalid, setIsCellphoneInvalid] = useState(true);
  const [isCpfOrCnpjInvalid, setIsCpfOrCnpjInvalid] = useState(true);

  const [hasErrorPassword, setHasErrorPassword] = useState(false);
  const [hasErrorNewPassword, setHasErrorNewPassword] = useState(false);

  const [showPasswordValue, setShowPasswordValue] = useState(false);
  const [showNewPasswordValue, setShowNewPasswordValue] = useState(false);
  const [showConfirmPasswordValue, setShowConfirmPasswordValue] =
    useState(false);

  const [passwordValue, setPasswordValue] = useState("");
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");

  const [updatePasswordIsLoading, setUpdatePasswordIsLoading] = useState(false);

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
    getUserDetails();
    getProfilePic();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  const getProfilePic = async () => {
    const response = await filesRequests.get(profilePicSrc);

    if (
      response !== "Ocorreu um erro no servidor, tente novamente mais tarde"
    ) {
      setProfilePic(response);
    }
  };

  const getUserDetails = async () => {
    const id = sessionControllerHandler.getUserID();

    if (id) {
      const response = await userRequests.getUser(id);

      if (response.id) {
        setName(response.name);
        setCellphone(response.cellphone);
        setCpfOrCnpj(response.cnpjOrCpf);
        setEmail(response.email);
        setRole(response.role);
        setSurname(response.surname);
        setUseUploadedPic(response.useUploadedPic);
        setFullname(`${response.name} ${response.surname}`);
        setRadioValue(response.useUploadedPic ? "uploaded" : "default");
        setCoin(response.coin ?? null);

        if (response.bankData) {
          setPixType(response.bankData.pixType);
          setPixValue(response.bankData.pixValue);

          setPixTypeDefault(response.bankData.pixType);
          setPixValueDefault(response.bankData.pixValue);
        }

        if (response.profilePic) {
          sessionControllerHandler.setProfilePicId(response.profilePic.id);
        } else {
          sessionControllerHandler.setProfilePicId("");
        }
      } else {
        toast({
          title: response,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    }
  };

  const userUpdate = async () => {
    const validationCellphone = checkCellphone();
    setIsCellphoneInvalid(validationCellphone);

    const validationCpfOrCnpj = checkCpfOrCnpj();
    setIsCpfOrCnpjInvalid(validationCpfOrCnpj);

    if (!validationCellphone) return;
    if (!validationCpfOrCnpj) return;

    setUpdateLoading(true);
    const id = sessionControllerHandler.getUserID();

    if (id) {
      let coinToUpdate: any = coin;
      if (coinToUpdate == "") coinToUpdate = null;

      const response = await userRequests.update({
        id,
        name,
        surname,
        email,
        cnpjOrCpf: cpfOrCnpj,
        cellphone,
        useUploadedPic: radioValue === "uploaded" ? true : false,
        coin: coinToUpdate ?? null,
      });

      if (response.id) {
        sessionControllerHandler.setUserName(name);
        sessionControllerHandler.setUserSurname(surname);
        sessionControllerHandler.setUseUploadedPic(
          radioValue === "uploaded" ? true : false
        );

        toast({
          title: "Dados da conta atualizados com sucesso.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        setUpdateLoading(false);
        document.location.reload();
      } else {
        toast({
          title: response,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        setUpdateLoading(false);
      }
    }
  };

  const uploadProfilePic = async () => {
    const id = sessionControllerHandler.getUserID();
    if (!id) return;

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", ".png, .jpg, .jpeg");

    input.addEventListener("change", async () => {
      setUploadLoading(true);

      if (input.files && input.files?.length !== 0) {
        const file = input.files[0];

        const response = await profilePicRequest.upload(id, file);

        toast({
          title: response,
          status:
            response === "Foto de perfil atualziada" ? "success" : "error",
          duration: 4000,
          isClosable: true,
        });
      }

      document.location.reload();
      setUploadLoading(false);
      input.remove();
    });

    input.click();
  };

  const removeProfilePic = async () => {
    if (!profilePicId) return;

    setRemoveLoading(true);

    const response = await profilePicRequest.delete(profilePicId);

    toast({
      title: response,
      status: response === "Foto de perfil removida" ? "success" : "error",
      duration: 4000,
      isClosable: true,
    });

    setRemoveLoading(false);
    document.location.reload();
  };

  const normalizeRole = (role: string) => {
    if (role === userRoles.ADMIN) return "Administrador";
    if (role === userRoles.COMPANY) return "Empresa";
    if (role === userRoles.GUEST) return "Convidado";
    if (role === userRoles.RESEARCHER) return "Pesquisador";
  };

  const updatePassword = async (event: any) => {
    event.preventDefault();

    const id = sessionControllerHandler.getUserID();
    if (!id) return;

    setUpdatePasswordIsLoading(true);
    setHasErrorPassword(false);

    if (newPasswordValue !== confirmPasswordValue) {
      setHasErrorNewPassword(true);
      setUpdatePasswordIsLoading(false);
      return;
    }

    setHasErrorNewPassword(false);

    const response = await userRequests.updatePassword({
      id,
      password: passwordValue,
      newPassword: newPasswordValue,
    });

    if (!response.id) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setHasErrorPassword(true);
      setUpdatePasswordIsLoading(false);
      return;
    }

    setHasErrorPassword(false);

    toast({
      title: "Senha alterada com sucesso!",
      description: "Faça login novamente para continuar.",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
    setUpdatePasswordIsLoading(false);

    sessionControllerHandler.clearRecords();
    setIsAuthenticated(false);
  };

  const checkCellphone = () => {
    if (cellphone.replaceAll("_", "").length < 19) {
      return false;
    }

    return true;
  };

  const checkCpfOrCnpj = () => {
    if (role === "company" && cpfOrCnpj.replaceAll("_", "").length < 18) {
      return false;
    }

    if (cpfOrCnpj.replaceAll("_", "").length < 14) {
      return false;
    }

    return true;
  };

  const inputTypePix = (typePix: string) => {
    if (typePix === "email") return "email";

    return "text";
  };

  const maskPix = (typePix: string) => {
    if (typePix === "cpf") return "999.999.999-99";

    if (typePix === "cnpj") return "99.999.999/9999-99";

    if (typePix === "phone") return "+99 (99) 99999-9999";

    return "";
  };

  const checkPixValue = () => {
    if (pixType === "cnpj" && pixValue.replaceAll("_", "").length < 18) {
      return false;
    }

    if (pixType === "cpf" && pixValue.replaceAll("_", "").length < 14) {
      return false;
    }

    if (pixType === "phone" && pixValue.replaceAll("_", "").length < 19) {
      return false;
    }

    return true;
  };

  const dataBankUpdate = async (event: any) => {
    event.preventDefault();

    const validationPixValue = checkPixValue();
    setIsPixValueInvalid(validationPixValue);

    if (!validationPixValue) return;

    const id = sessionControllerHandler.getUserID();
    if (!id) return;

    setUpdateBankDataLoading(true);

    const response = await userRequests.updateBankData(id, {
      pixType,
      pixValue,
    });

    setUpdateBankDataLoading(false);

    if (response == "Ocorreu um erro no servidor, tente novamente mais tarde") {
      toast({
        title: "Não foi possível alterar dados bancários!",
        description: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    closeBankDataModal();
    document.location.reload();

    toast({
      title: "Dados bancários atualizados!",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
  };

  const closeBankDataModal = () => {
    setPixType(pixTypeDefault);
    setPixValue(pixValueDefaul);

    changeBankDataModal.onClose();
  };

  const getTemplateArea = () => {
    let template = `
      "image name"
      "image surname"
      "image role"
      "uploadPic cpfOrCnpj"
      "removePic cellphone"
      "changePassword mail"
      "changeBankData coin"
    `;

    if (role != userRoles.COMPANY && role != userRoles.GUEST) {
      template = `
        "image name"
        "image surname"
        "uploadPic role"
        "removePic cpfOrCnpj"
        "changePassword cellphone"
        "changeBankData mail"
      `;
    }

    return template;
  };

  return (
    <>
      <>
        <Text color="gray.700" fontSize="2xl" as="b">
          Minha conta
        </Text>

        <Box
          p="1.5rem"
          marginTop="1.5rem"
          gap="1.5rem"
          bg="whiteAlpha.900"
          borderRadius="1rem"
        >
          <form>
            <Grid
              templateAreas={getTemplateArea()}
              gridTemplateColumns={"1fr 1fr"}
              gap="1.5rem"
              p="1.5rem"
            >
              <GridItem pl="2" area={"image"}>
                <RadioGroup
                  defaultValue={useUploadedPic ? "uploaded" : "default"}
                  onChange={setRadioValue}
                  value={radioValue}
                >
                  <Stack spacing="2rem" direction="row" justifyContent="center">
                    <Radio
                      colorScheme="teal"
                      value="uploaded"
                      flexDirection="column-reverse"
                      gap="1rem"
                    >
                      <Avatar size="2xl" src={profilePic} />
                    </Radio>

                    <Radio
                      colorScheme="teal"
                      value="default"
                      flexDirection="column-reverse"
                      gap="1rem"
                    >
                      <Avatar size="2xl" name={fullname} />
                    </Radio>
                  </Stack>
                </RadioGroup>
              </GridItem>

              <GridItem pl="2" area={"uploadPic"}>
                <Button
                  onClick={() => uploadProfilePic()}
                  size="lg"
                  variant="solid"
                  colorScheme="teal"
                  w="100%"
                  isLoading={uploadLoading}
                >
                  Carregar foto
                </Button>
              </GridItem>

              <GridItem pl="2" area={"removePic"}>
                <Button
                  size="lg"
                  variant="outline"
                  colorScheme="red"
                  w="100%"
                  onClick={() => removeProfilePic()}
                  isLoading={removeLoading}
                >
                  Excluir foto
                </Button>
              </GridItem>

              <GridItem pl="2" area={"changePassword"}>
                <Button
                  size="lg"
                  variant="solid"
                  colorScheme="teal"
                  w="100%"
                  onClick={onOpen}
                >
                  Alterar senha
                </Button>
              </GridItem>

              <GridItem pl="2" area={"changeBankData"}>
                <Button
                  size="lg"
                  variant="solid"
                  colorScheme="teal"
                  w="100%"
                  onClick={changeBankDataModal.onOpen}
                >
                  Alterar dados bancários
                </Button>
              </GridItem>

              <GridItem pl="2" area={"name"}>
                <FormControl isRequired>
                  <Input
                    size="lg"
                    focusBorderColor="teal.500"
                    placeholder="Nome"
                    color="gray.400"
                    defaultValue={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </FormControl>
              </GridItem>

              <GridItem pl="2" area={"surname"}>
                <FormControl isRequired>
                  <Input
                    size="lg"
                    focusBorderColor="teal.500"
                    placeholder="Sobrenome"
                    color="gray.400"
                    defaultValue={surname}
                    onChange={(event) => setSurname(event.target.value)}
                  />
                </FormControl>
              </GridItem>

              <GridItem pl="2" area={"role"}>
                <FormControl isRequired>
                  <Input
                    disabled
                    focusBorderColor="teal.500"
                    size="lg"
                    placeholder="Tipo de usuário"
                    color="gray.400"
                    borderColor="gray.400"
                    background="gray.300"
                    defaultValue={normalizeRole(role)}
                  />
                </FormControl>
              </GridItem>

              <GridItem pl="2" area={"cpfOrCnpj"}>
                <FormControl isRequired isInvalid={!isCpfOrCnpjInvalid}>
                  <Input
                    size="lg"
                    focusBorderColor="teal.500"
                    placeholder="CPF/CNPJ"
                    color="gray.400"
                    as={InputMask}
                    mask={
                      role === userRoles.COMPANY || role === userRoles.GUEST
                        ? "99.999.999/9999-99"
                        : "999.999.999-99"
                    }
                    value={cpfOrCnpj}
                    onChange={(event) => setCpfOrCnpj(event.target.value)}
                  />

                  {!isCpfOrCnpjInvalid && (
                    <Flex flexDirection="column" alignItems="center">
                      <FormErrorMessage textDecoration="underline">
                        O número informado é inválido
                      </FormErrorMessage>
                    </Flex>
                  )}
                </FormControl>
              </GridItem>

              <GridItem pl="2" area={"cellphone"}>
                <FormControl isRequired isInvalid={!isCellphoneInvalid}>
                  <Input
                    size="lg"
                    focusBorderColor="teal.500"
                    placeholder="Celular"
                    as={InputMask}
                    color="gray.400"
                    mask="+99 (99) 99999-9999"
                    value={cellphone}
                    onChange={(event) => setCellphone(event.target.value)}
                  />

                  {!isCellphoneInvalid && (
                    <Flex flexDirection="column" alignItems="center">
                      <FormErrorMessage textDecoration="underline">
                        O número informado é inválido
                      </FormErrorMessage>
                    </Flex>
                  )}
                </FormControl>
              </GridItem>

              <GridItem pl="2" area={"mail"}>
                <FormControl isRequired>
                  <Input
                    size="lg"
                    focusBorderColor="teal.500"
                    placeholder="E-mail"
                    type="email"
                    color="gray.400"
                    defaultValue={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormControl>
              </GridItem>

              {role == userRoles.COMPANY && (
                <GridItem pl="2" area={"coin"}>
                  <FormControl mb="1.5rem" isRequired>
                    <InputGroup
                      borderColor="gray.400"
                      size="lg"
                      variant="outline"
                      color="gray.400"
                      w="100%"
                    >
                      <Select
                        placeholder="Moeda"
                        focusBorderColor="teal.500"
                        color="gray.400"
                        onChange={(event) => {
                          setCoin(event.currentTarget.value as coinInterface);
                        }}
                        value={coin ?? undefined}
                      >
                        <option value={coinInterface.BRL}>BRL</option>
                        <option value={coinInterface.EUR}>EUR</option>
                        <option value={coinInterface.USD}>USD</option>
                      </Select>
                    </InputGroup>
                  </FormControl>
                </GridItem>
              )}

              {role == userRoles.GUEST && (
                <GridItem pl="2" area={"coin"}>
                  <FormControl mb="1.5rem" isRequired>
                    <InputGroup
                      borderColor="gray.400"
                      size="lg"
                      variant="outline"
                      color="gray.400"
                      w="100%"
                    >
                      <Select
                        placeholder="Moeda"
                        focusBorderColor="teal.500"
                        color="gray.400"
                        onChange={(event) => {
                          setCoin(event.currentTarget.value as coinInterface);
                        }}
                        value={coin ?? undefined}
                      >
                        <option value={coinInterface.BRL}>BRL</option>
                        <option value={coinInterface.EUR}>EUR</option>
                        <option value={coinInterface.USD}>USD</option>
                      </Select>
                    </InputGroup>
                  </FormControl>
                </GridItem>
              )}
            </Grid>

            <Flex gap="1.5rem" marginTop="1.5rem">
              <Button
                onClick={() => navigate(routes.HOMEPAGE)}
                size="lg"
                variant="outline"
                colorScheme="teal"
                w="100%"
              >
                Cancelar
              </Button>

              <Button
                isLoading={updateLoading}
                size="lg"
                variant="solid"
                colorScheme="teal"
                w="100%"
                onClick={() => userUpdate()}
              >
                Salvar
              </Button>
            </Flex>
          </form>
        </Box>
      </>

      <Modal isOpen={isOpen} onClose={onClose}>
        <form onSubmit={(event) => updatePassword(event)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Text color="gray.700" fontSize="2xl" as="b">
                Alterar senha
              </Text>
            </ModalHeader>

            <ModalBody>
              <FormControl mb="1.5rem" isRequired isInvalid={hasErrorPassword}>
                <InputGroup
                  borderColor="gray.400"
                  size="lg"
                  variant="outline"
                  w="100%"
                >
                  <InputLeftElement
                    children={
                      <Box as={BsFillLockFill} size="1.5rem" color="gray.400" />
                    }
                  />

                  <Input
                    type={showPasswordValue ? "text" : "password"}
                    placeholder="Senha atual"
                    focusBorderColor="teal.500"
                    onChange={(event) =>
                      setPasswordValue(event.currentTarget.value)
                    }
                  />

                  <InputRightElement>
                    <Button
                      borderLeft="1px"
                      borderColor="gray.400"
                      w="95%"
                      h="95%"
                      roundedLeft="0"
                      onClick={() => setShowPasswordValue(!showPasswordValue)}
                    >
                      {showPasswordValue ? (
                        <Box
                          as={BsFillEyeSlashFill}
                          size="1.5rem"
                          color="gray.700"
                        />
                      ) : (
                        <Box
                          as={BsFillEyeFill}
                          size="1.5rem"
                          color="gray.700"
                        />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                {hasErrorPassword && (
                  <Flex flexDirection="column" alignItems="center">
                    <FormErrorMessage textDecoration="underline">
                      Senha está incorreta.
                    </FormErrorMessage>
                  </Flex>
                )}
              </FormControl>

              <FormControl mb="1.5rem" isRequired>
                <InputGroup
                  borderColor="gray.400"
                  size="lg"
                  variant="outline"
                  w="100%"
                >
                  <InputLeftElement
                    children={
                      <Box as={BsFillLockFill} size="1.5rem" color="gray.400" />
                    }
                  />

                  <Input
                    type={showNewPasswordValue ? "text" : "password"}
                    placeholder="Nova senha"
                    focusBorderColor="teal.500"
                    onChange={(event) =>
                      setNewPasswordValue(event.currentTarget.value)
                    }
                  />

                  <InputRightElement>
                    <Button
                      borderLeft="1px"
                      borderColor="gray.400"
                      w="95%"
                      h="95%"
                      roundedLeft="0"
                      onClick={() =>
                        setShowNewPasswordValue(!showNewPasswordValue)
                      }
                    >
                      {showNewPasswordValue ? (
                        <Box
                          as={BsFillEyeSlashFill}
                          size="1.5rem"
                          color="gray.700"
                        />
                      ) : (
                        <Box
                          as={BsFillEyeFill}
                          size="1.5rem"
                          color="gray.700"
                        />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                {hasErrorPassword && (
                  <Flex flexDirection="column" alignItems="center">
                    <FormErrorMessage textDecoration="underline">
                      Senha está incorreta.
                    </FormErrorMessage>
                  </Flex>
                )}
              </FormControl>

              <FormControl isRequired isInvalid={hasErrorNewPassword}>
                <InputGroup
                  borderColor="gray.400"
                  size="lg"
                  variant="outline"
                  w="100%"
                >
                  <InputLeftElement
                    children={
                      <Box as={BsFillLockFill} size="1.5rem" color="gray.400" />
                    }
                  />

                  <Input
                    type={showConfirmPasswordValue ? "text" : "password"}
                    placeholder="Confirmar senha"
                    focusBorderColor="teal.500"
                    onChange={(event) =>
                      setConfirmPasswordValue(event.currentTarget.value)
                    }
                  />

                  <InputRightElement>
                    <Button
                      borderLeft="1px"
                      borderColor="gray.400"
                      w="95%"
                      h="95%"
                      roundedLeft="0"
                      onClick={() =>
                        setShowConfirmPasswordValue(!showConfirmPasswordValue)
                      }
                    >
                      {showConfirmPasswordValue ? (
                        <Box
                          as={BsFillEyeSlashFill}
                          size="1.5rem"
                          color="gray.700"
                        />
                      ) : (
                        <Box
                          as={BsFillEyeFill}
                          size="1.5rem"
                          color="gray.700"
                        />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                {hasErrorNewPassword && (
                  <Flex flexDirection="column" alignItems="center">
                    <FormErrorMessage textDecoration="underline">
                      As senhas não coincidem.
                    </FormErrorMessage>

                    <FormErrorMessage textDecoration="underline">
                      Verifique os campos.
                    </FormErrorMessage>
                  </Flex>
                )}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                mr="1rem"
                variant="outline"
                colorScheme="teal"
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                isLoading={updatePasswordIsLoading}
                colorScheme="teal"
                type="submit"
              >
                Alterar senha
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>

      <Modal isOpen={changeBankDataModal.isOpen} onClose={closeBankDataModal}>
        <form onSubmit={(event) => dataBankUpdate(event)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Text color="gray.700" fontSize="2xl" as="b">
                Alterar dados bancários
              </Text>
            </ModalHeader>

            <ModalBody>
              <FormControl mb="1.5rem" isRequired>
                <InputGroup
                  borderColor="gray.400"
                  size="lg"
                  variant="outline"
                  w="100%"
                >
                  <Select
                    placeholder="Tipo de chave PIX"
                    focusBorderColor="teal.500"
                    onChange={(event) => {
                      setPixValue("");
                      setPixType(event.currentTarget.value);
                    }}
                    value={pixType}
                  >
                    <option value="cpf">CPF</option>
                    <option value="cnpj">CNPJ</option>
                    <option value="email">E-mail</option>
                    <option value="phone">Telefone</option>
                    <option value="random">Aleatória</option>
                  </Select>
                </InputGroup>
              </FormControl>

              <FormControl
                mb="1.5rem"
                isRequired
                isInvalid={!isPixValueInvalid}
              >
                <InputGroup
                  borderColor="gray.400"
                  size="lg"
                  variant="outline"
                  w="100%"
                >
                  <Input
                    type={inputTypePix(pixType)}
                    placeholder="Valor da chave PIX"
                    focusBorderColor="teal.500"
                    as={InputMask}
                    mask={maskPix(pixType)}
                    value={pixValue}
                    onChange={(event) => setPixValue(event.currentTarget.value)}
                  />
                </InputGroup>

                {!isPixValueInvalid && (
                  <Flex flexDirection="column" alignItems="center">
                    <FormErrorMessage textDecoration="underline">
                      O valor do PIX informado é inválido
                    </FormErrorMessage>
                  </Flex>
                )}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                mr="1rem"
                variant="outline"
                colorScheme="teal"
                onClick={closeBankDataModal}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="teal"
                type="submit"
                isLoading={updateBankDataLoading}
              >
                Salvar dados bancários
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
