import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import {
  BsArrowLeft,
  BsFillLockFill,
  BsFillEyeSlashFill,
  BsFillEyeFill,
} from "react-icons/bs";
import {
  Flex,
  Text,
  Stack,
  FormControl,
  InputGroup,
  Input,
  Select,
  Button,
  Image,
  Box,
  Link,
  InputLeftElement,
  InputRightElement,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import userRequests from "../../utils/requests/userRequests";
import authHandler from "../../utils/handlers/authHandler";
import { routes } from "../../enums/routes";

enum StepStatus {
  NotCompleted,
  InProgress,
  Completed,
}

export default function RegisterModal() {
  const navigate = useNavigate();
  const toast = useToast();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [firstStepStatus, setFirstStepStatus] = useState<StepStatus>(
    StepStatus.InProgress
  );

  const [secondStepStatus, setSecondStepStatus] = useState<StepStatus>(
    StepStatus.NotCompleted
  );

  const [showPasswordValue, setShowPasswordValue] = useState(false);
  const [showConfirmPasswordValue, setShowConfirmPasswordValue] =
    useState(false);

  const [samePassword, setSamePassword] = useState(true);
  const [isCellphoneInvalid, setIsCellphoneInvalid] = useState(true);
  const [isCpfOrCnpjInvalid, setIsCpfOrCnpjInvalid] = useState(true);

  const [name, setName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [surname, setSurname] = useState("");
  const [cpfOrCnpj, setCpfOrCnpj] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isCreateUserLoading, setIsCreateUserLoading] = useState(false);

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.HOMEPAGE);
    }
  }, [isAuthenticated, navigate]);

  const finishFirstStep = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validationCellphone = checkCellphone();
    setIsCellphoneInvalid(validationCellphone);

    const validationCpfOrCnpj = checkCpfOrCnpj();
    setIsCpfOrCnpjInvalid(validationCpfOrCnpj);

    if (!validationCellphone) return;
    if (!validationCpfOrCnpj) return;

    setFirstStepStatus(StepStatus.Completed);
    setSecondStepStatus(StepStatus.InProgress);
  };

  const finishSecondStep = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validationPasswords = checkPasswords();
    setSamePassword(validationPasswords);
    if (!validationPasswords) return;

    createUser();
  };

  const handleShowPasswordValue = () =>
    setShowPasswordValue(!showPasswordValue);

  const handleShowConfirmPasswordValue = () =>
    setShowConfirmPasswordValue(!showConfirmPasswordValue);

  const checkPasswords = () => {
    return password === confirmPassword;
  };

  const checkCellphone = () => {
    if (cellphone.length < 19) {
      return false;
    }

    return true;
  };

  const checkCpfOrCnpj = () => {
    if (userRole === "company" && cpfOrCnpj.length < 18) {
      return false;
    }

    if (cpfOrCnpj.length < 14) {
      return false;
    }

    return true;
  };

  const createUser = async () => {
    setIsCreateUserLoading(true);

    const profilePicSrc = "";
    const response = await userRequests.create({
      name,
      surname,
      profilePicSrc,
      email,
      password,
      cnpjOrCpf: cpfOrCnpj,
      cellphone,
      role: userRole,
    });

    if (
      response === "Ocorreu um erro no servidor, tente novamente mais tarde"
    ) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setIsCreateUserLoading(false);
      return;
    }

    if (response.userExists) {
      toast({
        title: "Já existe um usuário cadastrado com esse e-mail.",
        status: "info",
        duration: 4000,
        isClosable: true,
      });

      setIsCreateUserLoading(false);
      return;
    }

    toast({
      title: "Usuário cadastrado com sucesso",
      status: "success",
      duration: 4000,
      isClosable: true,
    });

    setIsCreateUserLoading(false);
    setSecondStepStatus(StepStatus.Completed);
    navigate(routes.LOGIN);
  };

  return (
    <>
      <Flex
        bg="whiteAlpha.900"
        p="3rem"
        gap="1.5rem"
        direction="column"
        alignItems="center"
      >
        {firstStepStatus === StepStatus.InProgress && (
          <>
            <Flex gap="1.5rem" alignItems="center">
              <Link onClick={() => navigate(routes.LOGIN)}>
                <Box as={BsArrowLeft} size="1.5rem" color="gray.400" />
              </Link>

              <Image
                w="12rem"
                src="/assets/logo-wehack.svg"
                alt="Wehack Network"
              />
            </Flex>

            <Text fontSize="2xl" color="gary.700">
              Registro
            </Text>

            <form onSubmit={(event) => finishFirstStep(event)}>
              <Flex gap="1.5rem">
                <Stack spacing="1.5rem">
                  <FormControl isRequired>
                    <InputGroup
                      borderColor="gray.400"
                      size="lg"
                      variant="outline"
                      w="19rem"
                    >
                      <Input
                        type="text"
                        placeholder="Nome"
                        focusBorderColor="teal.500"
                        color="gray.400"
                        onChange={(event) => setName(event.target.value)}
                        value={name}
                      />
                    </InputGroup>
                  </FormControl>

                  <FormControl isRequired>
                    <Select
                      placeholder="Tipo de usuário"
                      focusBorderColor="teal.500"
                      borderColor="gray.400"
                      size="lg"
                      variant="outline"
                      color="gray.400"
                      w="19rem"
                      onChange={(event) => setUserRole(event.target.value)}
                      value={userRole}
                    >
                      <option value="researcher">Pesquisador</option>
                      <option value="company">Empresa</option>
                    </Select>
                  </FormControl>

                  <FormControl isRequired isInvalid={!isCellphoneInvalid}>
                    <InputGroup
                      borderColor="gray.400"
                      size="lg"
                      variant="outline"
                      w="19rem"
                    >
                      <Input
                        type="text"
                        placeholder="Celular"
                        focusBorderColor="teal.500"
                        color="gray.400"
                        as={InputMask}
                        mask="+55 (99) 99999-9999"
                        maskChar={null}
                        onChange={(event) => setCellphone(event.target.value)}
                        value={cellphone}
                      />
                    </InputGroup>

                    {!isCellphoneInvalid && (
                      <Flex flexDirection="column" alignItems="center">
                        <FormErrorMessage textDecoration="underline">
                          O número informado é inválido
                        </FormErrorMessage>
                      </Flex>
                    )}
                  </FormControl>
                </Stack>

                <Stack spacing="1.5rem">
                  <FormControl isRequired>
                    <InputGroup
                      borderColor="gray.400"
                      size="lg"
                      variant="outline"
                      w="19rem"
                    >
                      <Input
                        type="text"
                        placeholder="Sobrenome"
                        focusBorderColor="teal.500"
                        color="gray.400"
                        onChange={(event) => setSurname(event.target.value)}
                        value={surname}
                      />
                    </InputGroup>
                  </FormControl>

                  <FormControl isRequired isInvalid={!isCpfOrCnpjInvalid}>
                    <InputGroup
                      borderColor="gray.400"
                      size="lg"
                      variant="outline"
                      w="19rem"
                    >
                      <Input
                        type="text"
                        placeholder="CPF/CNPJ"
                        focusBorderColor="teal.500"
                        color="gray.400"
                        as={InputMask}
                        mask={
                          userRole === "company"
                            ? "99.999.999/9999-99"
                            : "999.999.999-99"
                        }
                        maskChar={null}
                        onChange={(event) => setCpfOrCnpj(event.target.value)}
                        value={cpfOrCnpj}
                      />
                    </InputGroup>

                    {!isCpfOrCnpjInvalid && (
                      <Flex flexDirection="column" alignItems="center">
                        <FormErrorMessage textDecoration="underline">
                          O número informado é inválido
                        </FormErrorMessage>
                      </Flex>
                    )}
                  </FormControl>

                  <FormControl isRequired>
                    <InputGroup
                      borderColor="gray.400"
                      size="lg"
                      variant="outline"
                      w="19rem"
                    >
                      <Input
                        type="email"
                        placeholder="E-mail"
                        focusBorderColor="teal.500"
                        color="gray.400"
                        onChange={(event) => setEmail(event.target.value)}
                        value={email}
                      />
                    </InputGroup>
                  </FormControl>
                </Stack>
              </Flex>

              <Button
                type="submit"
                size="lg"
                variant="solid"
                colorScheme="teal"
                isLoading={false}
                w="100%"
                marginTop="1.5rem"
              >
                Prosseguir
              </Button>
            </form>
          </>
        )}

        {secondStepStatus === StepStatus.InProgress && (
          <>
            <Flex gap="1.5rem" alignItems="center">
              <Link
                onClick={() => {
                  setSecondStepStatus(StepStatus.NotCompleted);
                  setFirstStepStatus(StepStatus.InProgress);
                }}
              >
                <Box as={BsArrowLeft} size="1.5rem" color="gray.400" />
              </Link>

              <Image
                w="12rem"
                src="/assets/logo-wehack.svg"
                alt="Wehack Network"
              />
            </Flex>

            <Text fontSize="2xl" color="gary.700">
              Defina uma senha
            </Text>

            <form onSubmit={(event) => finishSecondStep(event)}>
              <Stack spacing="1.5rem">
                <FormControl isRequired>
                  <InputGroup
                    borderColor="gray.400"
                    size="lg"
                    variant="outline"
                    w="19rem"
                  >
                    <InputLeftElement
                      children={
                        <Box
                          as={BsFillLockFill}
                          size="1.5rem"
                          color="gray.400"
                        />
                      }
                    />

                    <Input
                      type={showPasswordValue ? "text" : "password"}
                      placeholder="Senha"
                      focusBorderColor="teal.500"
                      onChange={(event) =>
                        setPassword(event.currentTarget.value)
                      }
                    />

                    <InputRightElement>
                      <Button
                        borderLeft="1px"
                        borderColor="gray.400"
                        w="95%"
                        h="95%"
                        roundedLeft="0"
                        onClick={handleShowPasswordValue}
                      >
                        {showPasswordValue ? (
                          <Box
                            as={BsFillEyeSlashFill}
                            size="1.5rem"
                            color="gray.700"
                          />
                        ) : (
                          <Box
                            as={BsFillEyeFill}
                            size="1.5rem"
                            color="gray.700"
                          />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <FormControl isRequired isInvalid={!samePassword}>
                  <InputGroup
                    borderColor="gray.400"
                    size="lg"
                    variant="outline"
                    w="19rem"
                  >
                    <InputLeftElement
                      children={
                        <Box
                          as={BsFillLockFill}
                          size="1.5rem"
                          color="gray.400"
                        />
                      }
                    />

                    <Input
                      type={showConfirmPasswordValue ? "text" : "password"}
                      placeholder="Confirmar senha"
                      focusBorderColor="teal.500"
                      onChange={(event) =>
                        setConfirmPassword(event.currentTarget.value)
                      }
                    />

                    <InputRightElement>
                      <Button
                        borderLeft="1px"
                        borderColor="gray.400"
                        w="95%"
                        h="95%"
                        roundedLeft="0"
                        onClick={handleShowConfirmPasswordValue}
                      >
                        {showConfirmPasswordValue ? (
                          <Box
                            as={BsFillEyeSlashFill}
                            size="1.5rem"
                            color="gray.700"
                          />
                        ) : (
                          <Box
                            as={BsFillEyeFill}
                            size="1.5rem"
                            color="gray.700"
                          />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  {!samePassword && (
                    <Flex flexDirection="column" alignItems="center">
                      <FormErrorMessage textDecoration="underline">
                        As senhas não coincidem.
                      </FormErrorMessage>

                      <FormErrorMessage textDecoration="underline">
                        Verifique os campos.
                      </FormErrorMessage>
                    </Flex>
                  )}
                </FormControl>
              </Stack>

              <Button
                type="submit"
                size="lg"
                variant="solid"
                colorScheme="teal"
                isLoading={isCreateUserLoading}
                w="100%"
                marginTop="1.5rem"
              >
                Concluir cadastro
              </Button>
            </form>
          </>
        )}
      </Flex>
    </>
  );
}
