import authHandler from "../../utils/handlers/authHandler";
import programRequests from "../../utils/requests/programRequests";
import ProgramInterface from "../../interfaces/program";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";

import React, { useEffect, useState } from "react";
import { StarIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../enums/routes";
import { BsArrowLeft } from "react-icons/bs";
import {
  Flex,
  Text,
  Box,
  Spacer,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Badge,
  Td,
  Spinner,
  useToast,
  Avatar,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { userRoles } from "../../enums/roles";
import userRequests from "../../utils/requests/userRequests";
import filesRequests from "../../utils/requests/files.requests";

export default function DetailProgram() {
  let { id } = useParams();
  const userRole = sessionControllerHandler.getUserRole();
  const userId = sessionControllerHandler.getUserID();

  const navigate = useNavigate();
  const toast = useToast();

  const confirmDisableProgram = useDisclosure();
  const cancelDisableProgramRef = React.useRef(null);

  const alertBankData = useDisclosure();
  const alertBankDataRef = React.useRef(null);

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabling, setIsDisabling] = useState(false);

  const [title, setTitle] = useState("");
  const [aboutProgram, setAboutProgram] = useState("");
  const [aboutInstitution, setAboutInstitution] = useState("");
  const [apis, setApis] = useState("");
  const [sites, setSites] = useState("");
  const [apps, setApps] = useState("");
  const [outOfScope, setOutOfScope] = useState("");
  const [criticalValue, setCriticalValue] = useState<number>(0.0);
  const [hightValue, setHightValue] = useState<number>(0.0);
  const [mediumValue, setMediumValue] = useState<number>(0.0);
  const [lowValue, setLowValue] = useState<number>(0.0);
  const [isActive, setIsActive] = useState(false);
  const [iconUrl, setIconUrl] = useState("");
  const [icon, setIcon] = useState("");

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
    getProgramDetail();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  const getProgramDetail = async () => {
    setIsLoading(true);

    if (!id) {
      toast({
        title: "Programa não identificado.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setIsLoading(false);
      return;
    }

    const response = await programRequests.get(id);

    if (!response.id) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setIsLoading(false);
      return;
    }

    const programDetails: ProgramInterface = response;

    setTitle(programDetails.title);
    setAboutProgram(programDetails.aboutProgram);
    setAboutInstitution(programDetails.aboutInstitution);
    setApis(programDetails.apis);
    setSites(programDetails.sites);
    setApps(programDetails.apps);
    setOutOfScope(programDetails.outOfScope);
    setCriticalValue(programDetails.criticalValue);
    setHightValue(programDetails.hightValue);
    setMediumValue(programDetails.mediumValue);
    setLowValue(programDetails.lowValue);
    setIconUrl(programDetails.iconUrl);
    setIsActive(programDetails.isActive);

    setIsLoading(false);

    if (programDetails.iconUrl) {
      getProgramIcon(programDetails.iconUrl);
    }
  };

  const disableProgram = async () => {
    if (!id) {
      toast({
        title: "Programa não identificado.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    setIsDisabling(true);

    const body = {
      id,
      title,
      aboutProgram,
      aboutInstitution,
      apis,
      sites,
      apps,
      outOfScope,
      criticalValue,
      hightValue,
      mediumValue,
      lowValue,
      isActive: false,
    };

    const response = await programRequests.edit(body);

    if (!response.id) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setIsDisabling(false);
      return;
    }

    toast({
      title: "Programa desativado!",
      description: `O programa ${title} foi desativado com sucesso.`,
      status: "success",
      duration: 4000,
      isClosable: true,
    });

    setIsDisabling(false);
    navigate(routes.PROGRAM);
    return;
  };

  const createReport = async () => {
    if (!userId) return;

    const response = await userRequests.getBankData(userId);

    if (response == "Ocorreu um erro no servidor, tente novamente mais tarde") {
      toast({
        title: "Não foi possível verificar dados bancários!",
        description: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    if (!response) {
      alertBankData.onOpen();
      return;
    }

    navigate(routes.CREATE_REPORT + id);
  };

  const getProgramIcon = async (url: string) => {
    const response = await filesRequests.get(url);

    if (
      response !== "Ocorreu um erro no servidor, tente novamente mais tarde"
    ) {
      setIcon(response);
    }
  };

  return (
    <>
      <Flex gap="1rem" flexDirection="row" alignItems="center" flexWrap="wrap">
        <Box
          as={BsArrowLeft}
          size="1.5rem"
          color="gray.700"
          cursor="pointer"
          onClick={() =>
            navigate(
              userRole == userRoles.ADMIN ? routes.COMPANIES : routes.PROGRAM
            )
          }
        />

        <Text color="gray.700" size="sm">
          <b>{title}</b>
        </Text>

        <Spacer />

        {(userRole === userRoles.COMPANY || userRole === userRoles.ADMIN) &&
          isActive && (
            <Button
              colorScheme="teal"
              variant="outline"
              isLoading={isDisabling}
              onClick={confirmDisableProgram.onOpen}
            >
              Desativar
            </Button>
          )}

        {userRole === userRoles.COMPANY ||
          (userRole === userRoles.ADMIN && (
            <Button
              colorScheme="teal"
              variant="solid"
              onClick={() => navigate(routes.EDIT_PROGRAM + id)}
            >
              Editar
            </Button>
          ))}

        {userRole === userRoles.GUEST && isActive && (
          <Button
            colorScheme="teal"
            variant="outline"
            isLoading={isDisabling}
            onClick={confirmDisableProgram.onOpen}
          >
            Desativar
          </Button>
        )}

        {userRole === userRoles.GUEST && (
          <Button
            colorScheme="teal"
            variant="solid"
            onClick={() => navigate(routes.EDIT_PROGRAM + id)}
          >
            Editar
          </Button>
        )}

        {userRole === userRoles.RESEARCHER && (
          <Button
            colorScheme="teal"
            variant="solid"
            onClick={() => createReport()}
          >
            Enviar relatório
          </Button>
        )}
      </Flex>

      {isLoading ? (
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          h="70vh"
        >
          <Spinner size="xl" color="teal.500" />
        </Flex>
      ) : (
        <Flex
          p="1.5rem"
          marginTop="1.5rem"
          gap="1rem"
          bg="whiteAlpha.900"
          borderRadius="1rem"
          flexDirection="column"
        >
          <Text color="gray.700" size="sm">
            <b>ícone:</b>
          </Text>

          <Avatar src={icon} icon={<StarIcon fontSize="1.5rem" />} />

          <Text color="gray.700" size="sm">
            <b>Sobre o programa: </b>
            {aboutProgram}
          </Text>

          <Text color="gray.700" size="sm">
            <b>Sobre a instituição: </b>
            {aboutInstitution}
          </Text>

          <TableContainer w="50%">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th borderColor="gray.400">
                    <Badge colorScheme="red" variant="solid">
                      Crítico
                    </Badge>
                  </Th>

                  <Th borderColor="gray.400">
                    <Badge colorScheme="orange" variant="solid">
                      Alto
                    </Badge>
                  </Th>

                  <Th borderColor="gray.400">
                    <Badge colorScheme="green" variant="solid">
                      Médio
                    </Badge>
                  </Th>

                  <Th borderColor="gray.400">
                    <Badge colorScheme="blue" variant="solid">
                      Baixo
                    </Badge>
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                <Tr>
                  <Td borderColor="gray.400">R$ {criticalValue.toFixed(2)}</Td>
                  <Td borderColor="gray.400">R$ {hightValue.toFixed(2)}</Td>
                  <Td borderColor="gray.400">R$ {mediumValue.toFixed(2)}</Td>
                  <Td borderColor="gray.400">R$ {lowValue.toFixed(2)}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          <Text color="gray.700" size="sm">
            <b>API'S: </b>
            {apis}
          </Text>

          <Text color="gray.700" size="sm">
            <b>Sites: </b>
            {sites}
          </Text>

          <Text color="gray.700" size="sm">
            <b>Aplicativos: </b>
            {apps}
          </Text>

          <Text color="gray.700" size="sm">
            <b>Fora de escopo: </b>
            {outOfScope}
          </Text>
        </Flex>
      )}

      <AlertDialog
        isOpen={confirmDisableProgram.isOpen}
        leastDestructiveRef={cancelDisableProgramRef}
        onClose={confirmDisableProgram.onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirmação
            </AlertDialogHeader>

            <AlertDialogBody>
              Você realmente deseja desativar este programa?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelDisableProgramRef}
                onClick={confirmDisableProgram.onClose}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  confirmDisableProgram.onClose();
                  disableProgram();
                }}
                ml={3}
              >
                Desativar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={alertBankData.isOpen}
        leastDestructiveRef={alertBankDataRef}
        onClose={alertBankData.onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Atenção
            </AlertDialogHeader>

            <AlertDialogBody>
              Para enviar um relatório, é necessário ter cadastrado informações
              de dados bancários.{" "}
              <b>Deseja cadastrar os dados bancários agora?</b>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={alertBankDataRef} onClick={alertBankData.onClose}>
                Mais tarde
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  alertBankData.onClose();
                  navigate(routes.MY_ACCOUNT);
                }}
                ml={3}
              >
                Cadastrar informações
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
