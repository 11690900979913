import React, { useEffect, useState } from "react";
import { Box, Text, Center } from "@chakra-ui/react";

interface Props {
  score: number;
}

const ScoreCard: React.FC<Props> = ({ score }) => {
  return (
    <Box borderRadius="1rem" p="1rem" bg="whiteAlpha.900" w="100%" h="100%">
      <Text fontSize="xl" mb="0.5rem">
        Pontuação
      </Text>

      <Center>
        <Text fontSize="lg" as="b">
          {score} pts
        </Text>
      </Center>
    </Box>
  );
};

export default ScoreCard;
