import ProgramInterface from "../../interfaces/program";
import programRequests from "../../utils/requests/programRequests";
import InfiniteScroll from "react-infinite-scroll-component";
import ProgramCard from "../programCard";

import React, { useEffect, useState } from "react";
import { BsCheckCircle } from "react-icons/bs";
import {
  Center,
  Grid,
  GridItem,
  Icon,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";

interface Props {
  lastDays: string;
}

const AllProgram: React.FC<Props> = ({ lastDays }) => {
  const toast = useToast();
  const [page, setPage] = useState(1);
  const [programs, setPrograms] = useState<ProgramInterface[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);

  useEffect(() => {
    resetStates();
    getPrograms(1, true);
  }, [lastDays]);

  const handleChangePage = async () => {
    // if (page === totalPages) {
    //   setHasMorePages(false);
    //   return;
    // }

    setPage(page + 1);
    getPrograms(page + 1);
  };

  const resetStates = () => {
    setPrograms([]);
    setPage(0);
    setHasMorePages(page !== totalPages);
  };

  const getPrograms = async (currentPage: number, isReload?: boolean) => {
    const userLevel = sessionControllerHandler.getUserLevel();
    const userId = sessionControllerHandler.getUserID();

    const response = await programRequests.getAll(
      lastDays,
      isReload ? 1 : currentPage,
      undefined,
      undefined,
      userLevel ?? undefined,
      userId ?? undefined
    );

    if (!response.programs) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      resetStates();
      return;
    }

    setPrograms(
      isReload ? response.programs : [...programs, ...response.programs]
    );
    setTotalRows(response.total);
    setTotalPages(response.totalPages);
    setHasMorePages(page !== response.totalPages);
    return;
  };

  return (
    <InfiniteScroll
      dataLength={programs.length}
      next={() => handleChangePage()}
      hasMore={hasMorePages}
      loader={
        <Center bg="transparent">
          <Spinner color="teal.500" />
        </Center>
      }
      endMessage={
        <Center bg="transparent">
          <Icon as={BsCheckCircle} boxSize={8} color="teal.500" />
        </Center>
      }
      style={{ overflow: "hidden" }}
      scrollableTarget="scrollableGridEl"
    >
      <Grid
        templateColumns="repeat(4, 1fr)"
        bg="whiteAlpha.900"
        p="1rem"
        gap="1.5rem"
        borderRadius="1rem"
        mt="1.5rem"
        mb="1rem"
      >
        {programs.map((program, index) => (
          <GridItem key={index} w="100%" h="auto">
            <ProgramCard
              companyName={`${
                program.company ? program.company.name : "Não definido"
              } ${program.company ? program.company.surname : ""}`}
              id={program.id}
              title={program.title}
              iconUrl={program.iconUrl}
              reportsTotal={program.reports ? program.reports.length : 0}
              criticalValue={program.criticalValue}
              hightValue={program.hightValue}
              lowValue={program.lowValue}
              mediumValue={program.mediumValue}
            />
          </GridItem>
        ))}
      </Grid>
    </InfiniteScroll>
  );
};

export default AllProgram;
