import React from "react";
import { Badge, ChakraProvider } from "@chakra-ui/react";
import { reasonForStatus } from "../../enums/reasonForStatus";

interface Props {
  status: reasonForStatus;
}

const ReasonForStatusBadge: React.FC<Props> = ({ status }) => {
  const normalizeInfos = () => {
    if (status === reasonForStatus.DUPLICATE) {
      return { color: "pink", label: "DUPLICADO" };
    }

    if (status === reasonForStatus.OUT_OF_SCOPE) {
      return { color: "purple", label: "FORA DE ESCOPO" };
    }

    if (status === reasonForStatus.SPAM) {
      return { color: "orange", label: "SPAM" };
    }

    if (status === reasonForStatus.FALSE_POSITIVE) {
      return { color: "teal", label: "FALSO POSITIVO" };
    }

    return { color: "teal", label: status };
  };

  const infos: { color: string; label: string } = normalizeInfos();

  return (
    <ChakraProvider>
      <Badge colorScheme={infos.color} variant="solid">
        {infos.label}
      </Badge>
    </ChakraProvider>
  );
};

export default ReasonForStatusBadge;
