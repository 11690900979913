import axios from "axios";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";

const files = axios.create();

files.interceptors.request.use(async (config: any) => {
  const token = sessionControllerHandler.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token.replace(/"/g, "")}`;
  }
  return config;
});

export default files;
