import React from "react";
import { Badge, ChakraProvider } from "@chakra-ui/react";
import { reportCritically } from "../../enums/critically";

interface Props {
  critically: reportCritically;
}

const CriticallyBadge: React.FC<Props> = ({ critically }) => {
  const normalizeInfos = () => {
    if (critically === reportCritically.CRITICAL) {
      return { color: "red", label: "CRÍTICO" };
    }

    if (critically === reportCritically.HIGH) {
      return { color: "orange", label: "ALTO" };
    }

    if (critically === reportCritically.LOW) {
      return { color: "blue", label: "BAIXO" };
    }

    if (critically === reportCritically.MEDIUM) {
      return { color: "yellow", label: "MÉDIO" };
    }

    return { color: "teal", label: critically };
  };

  const infos: { color: string; label: string } = normalizeInfos();

  return (
    <ChakraProvider>
      <Badge colorScheme={infos.color} variant="solid">
        {infos.label}
      </Badge>
    </ChakraProvider>
  );
};

export default CriticallyBadge;
