import OverviewSimpleCard from "../overviewSimpleCard";
import OverviewDetailedCard from "../overviewDetailedCard";
import overviewProgramRequests from "../../utils/requests/overviewProgramRequest";
import OverviewProgramInterface from "../../interfaces/overviewProgram";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";

import React, { useEffect, useState } from "react";
import { userRoles } from "../../enums/roles";
import { SimpleGrid } from "@chakra-ui/react";

interface Props {
  lastDays: string;
  especificCompanyId?: string;
}

const ProgramOverview: React.FC<Props> = ({ lastDays, especificCompanyId }) => {
  const [overview, setOverview] = useState<OverviewProgramInterface>({
    analysis: { critical: 0, hight: 0, low: 0, medium: 0 },
    attacks_avoided: 0,
    received: { critical: 0, hight: 0, low: 0, medium: 0 },
    resolved: { critical: 0, hight: 0, low: 0, medium: 0 },
  });

  useEffect(() => {
    getInformation();
  }, [lastDays]);

  const getInformation = async () => {
    const companyId =
      especificCompanyId ?? sessionControllerHandler.getUserID();

    const userRole = sessionControllerHandler.getUserRole();
    let userEmail: undefined | string = undefined;

    if (userRole == userRoles.GUEST) {
      userEmail = sessionControllerHandler.getEmail();
    }

    const response = await overviewProgramRequests.get(
      lastDays,
      userEmail ?? undefined,
      companyId ?? undefined
    );

    if (response === "Ocorreu um erro no servidor, tente novamente mais tarde")
      return;

    setOverview(response);
  };

  return (
    <SimpleGrid mt="1.5rem" columns={3} spacing="4rem">
      <OverviewDetailedCard
        titleCard="Novos"
        averageTime={{
          value: "12 horas",
          label: "Tempo médio para primeira resposta",
        }}
        defaultColor="teal"
        noAnswers={{
          label: "Novos sem respostas",
          total: 2,
        }}
        information={[
          {
            name: "Crítico",
            data: [overview ? overview.received.critical : 0],
            color: "#E53E3E",
            badgeColor: "red",
          },
          {
            name: "Alto",
            data: [overview ? overview.received.hight : 0],
            color: "#ED8936",
            badgeColor: "orange",
          },
          {
            name: "Médio",
            data: [overview ? overview.received.medium : 0],
            color: "#ECC94B",
            badgeColor: "yellow",
          },
          {
            name: "Baixo",
            data: [overview ? overview.received.low : 0],
            color: "#3182CE",
            badgeColor: "blue",
          },
        ]}
      />

      <OverviewDetailedCard
        titleCard="Em análise"
        averageTime={{
          value: "1 dia e 2 horas",
          label: "Tempo médio para triagem",
        }}
        defaultColor="purple"
        noAnswers={{
          label: "Triagem sem respostas",
          total: 2,
        }}
        information={[
          {
            name: "Crítico",
            data: [overview ? overview.analysis.critical : 0],
            color: "#E53E3E",
            badgeColor: "red",
          },
          {
            name: "Alto",
            data: [overview ? overview.analysis.hight : 0],
            color: "#ED8936",
            badgeColor: "orange",
          },
          {
            name: "Médio",
            data: [overview ? overview.analysis.medium : 0],
            color: "#ECC94B",
            badgeColor: "yellow",
          },
          {
            name: "Baixo",
            data: [overview ? overview.analysis.low : 0],
            color: "#3182CE",
            badgeColor: "blue",
          },
        ]}
      />

      <OverviewDetailedCard
        titleCard="Resolvidos"
        averageTime={{ value: "10 horas", label: "Tempo médio para resolução" }}
        defaultColor="green"
        noAnswers={{ label: "Resolvidos sem resposta", total: 2 }}
        information={[
          {
            name: "Crítico",
            data: [overview ? overview.resolved.critical : 0],
            color: "#E53E3E",
            badgeColor: "red",
          },
          {
            name: "Alto",
            data: [overview ? overview.resolved.hight : 0],
            color: "#ED8936",
            badgeColor: "orange",
          },
          {
            name: "Médio",
            data: [overview ? overview.resolved.medium : 0],
            color: "#ECC94B",
            badgeColor: "yellow",
          },
          {
            name: "Baixo",
            data: [overview ? overview.resolved.low : 0],
            color: "#3182CE",
            badgeColor: "blue",
          },
        ]}
      />

      <OverviewSimpleCard
        titleCard="Ataques evitados"
        informationLabel="Ataques"
        informationValue={overview ? overview.attacks_avoided : 0}
      />
    </SimpleGrid>
  );
};

export default ProgramOverview;
