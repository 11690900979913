import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";

class AuthHandler {
  checkUserAuthentication() {
    const token = sessionControllerHandler.getToken();

    return !token ? false : true;
  }
}

export default new AuthHandler();
