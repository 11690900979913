import api from "../../services/api";
import { PROFILE_PIC_ENDPOINTS } from "../../enums/apiEndpoints";

class ProfilePicRequests {
  public async upload(userId: string, file: File) {
    const body = new FormData();
    body.append("userId", userId);
    body.append("file", file);

    try {
      const response = await api.post(
        PROFILE_PIC_ENDPOINTS.PROFILE_PIC_UPLOAD,
        body
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async delete(id: string) {
    try {
      const response = await api.delete(
        PROFILE_PIC_ENDPOINTS.PROFILE_PIC_DELETE + `/${id}`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }
}

export default new ProfilePicRequests();
