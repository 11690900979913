import "./style.css";

import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import authHandler from "../../utils/handlers/authHandler";
import programRequests from "../../utils/requests/programRequests";
import ProgramInterface from "../../interfaces/program";
import reportRequests from "../../utils/requests/reportRequests";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../enums/routes";
import { reportCritically } from "../../enums/critically";
import { reportStatus } from "../../enums/status";
import { BsArrowLeft, BsPlusCircle } from "react-icons/bs";
import {
  Flex,
  Text,
  Box,
  Spacer,
  Button,
  FormControl,
  InputGroup,
  Input,
  FormLabel,
  Textarea,
  useToast,
  Skeleton,
  Select,
  HStack,
  Center,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Tooltip,
} from "@chakra-ui/react";
import { reportVulnerability } from "../../enums/vulnerability";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { reportFieldsDescription } from "../../enums/fieldsDescription";

export default function CreateReport() {
  let { id } = useParams();
  let developerId = sessionControllerHandler.getUserID();

  const navigate = useNavigate();
  const toast = useToast();
  const zoomImageModal = useDisclosure();

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [getProgramIsLoading, setGetProgramIsLoading] = useState(true);
  const [createReportLoading, setCreateReportLoading] = useState(false);
  const [imageToZoom, setImageToZoom] = useState<File>();

  const [programExist, setProgramExist] = useState(false);
  const [programTitle, setProgramTitle] = useState("");
  const [solution, setSolution] = useState("");
  const [criticalValue, setCriticalValue] = useState(0);
  const [hightValue, setHightValue] = useState(0);
  const [mediumValue, setMediumValue] = useState(0);
  const [lowValue, setLowValue] = useState(0);
  const [companyId, setCompanyId] = useState("");

  const [vulnerability, setVulnerability] = useState<reportVulnerability>(
    reportVulnerability.BROKEN_ACCESS_CONTROL
  );
  const [critically, setCritically] = useState<reportCritically>(
    reportCritically.LOW
  );
  const [title, setTitle] = useState("");
  const [operation, setOperation] = useState("");
  const [softwares, setSoftwares] = useState("");
  const [howToReproduce, setHowToReproduce] = useState("");
  const [solutions, setSolutions] = useState("");
  const [attachments, setAttachments] = useState<File[]>([]);

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
    getProgramDetail();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  const getProgramDetail = async () => {
    setGetProgramIsLoading(true);

    if (!id) {
      toast({
        title: "Programa não identificado.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setProgramExist(false);
      setGetProgramIsLoading(false);

      return;
    }

    const response = await programRequests.get(id);

    if (!response.id) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setProgramExist(false);
      setGetProgramIsLoading(false);

      return;
    }

    const programDetails: ProgramInterface = response;

    setGetProgramIsLoading(false);
    setProgramExist(true);
    setProgramTitle(programDetails.title);
    setCriticalValue(programDetails.criticalValue);
    setHightValue(programDetails.hightValue);
    setMediumValue(programDetails.mediumValue);
    setLowValue(programDetails.lowValue);
    setCompanyId(programDetails.company.id);
  };

  const createNewReport = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setCreateReportLoading(true);

    if (!programExist) {
      toast({
        title: "Não é possível criar relatório.",
        description: "Houve um problema ao vincular programa.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setCreateReportLoading(false);

      return;
    }

    if (!developerId) {
      toast({
        title: "Não é possível criar relatório.",
        description: "Houve um problema ao vincular desenvolvedor.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setCreateReportLoading(false);

      return;
    }

    if (!id) {
      toast({
        title: "Não é possível criar relatório.",
        description: "Houve um problema ao vincular empresa.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setCreateReportLoading(false);

      return;
    }

    const body = {
      title,
      operation,
      softwares,
      howToReproduce,
      possibleSolutions: solutions,
      critically,
      status: reportStatus.NEW,
      company: { id: companyId },
      developer: { id: developerId },
      program: { id },
      lowValue,
      mediumValue,
      hightValue,
      criticalValue,
      vulnerability,
      solution: solution ?? null,
    };

    const response = await reportRequests.create(body);

    if (!response.report) {
      toast({
        title: "Não é possível criar relatório.",
        description: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setCreateReportLoading(false);
      return;
    }

    toast({
      title: "Relatório enviado!",
      description: "Verifique o progresso em meus relatórios",
      status: "success",
      duration: 4000,
      isClosable: true,
    });

    for (let propertie in attachments) {
      const attachment = attachments[propertie];

      const uploadStatus = await reportRequests.uploadAttachments(
        response.report.id,
        attachment
      );

      if (uploadStatus !== "Anexos adicionados") {
        toast({
          title: "Não foi possível adicionar o anexo " + attachment.name,
          description: uploadStatus,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    }

    setCreateReportLoading(false);
    navigate(routes.PROGRAM_DETAILS + id);
  };

  const includeAttachment = (newAttachment: File) => {
    setAttachments([...attachments, ...[newAttachment]]);
  };

  const selectAttachment = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute(
      "accept",
      ".png, .jpg, .jpeg, video/mp4, video/x-m4v, video/*"
    );

    input.addEventListener("change", () => {
      if (input.files && input.files?.length !== 0) {
        includeAttachment(input.files[0]);
      }
      input.remove();
    });

    input.click();
  };

  return (
    <>
      <form onSubmit={(event) => createNewReport(event)}>
        <Flex
          gap="1rem"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box
            as={BsArrowLeft}
            size="1.5rem"
            color="gray.700"
            cursor="pointer"
            onClick={() => navigate(routes.PROGRAM_DETAILS + id)}
          />

          <Skeleton isLoaded={!getProgramIsLoading}>
            <Text color="gray.700" size="sm">
              <b>{programTitle}</b>
            </Text>
          </Skeleton>

          <Spacer />

          <Button
            colorScheme="teal"
            variant="outline"
            onClick={() => navigate(routes.PROGRAM_DETAILS + id)}
          >
            Cancelar
          </Button>

          <Button
            disabled={programExist}
            colorScheme="teal"
            variant="solid"
            type="submit"
            isLoading={createReportLoading}
          >
            Salvar e enviar
          </Button>
        </Flex>

        <Flex
          p="1.5rem"
          marginTop="1.5rem"
          gap="1rem"
          bg="whiteAlpha.900"
          borderRadius="1rem"
          flexDirection="column"
        >
          <FormControl isRequired>
            <FormLabel
              color="gray.700"
              size="sm"
              display="flex"
              alignItems="center"
              gap="0.5rem"
            >
              Vulnerabilidade
              <Tooltip
                hasArrow
                label={reportFieldsDescription.vulnerability}
                placement="top"
              >
                <InfoOutlineIcon cursor="pointer" />
              </Tooltip>
            </FormLabel>

            <InputGroup
              color="gray.400"
              borderColor="gray.400"
              size="lg"
              variant="outline"
            >
              <Select
                placeholder="Selecione"
                focusBorderColor="teal.500"
                color="gray.400"
                fontSize="sm"
                borderColor="gray.400"
                onChange={(event) =>
                  setVulnerability(event.target.value as reportVulnerability)
                }
              >
                {Object.entries(reportVulnerability).map(
                  ([key, vulnerability], index) => (
                    <option key={index} value={vulnerability}>
                      {vulnerability}
                    </option>
                  )
                )}
              </Select>
            </InputGroup>
          </FormControl>

          <FormControl isRequired>
            <FormLabel
              color="gray.700"
              size="sm"
              display="flex"
              alignItems="center"
              gap="0.5rem"
            >
              Criticidade
              <Tooltip
                hasArrow
                label={reportFieldsDescription.critically}
                placement="top"
              >
                <InfoOutlineIcon cursor="pointer" />
              </Tooltip>
            </FormLabel>

            <InputGroup
              color="gray.400"
              borderColor="gray.400"
              size="lg"
              variant="outline"
            >
              <Select
                placeholder="Selecione"
                focusBorderColor="teal.500"
                color="gray.400"
                fontSize="sm"
                borderColor="gray.400"
                onChange={(event) =>
                  setCritically(event.target.value as reportCritically)
                }
              >
                <option value={reportCritically.CRITICAL}>Crítico</option>
                <option value={reportCritically.HIGH}>Alto</option>
                <option value={reportCritically.LOW}>Baixo</option>
                <option value={reportCritically.MEDIUM}>Médio</option>
              </Select>
            </InputGroup>
          </FormControl>

          <FormControl isRequired>
            <FormLabel
              color="gray.700"
              size="sm"
              display="flex"
              alignItems="center"
              gap="0.5rem"
            >
              Título do Relatório
              <Tooltip
                hasArrow
                label={reportFieldsDescription.title}
                placement="top"
              >
                <InfoOutlineIcon cursor="pointer" />
              </Tooltip>
            </FormLabel>

            <InputGroup
              color="gray.400"
              borderColor="gray.400"
              size="lg"
              variant="outline"
            >
              <Input
                placeholder="Digite aqui"
                focusBorderColor="teal.500"
                color="gray.400"
                fontSize="sm"
                onChange={(event) => setTitle(event.target.value)}
              />
            </InputGroup>
          </FormControl>

          <FormControl isRequired>
            <FormLabel
              color="gray.700"
              size="sm"
              display="flex"
              alignItems="center"
              gap="0.5rem"
            >
              Softwares Utilizados
              <Tooltip
                hasArrow
                label={reportFieldsDescription.softwares}
                placement="top"
              >
                <InfoOutlineIcon cursor="pointer" />
              </Tooltip>
            </FormLabel>

            <InputGroup
              color="gray.400"
              borderColor="gray.400"
              size="lg"
              variant="outline"
            >
              <Input
                placeholder="Digite aqui"
                focusBorderColor="teal.500"
                color="gray.400"
                fontSize="sm"
                onChange={(event) => setSoftwares(event.target.value)}
              />
            </InputGroup>
          </FormControl>

          <HStack spacing="1rem">
            <FormControl isRequired>
              <FormLabel
                color="gray.700"
                size="sm"
                display="flex"
                alignItems="center"
                gap="0.5rem"
              >
                Funcionamento
                <Tooltip
                  hasArrow
                  label={reportFieldsDescription.operaton}
                  placement="top"
                >
                  <InfoOutlineIcon cursor="pointer" />
                </Tooltip>
              </FormLabel>
              <InputGroup
                color="gray.400"
                borderColor="gray.400"
                size="lg"
                variant="outline"
              >
                <Textarea
                  placeholder="Digite aqui"
                  focusBorderColor="teal.500"
                  color="gray.400"
                  fontSize="sm"
                  onChange={(event) => setOperation(event.target.value)}
                />
              </InputGroup>
            </FormControl>

            <FormControl isRequired>
              <FormLabel
                color="gray.700"
                size="sm"
                display="flex"
                alignItems="center"
                gap="0.5rem"
              >
                Como reproduzir
                <Tooltip
                  hasArrow
                  label={reportFieldsDescription.howToReproduce}
                  placement="top"
                >
                  <InfoOutlineIcon cursor="pointer" />
                </Tooltip>
              </FormLabel>
              <InputGroup
                color="gray.400"
                borderColor="gray.400"
                size="lg"
                variant="outline"
              >
                <Textarea
                  placeholder="Digite aqui"
                  focusBorderColor="teal.500"
                  color="gray.400"
                  fontSize="sm"
                  onChange={(event) => setHowToReproduce(event.target.value)}
                />
              </InputGroup>
            </FormControl>
          </HStack>

          <FormControl isRequired>
            <FormLabel
              color="gray.700"
              size="sm"
              display="flex"
              alignItems="center"
              gap="0.5rem"
            >
              Informações adicionais
              <Tooltip
                hasArrow
                label={reportFieldsDescription.solutions}
                placement="top"
              >
                <InfoOutlineIcon cursor="pointer" />
              </Tooltip>
            </FormLabel>
            <InputGroup
              color="gray.400"
              borderColor="gray.400"
              size="lg"
              variant="outline"
            >
              <Textarea
                placeholder="Digite aqui"
                focusBorderColor="teal.500"
                color="gray.400"
                fontSize="sm"
                onChange={(event) => setSolutions(event.target.value)}
              />
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel
              color="gray.700"
              size="sm"
              display="flex"
              alignItems="center"
              gap="0.5rem"
            >
              Solução
              <Tooltip
                hasArrow
                label={reportFieldsDescription.solution}
                placement="top"
              >
                <InfoOutlineIcon cursor="pointer" />
              </Tooltip>
            </FormLabel>
            <InputGroup
              color="gray.400"
              borderColor="gray.400"
              size="lg"
              variant="outline"
            >
              <Textarea
                placeholder="Digite aqui"
                focusBorderColor="teal.500"
                color="gray.400"
                fontSize="sm"
                onChange={(event) => setSolution(event.target.value)}
              />
            </InputGroup>
          </FormControl>

          <Flex gap="0.5rem" flexDirection="column">
            <Text
              color="gray.700"
              fontWeight="500"
              size="sm"
              display="flex"
              alignItems="center"
              gap="0.5rem"
            >
              Anexos
              <Tooltip
                hasArrow
                label={reportFieldsDescription.attachments}
                placement="top"
              >
                <InfoOutlineIcon cursor="pointer" />
              </Tooltip>
            </Text>

            <Flex
              border="1px"
              borderColor="gray.400"
              borderRadius="0.3rem"
              p="0.6rem"
              gap="0.6rem"
            >
              {attachments.map((attachment, index) =>
                attachment.type.includes("image") ? (
                  <Image
                    key={index}
                    src={URL.createObjectURL(attachment)}
                    alt={"Imagem " + attachment.name}
                    boxSize="4.5rem"
                    objectFit="cover"
                    borderRadius="0.3rem"
                    borderColor="gray.400"
                    title={attachment.name}
                    fallbackSrc="https://via.placeholder.com/150"
                    cursor="pointer"
                    onClick={() => {
                      setImageToZoom(attachment);
                      zoomImageModal.onOpen();
                    }}
                  />
                ) : (
                  <video
                    src={URL.createObjectURL(attachment)}
                    title={attachment.name}
                    controls
                    controlsList="nodownload"
                    className="video"
                  />
                )
              )}

              <Center
                w="4.5rem"
                h="4.5rem"
                border="1px"
                borderColor="gray.400"
                borderRadius="0.3rem"
              >
                <Box
                  as={BsPlusCircle}
                  size="1.5rem"
                  color="gray.400"
                  cursor="pointer"
                  onClick={() => selectAttachment()}
                  _hover={{ color: "gray.600" }}
                />
              </Center>
            </Flex>
          </Flex>
        </Flex>
      </form>

      <Modal
        size="full"
        isOpen={zoomImageModal.isOpen}
        onClose={zoomImageModal.onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody m="0" p="2.5rem">
            <Flex justifyContent="center" h="80vh">
              {imageToZoom && (
                <Image
                  src={URL.createObjectURL(imageToZoom)}
                  alt={imageToZoom.name}
                  borderRadius="8px"
                  fallbackSrc="https://via.placeholder.com/150"
                  w="auto"
                  h="100%"
                  objectFit="contain"
                />
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
