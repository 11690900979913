import api from "../../services/api";
import { REPORT_ENDPOINTS } from "../../enums/apiEndpoints";
import { reportStatus } from "../../enums/status";
import ReportInterface from "../../interfaces/report";
import { reportCritically } from "../../enums/critically";
import { reportVulnerability } from "../../enums/vulnerability";
import { reasonForStatus } from "../../enums/reasonForStatus";
import { retestStatus } from "../../enums/retestStatus";

class ReportRequests {
  public async getAll(
    status: reportStatus | "all",
    lastDays: string,
    page: number,
    program: string,
    researcherId?: string,
    companyId?: string,
    guestEmail?: string
  ) {
    try {
      let url: string = REPORT_ENDPOINTS.REPORT_GET;

      if (lastDays === "all") {
        url += `?page=${page}`;
      } else {
        url += `?lastDays=${lastDays}&page=${page}`;
      }

      if (status !== "all") {
        url += `&status=${status}`;
      }

      if (program !== "all") {
        url += `&program=${program}`;
      }

      if (researcherId) {
        url += `&researcherId=${researcherId}`;
      }

      if (companyId) {
        url += `&companyId=${companyId}`;
      }

      if (guestEmail) {
        url += `&email=${guestEmail}`;
      }

      const response = await api.get(url);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async get(id: string) {
    try {
      let url: string = REPORT_ENDPOINTS.REPORT_GET + `/${id}`;

      const response = await api.get(url);
      const report: ReportInterface = response.data;

      return report;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async update(body: {
    id: string;
    title: string;
    operation: string;
    softwares: string;
    howToReproduce: string;
    possibleSolutions: string;
    critically: reportCritically;
    status: reportStatus;
    vulnerability: reportVulnerability;
    reasonForStatus: reasonForStatus | null;
    value: number | null;
    solution?: string;
    retestStatus: retestStatus;
  }) {
    try {
      const url: string = REPORT_ENDPOINTS.REPORT_UPDATE;

      const response = await api.put(url, body);
      const report: ReportInterface = response.data;

      return report;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async create(body: {
    title: string;
    operation: string;
    softwares: string;
    howToReproduce: string;
    possibleSolutions: string;
    critically: reportCritically;
    status: reportStatus;
    company: { id: string };
    developer: { id: string };
    program: { id: string };
    lowValue: number;
    mediumValue: number;
    hightValue: number;
    criticalValue: number;
    vulnerability: reportVulnerability;
    solution?: string;
  }) {
    try {
      const url: string = REPORT_ENDPOINTS.REPORT_CREATE;

      const response = await api.post(url, body);
      const report: { report: ReportInterface } = response.data;

      return report;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async uploadAttachments(reportId: string, file: File) {
    try {
      const body = new FormData();
      body.append("reportId", reportId);
      body.append("files", file);

      const url: string = REPORT_ENDPOINTS.REPORT_UPLOAD_ATTACHMENTS;

      const response = await api.post(url, body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }
}

export default new ReportRequests();
