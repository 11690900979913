import React, { useEffect, useState } from "react";
import { GridItem, SimpleGrid } from "@chakra-ui/react";
import CreatedReportsCard from "../createdReportsCard";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import overviewAdminRequests from "../../utils/requests/overviewAdminRequests";
import InformationCard from "../informationCard";

interface Props {
  month: string;
}

const AdminOverview: React.FC<Props> = ({ month }) => {
  const [chartInformation, setChartInformation] = useState([]);

  const [disputeInformation, setDisputeInformation] = useState([
    { label: "Abertas", value: 0, colorScheme: "red" },
    { label: "Fechadas", value: 0, colorScheme: "red" },
  ]);

  const [researchersInformation, setResearchersInformation] = useState([
    { label: "Cadastros", value: 0, colorScheme: "blue" },
    { label: "Relatórios", value: 0, colorScheme: "blue" },
  ]);

  const [companyInformation, setCompanyInformation] = useState([
    { label: "Cadastradas", value: 0, colorScheme: "teal" },
    { label: "Programas", value: 0, colorScheme: "teal" },
  ]);

  useEffect(() => {
    getInformation();
  }, [month]);

  const getInformation = async () => {
    const response = await overviewAdminRequests.get(month);

    if (response === "Ocorreu um erro no servidor, tente novamente mais tarde")
      return;

    setChartInformation(response.countReportsByDay);

    const defaultDisputeInformation = getDefaultDisputeInformation();
    const defaultResearchersInformation = getDefaultResearchersInformation();
    const defaultCompanyInformation = getDefaultCompanyInformation();

    defaultResearchersInformation[0].value = response.totalResearcher;
    defaultResearchersInformation[1].value = response.totalReports;

    defaultCompanyInformation[0].value = response.totalCompany;
    defaultCompanyInformation[1].value = response.totalPrograms;

    setResearchersInformation(defaultResearchersInformation);
    setCompanyInformation(defaultCompanyInformation);
  };

  const getDefaultDisputeInformation = () => {
    return [
      { label: "Abertas", value: 0, colorScheme: "red" },
      { label: "Fechadas", value: 0, colorScheme: "red" },
    ];
  };

  const getDefaultResearchersInformation = () => {
    return [
      { label: "Cadastros", value: 0, colorScheme: "blue" },
      { label: "Relatórios", value: 0, colorScheme: "blue" },
    ];
  };

  const getDefaultCompanyInformation = () => {
    return [
      { label: "Cadastradas", value: 0, colorScheme: "teal" },
      { label: "Programas", value: 0, colorScheme: "teal" },
    ];
  };

  return (
    <SimpleGrid mt="1.5rem" columns={3} spacing="4rem">
      <GridItem rowSpan={2} colSpan={3}>
        <CreatedReportsCard chartInformation={chartInformation} />
      </GridItem>

      <GridItem rowSpan={2} colSpan={1}>
        <InformationCard
          titleText="Empresas"
          titleColor="teal.500"
          informations={companyInformation}
        />
      </GridItem>

      <GridItem rowSpan={2} colSpan={1}>
        <InformationCard
          titleText="Pesquisadores"
          titleColor="blue.500"
          informations={researchersInformation}
        />
      </GridItem>

      <GridItem rowSpan={2} colSpan={1}>
        <InformationCard
          titleText="Disputas"
          titleColor="red.600"
          informations={disputeInformation}
        />
      </GridItem>
    </SimpleGrid>
  );
};

export default AdminOverview;
