import authHandler from "../../utils/handlers/authHandler";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import ProgramOverview from "../../components/ProgramOverview";

import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { userRoles } from "../../enums/roles";
import { routes } from "../../enums/routes";

import {
  Button,
  Flex,
  Select,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import MyProgram from "../../components/myProgram";

const CompanyDetail: React.FC = () => {
  let { companyId, companyName } = useParams();

  const navigate = useNavigate();
  const userRole = sessionControllerHandler.getUserRole();

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [lastDays, setLastDays] = useState("all");
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <Text m="0" p="0.5rem 0" color="gray.700" fontSize="2xl" as="b">
        {companyName}
      </Text>

      <Tabs
        position="relative"
        variant="unstyled"
        onChange={(index) => setTabIndex(index)}
      >
        <TabList>
          <Flex gap="1.5rem" alignItems="center">
            {userRole === userRoles.ADMIN && (
              <Tab
                m="0"
                p="0.5rem 0"
                color="gray.400"
                fontSize="2xl"
                as="b"
                _selected={{ color: "gray.700" }}
                cursor="pointer"
              >
                Empresa
              </Tab>
            )}

            {userRole === userRoles.ADMIN && (
              <Tab
                m="0"
                p="0.5rem 0"
                color="gray.400"
                fontSize="2xl"
                as="b"
                _selected={{ color: "gray.700" }}
                cursor="pointer"
              >
                Programas
              </Tab>
            )}
          </Flex>

          <Spacer />

          <Flex gap="1.5rem" alignItems="center">
            {userRole === userRoles.ADMIN && tabIndex === 0 && (
              <Button
                type="submit"
                size="md"
                variant="solid"
                colorScheme="teal"
                onClick={() => navigate(routes.USER_DETAILS + companyId)}
              >
                Editar empresa
              </Button>
            )}

            {userRole === userRoles.ADMIN && tabIndex === 0 && (
              <Select
                w="15rem"
                size="md"
                onChange={(event) => setLastDays(event.target.value)}
                defaultValue="all"
                focusBorderColor="teal.500"
              >
                <option value="30">Últimos 30 dias</option>

                <option value="60">Últimos 60 dias</option>

                <option value="90">Últimos 90 dias</option>

                <option value="all">Todos</option>
              </Select>
            )}
          </Flex>
        </TabList>

        <TabPanels>
          {userRole === userRoles.ADMIN && (
            <TabPanel m="0" p="0">
              <ProgramOverview
                lastDays={lastDays}
                especificCompanyId={companyId}
              />
            </TabPanel>
          )}

          {userRole === userRoles.ADMIN && (
            <TabPanel m="0" p="0">
              <MyProgram lastDays={"all"} especificCompanyId={companyId} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default CompanyDetail;
