import { Box, Text } from "@chakra-ui/react";
import React from "react";

import moment from "moment";
import "moment/locale/pt-br";

interface Props {
  isFromLoggedUser?: boolean;
  message: {
    content: string;
    createdAt: Date;
  };
}

const Message: React.FC<Props> = ({ isFromLoggedUser, message }) => {
  moment.locale("pt-br");

  return (
    <Box w="auto" alignSelf={isFromLoggedUser ? "end" : "start"}>
      <Text
        bg={isFromLoggedUser ? "teal.500" : "gray.200"}
        color={isFromLoggedUser ? "whiteAlpha.900" : "gray.700"}
        p="0.5rem 1rem"
        borderRadius="10rem"
      >
        {message.content}
      </Text>

      <Text
        color="gray.600"
        mt="0.5rem"
        fontSize="xs"
        w="auto"
        alignSelf={isFromLoggedUser ? "end" : "start"}
        textAlign={isFromLoggedUser ? "end" : "start"}
      >
        {moment(message.createdAt).format("DD/mm/YYYY, HH:mm")}
      </Text>
    </Box>
  );
};

export default Message;
