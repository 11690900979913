import { ChatInterface } from "../../interfaces/chat";
import { ChatContext, ChatContextInterface } from "../../context/chatContext";
import { userStatus } from "../../enums/userStatus";

import {
  Avatar,
  AvatarBadge,
  Grid,
  HStack,
  Text,
  Center,
} from "@chakra-ui/react";

import React from "react";
import moment from "moment";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";

interface Props {
  chat: ChatInterface;
}

const ChatOption: React.FC<Props> = ({ chat }) => {
  const userId = sessionControllerHandler.getUserID();

  const [isSelected, setIsSelected] = React.useState<boolean>(false);

  const { chatSelected, updateChatSelected } = React.useContext(
    ChatContext
  ) as ChatContextInterface;

  const anotherPersonInChat = chat.users.filter((value) => value.id != userId);

  React.useEffect(() => {
    if (chatSelected && chatSelected.id === chat.id) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [chatSelected]);

  return (
    <Grid
      p="1rem"
      templateColumns="70% 30%"
      gap="0.5rem"
      borderBottom="1px solid"
      borderColor="gray.200"
      bg={isSelected ? "teal.500" : "transparent"}
      cursor="pointer"
      onClick={() => {
        updateChatSelected(chat);
      }}
      _hover={{
        bg: isSelected ? "teal.500" : "gray.200",
        transition: "background-color 0.3s ease",
      }}
    >
      <HStack spacing="0.5rem">
        <Avatar
          name={`${anotherPersonInChat[0].name} ${anotherPersonInChat[0].surname}`}
          bg="teal.500"
          color="whiteAlpha.900"
          size="sm"
          src=""
        >
          <AvatarBadge
            boxSize="1.25em"
            bg={
              anotherPersonInChat[0].status === userStatus.ONLINE
                ? "green.500"
                : "red.500"
            }
          />
        </Avatar>

        <Text
          fontSize="sm"
          as="b"
          noOfLines={1}
          color={isSelected ? "whiteAlpha.900" : "gray.700"}
        >
          {`${anotherPersonInChat[0].name} ${anotherPersonInChat[0].surname}`}
        </Text>
      </HStack>

      {chat.unreadMessageCount !== 0 ? (
        <Center borderRadius="100%" boxSize="8" bg="red.500" justifySelf="end">
          <Text fontSize="sm" noOfLines={1} color="whiteAlpha.900">
            {chat.unreadMessageCount}
          </Text>
        </Center>
      ) : (
        <p></p>
      )}

      <Text
        fontSize="sm"
        noOfLines={1}
        color={isSelected ? "whiteAlpha.900" : "gray.700"}
      >
        {chat.messages[0] ? chat.messages[0].text : ""}
      </Text>

      <Text
        fontSize="sm"
        noOfLines={1}
        justifySelf="end"
        color={isSelected ? "whiteAlpha.900" : "gray.700"}
      >
        {anotherPersonInChat[0].lastTimeOnline
          ? moment()
              .startOf("hour")
              .from(new Date(anotherPersonInChat[0].lastTimeOnline))
              .toString()
          : "Informação indisponível"}
      </Text>
    </Grid>
  );
};

export default ChatOption;
