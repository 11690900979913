import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";

interface Props {
  chartInformation: { day: string; totalApprovedReports: number }[];
}

const ApprovedReportsCard: React.FC<Props> = ({ chartInformation }) => {
  const chartConfig = {
    series: [
      {
        name: "Relatórios aprovados",
        data: chartInformation.reduce(
          (accumulator: any, currentValue: any) => [
            ...accumulator,
            {
              x: currentValue.day,
              y: currentValue.totalApprovedReports,
            },
          ],
          []
        ),
      },
    ],

    options: {
      chart: {
        type: "line" as any,
        toolbar: {
          show: false,
        },
      },

      markers: {
        size: 6,
        color: "#3182CE",
        fillOpacity: 0.5,
      },

      legend: {
        show: false,
      },

      yaxis: {
        forceNiceScale: true,
        tickAmount: 1,
        decimalsInFloat: 0,
        labels: {
          formatter: function (val: any) {
            return val.toFixed(0);
          },
        },
      },

      tooltip: {
        x: {
          show: true,
          formatter: function (
            value: any,
            { series, seriesIndex, dataPointIndex, w }: any
          ) {
            return "Dia " + w.config.series[0].data[dataPointIndex].x;
          },
        },
      },

      stroke: {
        width: 2,
      },

      colors: ["#3182CE"],

      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
    },
  };

  return (
    <Box
      borderRadius="1rem"
      p="1rem"
      bg="whiteAlpha.900"
      w="100%"
      h="100%"
      maxH="15rem"
      overflowY="auto"
    >
      <Text fontSize="xl" mb="0.5rem">
        Relatórios aprovados
      </Text>

      <ReactApexChart
        options={chartConfig.options}
        series={chartConfig.series}
        type="line"
        height={150}
      />
    </Box>
  );
};

export default ApprovedReportsCard;
