import React, { useEffect, useState } from "react";

import { Grid, GridItem, useToast } from "@chakra-ui/react";

import ProgramsListCard from "../programsListCard";
import ScoreCard from "../scoreCard";
import RewardCard from "../rewardCard";
import ApprovedReportsCard from "../approvedReportsCard";
import overviewRequest from "../../utils/requests/overviewRequest";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";

interface Props {
  month: string;
}

const Overview: React.FC<Props> = ({ month }) => {
  const toast = useToast();

  const [programs, setPrograms] = useState([]);
  const [chartInformation, setChartInformation] = useState([]);
  const [score, setScore] = useState(0);
  const [reward, setReward] = useState(0);

  useEffect(() => {
    getOverviewInformation();
  }, [month]);

  const getOverviewInformation = async () => {
    const userId = sessionControllerHandler.getUserID();

    if (!userId) return;

    const information = await overviewRequest.get(month, userId);

    if (!information.countReportsByCompany) {
      toast({
        title: "Não foi possível obter informações da dashboard",
        description: information,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    setPrograms(information.countReportsByCompany);
    setChartInformation(information.countApprovedReportsByDay);
    setScore(information.score);
    // setReward(information.reward);
  };

  return (
    <Grid mt="1.5rem" templateColumns="repeat(2, 1fr)" gap="2rem">
      <GridItem rowSpan={2} colSpan={2}>
        <ApprovedReportsCard chartInformation={chartInformation} />
      </GridItem>

      <GridItem rowSpan={2}>
        <ProgramsListCard programs={programs} />
      </GridItem>

      <GridItem rowSpan={1}>
        <ScoreCard score={score} />
      </GridItem>

      <GridItem rowSpan={1}>
        <RewardCard reward={reward} />
      </GridItem>
    </Grid>
  );
};

export default Overview;
