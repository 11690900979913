import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BsFillPersonFill,
  BsFillLockFill,
  BsFillEyeSlashFill,
  BsFillEyeFill,
} from "react-icons/bs";
import {
  Flex,
  Image,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Box,
  Stack,
  Button,
  Link,
  Divider,
  Text,
  FormErrorMessage,
  useToast,
  HStack,
  PinInput,
  PinInputField,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  VStack,
} from "@chakra-ui/react";
import userRequests from "../../utils/requests/userRequests";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import authHandler from "../../utils/handlers/authHandler";
import { routes } from "../../enums/routes";
import ReCAPTCHA from "react-google-recaptcha";
import logoutAndReload from "../../utils/logoutAndReload";

export default function LoginModal() {
  const navigate = useNavigate();
  const toast = useToast();
  const forgotPasswordModal = useDisclosure();

  const [showPasswordValue, setShowPasswordValue] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [hasErrorPassword, setHasErrorPassword] = useState(false);
  const [hasErrorEmail, setHasErrorEmail] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [inDoubleAuth, setInDoubleAuth] = useState(false);
  const [timer, setTimer] = useState(60);
  const [code, setCode] = useState("");
  const [emailToUpdatePassword, setEmailToUpdatePassword] = useState("");
  const [sendCodeAttempts, setSendCodeAttempts] = useState(0);
  const [captchaValue, setCaptchaValue] = useState<null | string>(null);
  const [loginCaptcha, setLoginCaptcha] = useState<any>(null);

  const [authInformation, setAuthInformation] = useState({
    userID: "",
    userRole: "",
    userName: "",
    userSurname: "",
    profilePicId: "",
    useUploadedPic: false,
    email: "",
    level: 0,
  });

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
  }, []);

  useEffect(() => {
    setTimeout(startTimer, 1000);
  }, [timer]);

  useEffect(() => {
    if (isAuthenticated) {
      const userId = sessionControllerHandler.getUserID();

      navigate(routes.HOMEPAGE);
    }
  }, [isAuthenticated, navigate]);

  const handleShowPasswordValue = () =>
    setShowPasswordValue(!showPasswordValue);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    const response = await userRequests.login({
      email,
      password,
      captchaValue,
    });

    if (response === "Usuário não encontrado") {
      setIsLoading(false);
      setHasErrorEmail(true);
      return;
    }

    setHasErrorEmail(false);

    if (response === "Senha incorreta") {
      setIsLoading(false);
      setHasErrorPassword(true);
      return;
    }

    setHasErrorPassword(false);

    if (
      response === "Ocorreu um erro no servidor, tente novamente mais tarde"
    ) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setIsLoading(false);
      return;
    }

    if (response === "ReCAPTCHA inválido.") {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setIsLoading(false);
      if (loginCaptcha) loginCaptcha.reset();
      return;
    }

    setAuthInformation({
      userID: response.foundUser.id,
      userRole: response.foundUser.role,
      userName: response.foundUser.name,
      userSurname: response.foundUser.surname,
      profilePicId: response.foundUser.profilePic
        ? response.foundUser.profilePic.id
        : null,
      useUploadedPic: response.foundUser.useUploadedPic,
      email: response.foundUser.email,
      level: response.foundUser.level,
    });

    setIsLoading(false);
    setInDoubleAuth(true);
    startTimer();
    sendAuthCode(response.foundUser.id);
  };

  const startTimer = () => {
    if (timer - 1 >= 0) {
      setTimer(timer - 1);
    }
  };

  const sendAuthCode = async (userId: string) => {
    const response = await userRequests.sendAuthCode(userId);

    if (response !== "Código de autenticação enviado com sucesso") {
      toast({
        title:
          "Não foi possível enviar o codigo de autenticação. Tente novamente.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const confirmAuthCode = async (userId: string) => {
    const response = await userRequests.confirmAuthCode(userId, code);

    if (!response.success) {
      toast({
        title: "Código de autenticação inválido.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    sessionControllerHandler.setToken(response.token);
    sessionControllerHandler.setUserID(authInformation.userID);
    sessionControllerHandler.setUserRole(authInformation.userRole);
    sessionControllerHandler.setUserName(authInformation.userName);
    sessionControllerHandler.setUserSurname(authInformation.userSurname);
    sessionControllerHandler.setProfilePicId(authInformation.profilePicId);
    sessionControllerHandler.setUseUploadedPic(authInformation.useUploadedPic);
    sessionControllerHandler.setEmail(authInformation.email);
    sessionControllerHandler.setUserLevel(authInformation.level);

    setTimeout(() => {
      logoutAndReload();
    }, response.tokenExpiresInHours * 60 * 60 * 1000);

    setIsAuthenticated(true);
  };

  const forgotPassword = async () => {
    const response = await userRequests.sendResetPasswordCode(
      emailToUpdatePassword
    );

    if (response !== "Código enviado com sucesso") {
      toast({
        title:
          "Não foi possível enviar o codigo de validação. Tente novamente. Certifique-se de que o email informado está correto.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    toast({
      title: "Um código de validação foi enviado ao email cadastrado.",
      status: "success",
      duration: 4000,
      isClosable: true,
    });

    sessionControllerHandler.setEmailToChangePassword(emailToUpdatePassword);

    navigate(routes.FORGOT_PASSWORD);
  };

  const onCaptchaChange = async (value: string | null) => {
    setCaptchaValue(value);
  };

  return (
    <>
      <Flex
        bg="whiteAlpha.900"
        p="3rem"
        gap="1.5rem"
        direction="column"
        alignItems="center"
        maxW="25rem"
      >
        <Image
          src="/assets/logo-wehack.svg"
          alt="Wehack Network"
          w="70%"
          mb="1.5rem"
        />

        {!inDoubleAuth && (
          <>
            <form onSubmit={(event) => handleLogin(event)}>
              <Stack spacing="1.5rem">
                <FormControl isRequired isInvalid={hasErrorEmail}>
                  <InputGroup
                    borderColor="gray.400"
                    size="lg"
                    variant="outline"
                    w="19rem"
                  >
                    <InputLeftElement
                      children={
                        <Box
                          as={BsFillPersonFill}
                          size="1.5rem"
                          color="gray.400"
                        />
                      }
                    />
                    <Input
                      type="email"
                      placeholder="E-mail"
                      focusBorderColor="teal.500"
                      onChange={(event) => setEmail(event.currentTarget.value)}
                    />
                  </InputGroup>

                  {hasErrorEmail && (
                    <Flex flexDirection="column" alignItems="center">
                      <FormErrorMessage textDecoration="underline">
                        E-mail não encontrado.
                      </FormErrorMessage>
                    </Flex>
                  )}
                </FormControl>

                <FormControl isRequired isInvalid={hasErrorPassword}>
                  <InputGroup
                    borderColor="gray.400"
                    size="lg"
                    variant="outline"
                    w="19rem"
                  >
                    <InputLeftElement
                      children={
                        <Box
                          as={BsFillLockFill}
                          size="1.5rem"
                          color="gray.400"
                        />
                      }
                    />

                    <Input
                      type={showPasswordValue ? "text" : "password"}
                      placeholder="Senha"
                      focusBorderColor="teal.500"
                      onChange={(event) =>
                        setPassword(event.currentTarget.value)
                      }
                    />

                    <InputRightElement>
                      <Button
                        borderLeft="1px"
                        borderColor="gray.400"
                        w="95%"
                        h="95%"
                        roundedLeft="0"
                        onClick={handleShowPasswordValue}
                      >
                        {showPasswordValue ? (
                          <Box
                            as={BsFillEyeSlashFill}
                            size="1.5rem"
                            color="gray.700"
                          />
                        ) : (
                          <Box
                            as={BsFillEyeFill}
                            size="1.5rem"
                            color="gray.700"
                          />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  {hasErrorPassword && (
                    <Flex flexDirection="column" alignItems="center">
                      <FormErrorMessage textDecoration="underline">
                        Senha incorreta.
                      </FormErrorMessage>
                    </Flex>
                  )}
                </FormControl>

                <Link
                  color="gray.700"
                  alignSelf="end"
                  textDecoration="underline"
                  onClick={() => forgotPasswordModal.onOpen()}
                >
                  Esqueci a senha
                </Link>

                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={onCaptchaChange}
                  ref={(c) => setLoginCaptcha(c)}
                />

                <Button
                  type="submit"
                  size="lg"
                  variant="solid"
                  colorScheme="teal"
                  isLoading={isLoading}
                  isDisabled={captchaValue ? false : true}
                >
                  Entrar
                </Button>
              </Stack>
            </form>

            {/* <Divider />

            <Text alignSelf="center">Ainda não possuí conta?</Text>

            <Button
              size="lg"
              w="100%"
              variant="outline"
              colorScheme="teal"
              onClick={() => navigate(routes.REGISTER_USER)}
            >
              Registrar-se
            </Button> */}
          </>
        )}

        {inDoubleAuth && (
          <>
            <form
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Stack spacing="1.5rem">
                <Text alignSelf="center" textAlign="center">
                  Um código de autenticação foi enviado para o e-mail
                  cadastrado. Por favor, insira o código no campo abaixo.
                </Text>

                <HStack justifyContent="center">
                  <PinInput
                    type="number"
                    focusBorderColor="teal.500"
                    onChange={setCode}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>

                <Button
                  type="submit"
                  size="lg"
                  variant="solid"
                  colorScheme="teal"
                  onClick={(event) => {
                    event.preventDefault();
                    confirmAuthCode(authInformation.userID);
                  }}
                >
                  Confirmar
                </Button>
              </Stack>
            </form>

            <Divider />

            <Text alignSelf="center">Não recebeu o código?</Text>

            <Button
              size="lg"
              w="100%"
              variant="outline"
              colorScheme="teal"
              isDisabled={timer !== 0}
              onClick={() => {
                if (sendCodeAttempts === 3) {
                  toast({
                    title: "Você já enviou códigos demais",
                    description: "Por favor, tente novamente mais tarde.",
                    status: "warning",
                    duration: 4000,
                    isClosable: true,
                  });

                  return;
                }

                setSendCodeAttempts(sendCodeAttempts + 1);
                setTimer(60);
                sendAuthCode(authInformation.userID);
              }}
            >
              Enviar novamente em {timer} seg
            </Button>
          </>
        )}
      </Flex>

      <Modal
        isOpen={forgotPasswordModal.isOpen}
        onClose={forgotPasswordModal.onClose}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            forgotPassword();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Text color="gray.700" fontSize="2xl" as="b">
                Alterar senha
              </Text>
            </ModalHeader>

            <ModalBody>
              <VStack spacing="1rem" alignItems={"flex-start"}>
                <Text>
                  Vamos enviar um código de verificação para que você possa
                  alterar sua senha.
                </Text>

                <Text>Por favor, insira seu email cadastrado abaixo.</Text>

                <FormControl mb="1.5rem" isRequired>
                  <InputGroup
                    borderColor="gray.400"
                    size="lg"
                    variant="outline"
                    w="100%"
                  >
                    <Input
                      type={"email"}
                      placeholder="Email"
                      focusBorderColor="teal.500"
                      onChange={(event) =>
                        setEmailToUpdatePassword(event.currentTarget.value)
                      }
                    />
                  </InputGroup>
                </FormControl>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button
                mr="1rem"
                variant="outline"
                colorScheme="teal"
                onClick={forgotPasswordModal.onClose}
              >
                Cancelar
              </Button>
              <Button colorScheme="teal" type="submit">
                Confirmar
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
