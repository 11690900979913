export enum routes {
  LOGIN = "/",
  REGISTER_USER = "/register-user/",
  FORGOT_PASSWORD = "/forgot-password/",

  HOMEPAGE = "/wehack/homepage/",

  MY_ACCOUNT = "/wehack/my-account/",
  USER_DETAILS = "/wehack/user-details/",

  REPORTS = "/wehack/reports",
  REPORT_DETAILS = "/wehack/reports/",
  REPORT_EDIT = "/wehack/reports/edit/",

  PROGRAM = "/wehack/programs/",
  PROGRAM_DETAILS = "/wehack/programs/",
  CREATE_PROGRAM = "/wehack/programs/create/",
  EDIT_PROGRAM = "/wehack/programs/edit/",
  CREATE_REPORT = "/wehack/programs/insert-report/",

  MESSAGES = "/wehack/messages/",
  FINANCIAL = "/wehack/financial/",

  COMPANIES = "/wehack/companies/",
  COMPANIES_DETAILS = "/wehack/companies/",
}
