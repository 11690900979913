import React from "react";
import { Box, Text, Flex, Badge, Spacer, Icon } from "@chakra-ui/react";
import ReactApexCharts from "react-apexcharts";
import CircleIcon from "../circleIcon";

interface Props {
  titleCard: string;
  information: {
    name?: string;
    data: number[];
    color?: string;
    badgeColor: string;
  }[];
  averageTime: { label: string; value: string };
  noAnswers: { label: string; total: number };
  defaultColor: string;
}

const OverviewDetailedCard: React.FC<Props> = ({
  titleCard,
  information,
  averageTime,
  noAnswers,
  defaultColor,
}) => {
  const totalInformation = information.reduce(
    (accumulator, currentValue) => accumulator + currentValue.data[0],
    0
  );

  const chartOptions = {
    series: information,
    options: {
      chart: {
        type: "bar",
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false,
        },
      },

      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "100%",
          columnWidth: "100%",
          borderRadius: 2,
        },
      },

      legend: {
        show: false,
      },

      grid: {
        show: false,
        padding: { left: -15, right: 0, top: 0, bottom: 0 },
      },

      yaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },

      xaxis: {
        labels: {
          show: false,
        },

        axisBorder: {
          show: false,
        },

        axisTicks: {
          show: false,
        },
      },

      tooltip: {
        x: {
          show: false,
        },
      },

      dataLabels: {
        enabled: false,
      },
    },
  };

  return (
    <Box p="1.5rem" bg="whiteAlpha.900" height="auto">
      <Flex mb="1rem" gap="1rem">
        <Text color="gray.700" fontSize="xl">
          {titleCard}
        </Text>

        <Badge
          fontSize="md"
          variant="solid"
          colorScheme={defaultColor}
          borderRadius="5px"
        >
          {totalInformation}
        </Badge>
      </Flex>

      <Flex flexDirection="column" gap="1rem">
        {information.map((info, index) => (
          <Flex key={index} gap="0.5rem" alignItems="center">
            <CircleIcon color={info.color} />

            <Text color="gray.700" fontSize="sm">
              {info.name}
            </Text>

            <Spacer />

            <Badge
              fontSize="sm"
              variant="outline"
              colorScheme={info.badgeColor}
              borderRadius="5px"
            >
              {info.data[0]}
            </Badge>
          </Flex>
        ))}
      </Flex>

      <Flex flexDirection="column" gap="1rem">
        {totalInformation === 0 ? (
          <Text p="1.875rem" alignSelf="center" color="gray.400">
            Sem relatórios identificados
          </Text>
        ) : (
          <ReactApexCharts
            options={chartOptions.options as ApexCharts.ApexOptions}
            series={chartOptions.series as ApexAxisChartSeries}
            type="bar"
            height={70}
          />
        )}
      </Flex>

      {/* <Flex flexDirection="column" alignItems="center" mb="1rem" gap="1rem">
        <Text color="gray.700" fontSize="sm">
          {averageTime.label}
        </Text>

        <Badge
          fontSize="sm"
          variant="solid"
          colorScheme={defaultColor}
          borderRadius="5px"
        >
          {averageTime.value}
        </Badge>
      </Flex>

      <Flex>
        <Text color="gray.700" fontSize="sm">
          {noAnswers.label}
        </Text>

        <Spacer />

        <Badge
          fontSize="sm"
          variant="solid"
          colorScheme="gray"
          borderRadius="5px"
        >
          {noAnswers.total}
        </Badge>
      </Flex> */}
    </Box>
  );
};

export default OverviewDetailedCard;
