import { Routes, Route, Outlet } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import { routes } from "../enums/routes";

import NavigationMenu from "../components/navigationMenu";
import TopBar from "../components/topBar";

import Homepage from "../pages/homepage";
import Login from "../pages/login";
import MyAccount from "../pages/myAccount";
import Programs from "../pages/programs";
import RegisterUser from "../pages/registerUser";
import ReportDetails from "../pages/reportDetails";
import Reports from "../pages/reports";
import CreateProgram from "../pages/createProgram";
import DetailProgram from "../pages/detailProgram";
import EditProgram from "../pages/editProgram";
import CreateReport from "../pages/createReport";
import EditReport from "../pages/editReport";
import FinancialHistory from "../pages/financialHistory";
import Message from "../pages/message";
import ForgotPassword from "../pages/forgotPassword";
import Developing from "../pages/developing";
import Companies from "../pages/companies";
import CompanyDetail from "../pages/companyDetail";
import UserDetails from "../pages/userDetails";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path={routes.LOGIN} element={<Login />} />
      <Route path={routes.REGISTER_USER} element={<RegisterUser />} />
      <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />

      <Route path="wehack" element={<MainPage />}>
        <Route index path={routes.HOMEPAGE} element={<Homepage />} />

        <Route path={routes.MY_ACCOUNT} element={<MyAccount />} />

        <Route path={routes.REPORTS} element={<Reports />} />
        <Route path={routes.CREATE_REPORT + ":id"} element={<CreateReport />} />
        <Route
          path={routes.REPORT_DETAILS + ":id"}
          element={<ReportDetails />}
        />
        <Route path={routes.REPORT_EDIT + ":id"} element={<EditReport />} />

        <Route path={routes.PROGRAM} element={<Programs />} />
        <Route path={routes.CREATE_PROGRAM} element={<CreateProgram />} />
        <Route
          path={routes.PROGRAM_DETAILS + ":id"}
          element={<DetailProgram />}
        />
        <Route path={routes.EDIT_PROGRAM + ":id"} element={<EditProgram />} />

        {/* <Route path={routes.FINANCIAL} element={<FinancialHistory />} /> */}
        <Route path={routes.FINANCIAL} element={<Developing />} />

        <Route path={routes.MESSAGES} element={<Message />} />

        <Route path={routes.COMPANIES} element={<Companies />} />

        <Route
          path={routes.COMPANIES_DETAILS + ":companyId/:companyName"}
          element={<CompanyDetail />}
        />

        <Route path={routes.USER_DETAILS + ":id"} element={<UserDetails />} />
      </Route>
    </Routes>
  );
}

function MainPage() {
  return (
    <>
      <TopBar />
      <NavigationMenu />

      <Box
        p="1.5rem"
        position="absolute"
        overflow={"auto"}
        left="15vw"
        top="9vh"
        w="85vw"
        h="91vh"
        bg="gray.50"
        id="scrollableGridEl"
      >
        <Outlet />
      </Box>
    </>
  );
}
