import React, { useEffect, useState } from "react";
import { SimpleGrid, Box, Text, Flex, Badge, Spacer } from "@chakra-ui/react";

interface Props {
  titleCard: string;
  informationLabel: string;
  informationValue: number;
}

const OverviewSimpleCard: React.FC<Props> = ({
  titleCard,
  informationLabel,
  informationValue,
}) => {
  return (
    <Box p="1.5rem" bg="whiteAlpha.900" height="auto">
      <Text mb="1rem" color="gray.700" fontSize="xl">
        {titleCard}
      </Text>

      <Flex>
        <Text color="gray.700" fontSize="sm">
          {informationLabel}
        </Text>

        <Spacer />

        <Badge
          fontSize="sm"
          variant="solid"
          colorScheme="gray"
          borderRadius="5px"
        >
          {informationValue}
        </Badge>
      </Flex>
    </Box>
  );
};

export default OverviewSimpleCard;
