import React from "react";
import { Center } from "@chakra-ui/react";
import LoginModal from "../../components/loginModal";

export default function Login() {
  return (
    <>
      <Center bg="gray.50" h="100vh">
        <LoginModal />
      </Center>
    </>
  );
}
