import api from "../../services/api";
import OverviewInterface from "../../interfaces/overview";
import { OVERVIEW_ENDPOINTS } from "../../enums/apiEndpoints";

class OverviewRequest {
  public async get(month: string, researcherId: string) {
    try {
      let url: string = OVERVIEW_ENDPOINTS.GET;

      url += `?month=${month}`;
      url += `&researcherId=${researcherId}`;

      const response = await api.get(url);
      return response.data as OverviewInterface;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }
}

export default new OverviewRequest();
