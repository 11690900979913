import api from "../../services/api";
import { USER_ENDPOINTS } from "../../enums/apiEndpoints";
import BankData from "../../interfaces/bankData";
import { coin } from "../../enums/coin";

class UserRequests {
  public async login(body: {
    email: string;
    password: string;
    captchaValue: string | null;
  }) {
    try {
      const response = await api.post(USER_ENDPOINTS.USER_LOGIN, body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async logout(body: { id: string }) {
    try {
      const response = await api.post(USER_ENDPOINTS.USER_LOGOUT, body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async sendAuthCode(userId: string) {
    try {
      const response = await api.put(USER_ENDPOINTS.USER_SEND_AUTH_CODE, {
        id: userId,
      });
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async confirmAuthCode(userId: string, authCode: string) {
    try {
      const response = await api.post(USER_ENDPOINTS.USER_CONFIRM_AUTH_CODE, {
        id: userId,
        authCode,
      });
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async create(body: {
    name: string;
    surname: string;
    profilePicSrc: string;
    email: string;
    password: string;
    cnpjOrCpf: string;
    cellphone: string;
    role: string;
  }) {
    try {
      const response = await api.post(USER_ENDPOINTS.USER_CREATE, body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async update(body: {
    id: string;
    name: string;
    surname: string;
    email: string;
    cnpjOrCpf: string;
    cellphone: string;
    useUploadedPic: boolean;
    coin: coin | null;
  }) {
    try {
      const response = await api.put(USER_ENDPOINTS.USER_UPDATE, body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async getUser(id: string) {
    try {
      const response = await api.get(
        USER_ENDPOINTS.USER_GET_DETAILS + `/${id}`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async updatePassword(body: {
    id: string;
    password: string;
    newPassword: string;
  }) {
    try {
      const response = await api.put(USER_ENDPOINTS.USER_UPDATE_PASSWORD, body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async updateBankData(userId: string, bankData: BankData) {
    try {
      const payload = {
        userId,
        bankData,
      };

      const response = await api.put(
        USER_ENDPOINTS.USER_PUT_BANK_DATA,
        payload
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async getBankData(userId: string) {
    try {
      const response = await api.get(
        USER_ENDPOINTS.USER_GET_BANK_DATA + `/${userId}`
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async sendResetPasswordCode(email: string) {
    try {
      const response = await api.put(
        USER_ENDPOINTS.USER_SEND_RESET_PASSWORD_CODE,
        {
          email,
        }
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async validateResetPassword(email: string, resetPasswordCode: string) {
    try {
      const response = await api.post(USER_ENDPOINTS.VALIDATE_CODE_PASSWORD, {
        email,
        resetPasswordCode,
      });

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async resetPassword(
    email: string,
    newPassword: string,
    resetPasswordCode: string,
    captchaValue: string | null
  ) {
    try {
      const response = await api.post(USER_ENDPOINTS.USER_RESET_PASSWORD, {
        email,
        newPassword,
        resetPasswordCode,
        captchaValue,
      });

      return response;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response;
    }
  }

  public async getCompanies(page: number) {
    try {
      let url: string = USER_ENDPOINTS.USER_GET_COMPANIES + `?page=${page}`;

      const response = await api.get(url);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }

  public async inviteGuest(guestEmail: string, companyId: string) {
    try {
      const payload = {
        email: guestEmail,

        company: {
          id: companyId,
        },
      };

      const response = await api.post(USER_ENDPOINTS.INVITE_GUEST, payload);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }
}

export default new UserRequests();
