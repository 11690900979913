import "@mui/lab/themeAugmentation";
import UserInterface from "../../interfaces/user";
import userRequests from "../../utils/requests/userRequests";

import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { routes } from "../../enums/routes";
import { useNavigate } from "react-router-dom";
import { BsFillArrowUpRightSquareFill } from "react-icons/bs";
import { Box, HStack, useToast } from "@chakra-ui/react";

import {
  createTheme,
  Table,
  TableContainer,
  TableHead,
  ThemeProvider,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Pagination,
} from "@mui/material";

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: "#319795",
    },
  },

  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          padding: "1.3rem",
          marginTop: "1.5rem",
          marginBottom: "1rem",
          borderRadius: "16px",
          border: "1px solid #E2E8F0",
          minWidth: 650,
        },
      },
    },
    MuiTableCell: { styleOverrides: { root: { padding: "1rem" } } },
  },
});

const AllCompanies: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [page, setPage] = useState(1);
  const [companies, setCompanies] = useState<UserInterface[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getCompanies(page);
  }, []);

  const handleChangePage = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    getCompanies(value);
  };

  const getCompanies = async (currentPage: number) => {
    const response = await userRequests.getCompanies(currentPage);

    if (!response.companies) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setCompanies([]);
      setTotalPages(1);
      setPage(1);

      return;
    }

    setCompanies(response.companies);
    setTotalRows(response.total);
    setTotalPages(response.totalPages);
    return;
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", fontColor: "#4A5568" }}>
                EMPRESA
              </TableCell>

              <TableCell sx={{ fontWeight: "bold", fontColor: "#4A5568" }}>
                CNPJ
              </TableCell>

              <TableCell
                align="left"
                sx={{ fontWeight: "bold", fontColor: "#4A5568" }}
              >
                PROGRAMAS
              </TableCell>

              <TableCell
                sx={{ fontWeight: "bold", fontColor: "#4A5568" }}
              ></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {companies.length !== 0 ? (
              companies.map((company) => (
                <TableRow
                  key={company.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "15rem",
                    }}
                    component="th"
                    scope="row"
                  >
                    {company.name}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "15rem",
                    }}
                    component="th"
                    scope="row"
                  >
                    {company.cnpjOrCpf}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="left"
                    component="th"
                    scope="row"
                  >
                    {company.programs?.length}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontColor: "#2D3748",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    component="th"
                    scope="row"
                  >
                    <HStack spacing="1rem">
                      <Box
                        as={BsFillArrowUpRightSquareFill}
                        size="1.2rem"
                        color="teal.500"
                        cursor="pointer"
                        onClick={() =>
                          navigate(
                            routes.COMPANIES_DETAILS +
                              company.id +
                              `/${company.name} ${company.surname}`
                          )
                        }
                      />
                      <Box
                        as={FiEdit}
                        size="1.2rem"
                        color="teal.500"
                        cursor="pointer"
                        onClick={() =>
                          navigate(routes.USER_DETAILS + company.id)
                        }
                      />
                    </HStack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    fontColor: "#2D3748",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "15rem",
                  }}
                  component="th"
                  scope="row"
                  colSpan={7}
                  align="center"
                >
                  Nenhuma empresa encontrada
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <Stack direction="row-reverse">
          <Pagination
            page={page}
            onChange={handleChangePage}
            count={totalPages}
            color="primary"
            sx={{ fontColor: "#319795" }}
          />
        </Stack>
      </TableContainer>
    </ThemeProvider>
  );
};

export default AllCompanies;
