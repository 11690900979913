import { Box, Center, Text } from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";

interface Props {
  chartTitle: string;
  chartType:
    | "bar"
    | "area"
    | "line"
    | "histogram"
    | "pie"
    | "donut"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "treemap"
    | "boxPlot"
    | "candlestick"
    | "radar"
    | "polarArea"
    | "rangeBar"
    | undefined;
  chartOptions: any;
  chartSeries: any[];
}

const ReportChart: React.FC<Props> = ({
  chartTitle,
  chartOptions,
  chartSeries,
  chartType,
}) => {
  return (
    <Box>
      <Text fontSize="xl" mb="2rem">
        {chartTitle}
      </Text>

      {chartSeries.length === 0 ? (
        <Center p="3rem" maxH="30rem" bg="whiteAlpha.900" borderRadius="1rem">
          Nenhuma informação para apresentar
        </Center>
      ) : (
        <Box p="3rem" maxH="30rem" bg="whiteAlpha.900" borderRadius="1rem">
          <ReactApexChart
            options={chartOptions as any}
            series={chartSeries}
            type={chartType}
            height={300}
          />
        </Box>
      )}
    </Box>
  );
};

export default ReportChart;
