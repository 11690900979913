import api from "../../services/api";
import { OVERVIEW_PROGRAM_ENDPOINTS } from "../../enums/apiEndpoints";
import OverviewProgramInterface from "../../interfaces/overviewProgram";
import sessionControllerHandler from "../handlers/sessionControllerHandler";
import { userRoles } from "../../enums/roles";

class OverviewProgramRequests {
  public async get(
    lastDays: string,
    email?: string,
    companyId?: string,
    researcherId?: string
  ) {
    try {
      let url: string = OVERVIEW_PROGRAM_ENDPOINTS.GET + "?";
      const userRole = sessionControllerHandler.getUserRole();

      if (email && userRole === userRoles.GUEST) {
        url += `&email=${email}`;
      }

      if (lastDays !== "all") {
        url += `&lastDays=${lastDays}`;
      }

      if (companyId && userRole !== userRoles.GUEST) {
        url += `&companyId=${companyId}`;
      }

      if (researcherId && userRole !== userRoles.GUEST) {
        url += `&researcherId=${researcherId}`;
      }

      const response = await api.get(url);
      return response.data as OverviewProgramInterface;
    } catch (error: any) {
      if (!error.response) {
        return "Ocorreu um erro no servidor, tente novamente mais tarde";
      }

      return error.response.data;
    }
  }
}

export default new OverviewProgramRequests();
